export const headCells = [
  //for zero(0) index for Signee/Candidate
  [{
    id: 'id',
    label: 'Id',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'candidate_name',
    label: 'Candidate Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'segment',
    label: 'Segment',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'item',
    label: 'Item',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'file_name',
    label: 'Document',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'formatted_expire_date',
    label: 'Expiry',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'remark',
    label: 'Notes',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'document_status',
    label: 'Status',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'Notified',
    label: 'Notified',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
    {
    id: 'Invoice',
    label: 'Action',
    sortable: true,
    align: 'center',
    numIndex: 0,
    activeIndexOnly:true,
  },],

  
]
