import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  // FormHelperText,
} from "@material-ui/core";
import axios from "axios";
import { useForm } from "react-hook-form";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { createUmbrella } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import history from "../../utils/HistoryUtils";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  formControl: {
    width: "100%",
    "& > .MuiFormHelperText-root": {
      color: "red",
    },
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const CreateUmbrella = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [roleItem, setRoleItem] = useState([]);
  const [designationItem, setDesignationItem] = useState([]);
  const [umbrellaNotify, setUmbrellaNotify] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { umbrellaError, umbrellaSuccess } = useSelector(
    (state) => state.umbrellaReducer
  );
  const [data, setData] = useState({
    name_umbrella: "",
    address: "",
    email: "",
    bank_name: "",
    bank_account_number: "",
    bank_sort_code: "",
    vat: "",
    vat_number: "",
  });
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const umbrellaSubmit = async (datas) => {
    dispatch(createUmbrella(datas));
    setUmbrellaNotify(true);
    reset();
  };

  const backPage = () => {
    history.goBack();
  };

  return (
    <>
      {umbrellaNotify && umbrellaSuccess?.message && (
        <Notification
          data={
            umbrellaSuccess?.message
              ? "Umbrella company added successfully"
              : ""
          }
          status="success"
        />
      )}

      {umbrellaNotify && umbrellaError?.message && (
        <Notification data={umbrellaError?.message} status="error" />
      )}
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(umbrellaSubmit)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="name_umbrella"
                label="Name Of Umbrella"
                variant="outlined"
                name="name_umbrella"
                onChange={handleChange}
                fullWidth
                // value={data?.name_umbrella}
                {...register("name_umbrella", {
                  required: "Please enter name",
                })}
                // helperText={errors.name_umbrella ? "Please enter first name" : false}
                error={errors.name_umbrella ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                name="address"
                // value={data?.address}
                onChange={handleChange}
                fullWidth
                {...register("address", {
                  required: "Please enter address",
                })}
                helperText={errors.address ? "Please enter last name" : false}
                error={errors.address ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="email"
                label="Contact Email"
                variant="outlined"
                name="email"
                // value={data?.email}
                onChange={handleChange}
                fullWidth
                {...register("email", {
                  required: "Please Enter email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Enter a valid e-mail address",
                  },
                })}
                // helperText={errors.email ? "Please enter email address" : false}
                error={errors.email ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="bank_name"
                label="Bank Name"
                variant="outlined"
                name="bank_name"
                // value={data?.bank_name}
                onChange={handleChange}
                fullWidth
                {...register("bank_name", {
                  required: "Please enter bank name",
                })}
                // helperText={errors.bank_name ? "Please enter contact number" : false}
                error={errors.bank_name ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="bank_account_number"
                label="Bank Account Number"
                variant="outlined"
                name="bank_account_number"
                // value={data?.bank_account_number}
                onChange={handleChange}
                fullWidth
                {...register("bank_account_number", {
                  required: "Please enter bank account number",
                  pattern: /[0-9]/,
                })}
                // helperText={errors.bank_account_number ? "Please enter contact number" : false}
                error={errors.bank_account_number ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="bank_sort_code"
                label="Bank Sort Code"
                variant="outlined"
                name="bank_sort_code"
                // value={data?.bank_sort_code}
                onChange={handleChange}
                fullWidth
                {...register("bank_sort_code", {
                  required: "Please enter bank account number",
                })}
                error={errors.bank_sort_code ? true : false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="vat"
                label="VAT(%)"
                variant="outlined"
                name="vat"
                // value={data?.vat}
                onChange={handleChange}
                fullWidth
                // {...register("vat", {
                //   required: "Please enter bank account number",
                //   pattern: /[0-9]/,
                // })}
                // helperText={errors.vat ? "Please enter contact number" : false}
                // error={errors.vat ? true : false}
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="vat_number"
                label="VAT Number"
                variant="outlined"
                name="vat_number"
                onChange={handleChange}
                fullWidth
                {...register("vat_number", {
                  required: "Please enter vat number",
                })}
                error={errors.vat_number ? true : false}
                required
              />
            </Grid>
          </Grid>

          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              formNoValidate
            >
              Add
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default CreateUmbrella;
