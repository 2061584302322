export const headCells = [
  //for zero(0) index for Trust/Customers
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'invoice_number',
    label: 'Invoice Number',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  
    {
    id: 'type',
    label: 'Type',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  
  {
    id: 'invoice_date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'total_hours',
    label: 'Total',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'customer_name',
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'reference_id',
    label: 'Reference ID',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  
  {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },
],

  
]
