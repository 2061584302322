import Bookings from "./pages/Bookings/Bookings";
import Payment from "./pages/Payments/Payments";
import PaymentScheme from "./pages/payamentScheme/PaymentScheme";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Organization from "./pages/Organization/Organization";
import PlanManagement from "./pages/PlanManagement/PlanManagement";
import Holiday from "./pages/Holiday/Holiday";
import Roles from "./pages/Roles/Roles";
import Specialities from "./pages/Specialities/Specialities";
import CreateStaff from "./pages/Staff/CreateStaff";
import Staff from "./pages/Staff/Staff";
import Trust from "./pages/Trust/Trust";
import Profile from "./pages/Profile/Profile";
import OrgProfile from "./pages/Profile/OrgProfile";
import Signee from "./pages/Signee/Signee";
import ComplianceDocument from "./pages/compliance-document/ComplianceDocument"
import Notification from "./pages/Notification/Notification";
import StaffUserProfile from "./pages/StaffUser/StaffUserProfile/StaffUserProfile";
import Report from "./pages/Report/Reports";
import Umbrella from "./pages/UmbrellaCompany/Umbrella";
import Category from "./pages/Category/Category";
import SubCategory from "./pages/SubCategory/SubCategory";
import SystemLogs from "./pages/SystemLogs/SystemLogs";
import NotificationPreference from "./pages/NotificationPreference/NotificationPreference";
import UserNotificationPreference from "./pages/UserNotificationPreference/UserNotificationPreference";
import InvoiceStudio from "./pages/InvoiceStudio/InvoiceStudio";
import Timesheet from "./pages/Timesheet/Timesheet";
import Invoice from "./pages/Invoice/Invoice";
const loginUserInfo = JSON.parse(window.localStorage.getItem("loginUserInfo"));

const Routes = [
  {
    name: "Dashboard",
    path: "dashboard",
    component: Dashboard,
    icon: "dashboard",
    role: "superadmin",
  },
  {
    name: "Organizations",
    path: "organizations",
    component: Organization,
    icon: "business",
    role: "superadmin",
  },
  {
    name: "Plan Management",
    path: "plan",
    component: PlanManagement,
    icon: "account_tree",
    role: "superadmin",
  },

  {
    name: "change-password",
    path: "change-password",
    component:
      loginUserInfo?.is_plan_expire === true ? Payment : ChangePassword,
    icon: "assignment",
    role: "organization",
    sidebar: false,
  },

  {
    name: "Bookings",
    path: "bookings",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Bookings,
    icon: "bookmark",
    role: "organization",
  },
  {
    name: "Bookings",
    path: "bookings",
    component: Bookings,
    icon: "bookmark",
    role: "staff",
  },
  {
    name: "Candidates",
    path: "candidate",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Signee,
    icon: "assignment",
    role: "organization",
  },
  {
    name: "Candidates",
    path: "candidate",
    component: Signee,
    icon: "assignment",
    role: "staff",
  },

  {
    name: "Staff",
    path: "staff",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Staff,
    icon: "people",
    role: "organization",
    children: [
      {
        name: "Create Staff",
        path: "create",
        component:
          loginUserInfo?.is_plan_expire === true ? Payment : CreateStaff,
      },
    ],
    sidebar:false
  },
  {
    name: "Staff",
    path: "staff",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Staff,
    icon: "people",
    role: "staff",
    children: [
      {
        name: "Create Staff",
        path: "create",
        component:
          loginUserInfo?.is_plan_expire === true ? Payment : CreateStaff,
      },
    ],
    sidebar:false
  },

  {
    name: "Invoices",
    path: "Invoice",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.role !== "SUPERADMIN" && loginUserInfo?.staffdetails !== "Admin") ? Payment : Invoice,
    icon: "assignment",
    role: "organization",
    sidebar: false,
  },
  {
    name: "Invoices",
    path: "Invoice",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.role !== "SUPERADMIN" && loginUserInfo?.staffdetails !== "Admin") ? Payment : Invoice,
    icon: "assignment",
    role: "staff",
    sidebar: false,
  },
  

  {
    name: "Timesheets",
    path: "Timesheet",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.role !== "SUPERADMIN" && loginUserInfo?.staffdetails !== "Admin") ? Payment : Timesheet,
    icon: "shield",
    role: "organization",
    sidebar:false
  },
  {
    name: "Timesheets",
    path: "Timesheet",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.role !== "SUPERADMIN" && loginUserInfo?.staffdetails !== "Admin") ? Payment : Timesheet,
    icon: "shield",
    role: "staff",
    sidebar:false
  },

  {
    name: "Customers",
    path: "Customers",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Trust,
    icon: "shield",
    role: "organization",
  },
  {
    name: "Customers",
    path: "Customers",
    component: Trust,
    icon: "shield",
    role: "staff",
  },
  {
    name: "Roles",
    path: "roles",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Roles,
    icon: "manage_accounts",
    role: "organization",
    sidebar:false
  },
  {
    name: "Roles",
    path: "roles",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Roles,
    icon: "manage_accounts",
    role: "staff",
    sidebar:false
  },
  
  {
    name: "Specialities",
    path: "specialities",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Specialities,
    icon: "volunteer_activism",
    role: "organization",
    sidebar:false
  },
   {
    name: "Specialities",
    path: "specialities",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Specialities,
    icon: "volunteer_activism",
    role: "staff",
    sidebar:false
  },

  {
    name: "Reports",
    path: "reports",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Report,
    icon: "description",
    role: "organization",
  },
  {
    name: "Reports",
    path: "reports",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Report,
    icon: "description",
    role: "staff",
    sidebar : ((loginUserInfo?.role === "STAFF" && (loginUserInfo?.staffdetails === "Admin" || loginUserInfo?.staffdetails === "Compliance" || loginUserInfo?.staffdetails === "Finance")) || loginUserInfo?.role === "ORGANIZATION") ? true : false,
  },
  {
    name: "Compliance-document",
    path: "compliance-document",
    component: ComplianceDocument,
    icon: "assignment",
    role: "staff",
    sidebar : ((loginUserInfo?.role === "STAFF" && (loginUserInfo?.staffdetails === "Admin" || loginUserInfo?.staffdetails === "Compliance" || loginUserInfo?.staffdetails === "Finance")) || loginUserInfo?.role === "ORGANIZATION") ? true : false,
  },
  {
    name: "Compliance-document",
    path: "compliance-document",
    component: ComplianceDocument,
    icon: "assignment",
    role: "organization",
  },
  {
    name: "Profile",
    path: "profile",
    component: Profile,
    // icon:"description",
    role: "superadmin",
    sidebar: false,
  },
  {
    name: "Subscription",
    path: "payment",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Payment,
    icon: "paid",
    role: "organization",
    sidebar: false,
  },
   {
    name: "Subscription",
    path: "payment",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Payment,
    icon: "paid",
    role: "staff",
    sidebar: false,
  },
  {
    name: "Organization Profile",
    path: "organization-profile",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : OrgProfile,
    // icon:"description",
    role: "organization",
    sidebar: false,
  },
  {
    name: "Staff Profile",
    path: "staff-profile",
    component: StaffUserProfile,
    icon: "people",
    role: "staff",
    sidebar: false,
  },
  {
    name: "Notification",
    path: "notification",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Notification,
    // icon:"description",
    role: "organization",
    sidebar: false,
  },
  {
      name: "Umbrella Companies",
      path: "umbrella-company",
      component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Umbrella,
      icon: "umbrella",
      role: "organization",
      sidebar: false,
    },
    {
      name: "Umbrella Companies",
      path: "umbrella-company",
      component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Umbrella,
      icon: "umbrella",
      role: "staff",
      sidebar: false,
    },
    
    {
    name: "Invoice-Studio",
    path: "invoice-studio",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : InvoiceStudio,
    icon: "assignment",
    role: 'organization',
    sidebar: false,
  },
  {
    name: "Invoice-Studio",
    path: "invoice-studio",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : InvoiceStudio,
    icon: "assignment",
    role: 'staff',
    sidebar: false,
  },
    
  {
    name: "System-Logs",
    path: "systemlogs",
    component: (loginUserInfo?.role === "ORGANIZATION" || loginUserInfo?.is_smt === "Yes") ? SystemLogs : Bookings,
    icon: "assignment",
    role: "organization",
    sidebar: false,
  },
  {
    name: "System-Logs",
    path: "systemlogs",
    component: (loginUserInfo?.role === "ORGANIZATION" || loginUserInfo?.is_smt === "Yes") ? SystemLogs : Bookings,
    icon: "assignment",
    role: "staff",
    sidebar: false,
  },

  {
    name: "Notification-Preference",
    path: "notification-preference",
    component:  (loginUserInfo?.role === "ORGANIZATION" || loginUserInfo?.is_smt === "Yes") ? NotificationPreference : Bookings,
    icon: "assignment",
    role: "organization",
    sidebar: false,
  },
  
  {
    name: "UserNotificationPreference",
    path: "user-notification-preference",
    component:  (loginUserInfo?.role === "ORGANIZATION" || loginUserInfo?.is_smt === "Yes") ? UserNotificationPreference : Bookings,
    icon: "assignment",
    role: "organization",
    sidebar: false,
  },
  {
    name: "UserNotificationPreference",
    path: "user-notification-preference",
    component:  (loginUserInfo?.role === "STAFF" || loginUserInfo?.is_smt === "Yes") ? UserNotificationPreference : Bookings,
    icon: "assignment",
    role: "staff",
    sidebar: false,
  },
  
     {
    name: "Holidays",
    path: "holiday",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Holiday,
    icon: "holiday_village",
    role: "organization",
    sidebar: false,
  },
  {
    name: "Holidays",
    path: "holiday",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Holiday,
    icon: "holiday_village",
    role: "staff",
    sidebar: false,
  },
   {
    name: "Compliance Category",
    path: "compliance-category",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Category,
    icon: "category_icon",
    role: "organization",
    sidebar : ((loginUserInfo?.role === "STAFF" && (loginUserInfo?.staffdetails === "Admin" || loginUserInfo?.staffdetails === "Compliance")) && loginUserInfo?.role !== "ORGANIZATION") ? true : false,
  },
  {
    name: "Compliance Category",
    path: "compliance-category",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Category,
    icon: "category_icon",
    role: "staff",
    sidebar : (loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Compliance") ? true : false,
  },
  {
    name: "Sub Category",
    path: "subCategory",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : SubCategory,
    icon: "category_icon",
    role: "organization",
    sidebar:false
  },
  {
    name: "Sub Category",
    path: "subCategory",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : SubCategory,
    icon: "category_icon",
    role: "staff",
    sidebar : false,
  },
  {
    name: "Payment Schemes",
    path: "payment-scheme",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : PaymentScheme,
    icon: "paid",
    role: "organization",
    sidebar:((( loginUserInfo?.staffdetails !== "Admin") || loginUserInfo?.role !== "ORGANIZATION") && loginUserInfo?.is_consulatant === "Yes")
        ? true
        : false,
  },
  {
    name: "Payment Schemes",
    path: "payment-scheme",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : PaymentScheme,
    icon: "paid",
    role: "staff",
   sidebar:((( loginUserInfo?.staffdetails !== "Admin") || loginUserInfo?.role !== "ORGANIZATION") && loginUserInfo?.is_consulatant === "Yes")
        ? true
        : false,
  },

//sub menu of Finance
  {
      name: "Finance",
      path: "invoice-studio",
      component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Roles,
      icon: "payments",
      role: 'staff,organization',
      sidebar:((loginUserInfo?.role === "STAFF" && ((loginUserInfo?.staffdetails === "Finance") || (loginUserInfo?.staffdetails === "Admin" && loginUserInfo?.is_smt === "Yes"))) || (loginUserInfo?.role === "ORGANIZATION"))
                ? true
                : false,
      child: [
          
                {
                  name: "Invoice-Studio",
                  path: "invoice-studio",
                  component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : InvoiceStudio,
                  icon: "assignment",
                  role: 'staff,organization',
                  sidebar:((loginUserInfo?.role === "STAFF" && ((loginUserInfo?.staffdetails === "Finance") || (loginUserInfo?.staffdetails === "Admin" && loginUserInfo?.is_smt === "Yes"))) || (loginUserInfo?.role === "ORGANIZATION"))
                      ? true
                      : false,
                },

                {
                name: "Invoices",
                path: "Invoice",
                component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.role !== "SUPERADMIN" && loginUserInfo?.staffdetails !== "Admin") ? Payment : Invoice,
                icon: "view_timeline",
                role: "staff,organization",
                sidebar:((loginUserInfo?.role === "STAFF" && ((loginUserInfo?.staffdetails === "Finance") || (loginUserInfo?.staffdetails === "Admin" && loginUserInfo?.is_smt === "Yes"))) || (loginUserInfo?.role === "ORGANIZATION"))
                    ? true
                    : false,
              },
               {
                name: "Timesheets",
                path: "Timesheet",
                component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.role !== "SUPERADMIN" && loginUserInfo?.staffdetails !== "Admin") ? Payment : Timesheet,
                icon: "pending_actions_icon",
                role: "staff,organization",
                sidebar:((loginUserInfo?.role === "STAFF" && ((loginUserInfo?.staffdetails === "Finance") || (loginUserInfo?.staffdetails === "Admin" && loginUserInfo?.is_smt === "Yes"))) || (loginUserInfo?.role === "ORGANIZATION"))
                    ? true
                    : false,
              },
                
      ],
  },
  
//sub menu of setttig
  {
      name: "Settings",
      path: "roles",
      component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Roles,
      icon: "settings",
      role: 'staff,organization',
      sidebar:((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Admin") || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
      child: [
          
     {
    name: "Roles",
    path: "roles",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Roles,
    icon: "manage_accounts",
    role: 'staff,organization',
   sidebar:((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Admin") || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },

  {
    name: "Staff",
    path: "staff",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Staff,
    icon: "people",
    role: "staff, organization",
    children: [
      {
        name: "Create Staff",
        path: "create",
        component:
          loginUserInfo?.is_plan_expire === true ? Payment : CreateStaff,
      },
    ],
    sidebar:((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Admin") || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },
    {
    name: "Specialities",
    path: "specialities",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Specialities,
    icon: "volunteer_activism",
    role: 'staff,organization',
    sidebar:((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Admin") || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },     
         
  {
    name: "Holidays",
    path: "holiday",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Holiday,
    icon: "holiday_village",
    role: 'staff,organization',
    sidebar:((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Admin") || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },
  {
    name: "Subscription",
    path: "payment",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Payment,
    icon: "subscriptions_icon",
    role: 'staff,organization',
    sidebar: ((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails !== "Admin") || loginUserInfo?.role === "ORGANIZATION") ? true : false,
  },
  {
    name: "Payment Schemes",
    path: "payment-scheme",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : PaymentScheme,
    icon: "paid",
    role: 'staff,organization',
    sidebar:(loginUserInfo?.is_consulatant === "Yes" || loginUserInfo?.staffdetails === "Admin" || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },
  {
    name: "Compliance Category",
    path: "compliance-category",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Category,
    icon: "category_icon",
    role: 'staff,organization',
    sidebar : ((loginUserInfo?.role === "STAFF" && (loginUserInfo?.staffdetails === "Admin" || loginUserInfo?.staffdetails === "Compliance")) || loginUserInfo?.role === "ORGANIZATION") ? true : false,
  },

  {
    name: "Umbrella Companies",
    path: "umbrella-company",
    component: (loginUserInfo?.is_plan_expire === true && loginUserInfo?.staffdetails !== "Admin") ? Payment : Umbrella,
    icon: "apartment_icon",
    role: 'staff,organization',
    sidebar:((loginUserInfo?.role === "STAFF" && loginUserInfo?.staffdetails === "Admin") || loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },

  {
    name: "System-Logs",
    path: "systemlogs",
    component: (loginUserInfo?.role === "ORGANIZATION") ? SystemLogs : Bookings,
    icon: "assignment",
    role: "organization",
    sidebar:(loginUserInfo?.role === "ORGANIZATION")
        ? true
        : false,
  },
  {
    name: "System-Logs",
    path: "systemlogs",
    component: (loginUserInfo?.role === "STAFF" || loginUserInfo?.is_smt === "Yes") ? SystemLogs : Bookings,
    icon: "assignment",
    role: "staff",
    sidebar:(loginUserInfo?.role === "STAFF" || loginUserInfo?.is_smt === "Yes")
        ? true
        : false,
  },
  

      ],
  },
];

export default Routes;
