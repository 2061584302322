import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getUmbrellaItem: [],
  getUmbrellaError: [],

  umbrellaSuccess: [],
  umbrellaError: [],

  umbrellaUpdateError: [],
  umbrellaUpdateSuccess: [],

  umbrellaDeleteSuccess: [],
  umbrellaDeleteError: [],
};

const umbrellaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_UMBRELLA_REQUEST:
      return updateObject(state, {
        loading: true,
        umbrellaSuccess: "",
        umbrellaError: "",
      });

    case actionTypes.GET_UMBRELLA_SUCCESS:
      return updateObject(state, {
        loading: false,
        getUmbrellaItem: action.payload,
      });

    case actionTypes.GET_UMBRELLA_ERROR:
      return updateObject(state, {
        loading: false,
        getUmbrellaError: action.payload,
      });

    case actionTypes.CREATE_UMBRELLA_REQUEST:
      return updateObject(state, {
        loading: true,
        umbrellaError: "",
        umbrellaSuccess: "",
      });

    case actionTypes.CREATE_UMBRELLA_SUCCESS:
      return updateObject(state, {
        loading: false,
        umbrellaSuccess: action.payload,
      });

    case actionTypes.CREATE_UMBRELLA_ERROR:
      return updateObject(state, {
        loading: false,
        umbrellaError: action.payload,
      });

    case actionTypes.UPDATE_UMBRELLA_REQUEST:
      return updateObject(state, {
        loading: true,
        umbrellaUpdateError: "",
        umbrellaUpdateSuccess: "",
      });

    case actionTypes.UPDATE_UMBRELLA_SUCCESS:
      return updateObject(state, {
        loading: false,
        umbrellaUpdateSuccess: action.payload,
      });

    case actionTypes.UPDATE_UMBRELLA_ERROR:
      return updateObject(state, {
        loading: false,
        umbrellaUpdateError: action.payload,
      });

    case actionTypes.DELETE_UMBRELLA_REQUEST:
      return updateObject(state, {
        loading: true,
        umbrellaDeleteError: "",
        umbrellaDeleteSuccess: "",
      });

    case actionTypes.DELETE_UMBRELLA_SUCCESS:
      return updateObject(state, {
        loading: false,
        umbrellaDeleteSuccess: action.payload,
      });

    case actionTypes.DELETE_UMBRELLA_ERROR:
      return updateObject(state, {
        loading: false,
        umbrellaDeleteError: action.payload,
      });

    default:
      return state;
  }
};

export default umbrellaReducer;
