export const headCellsContactEvent = [
  //for zero(0) index for Signee/Candidate
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'created_at',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'comment',
    label: 'Comment',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'first_name',
    label: 'By',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
 {
    id: 'edit',
    label: 'Actions',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  ],

  
]
