export const headCells = [
  //for zero(0) index for Trust/Customers
  [{
    id: 'id',
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'name',
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true,
  },],

  
]
