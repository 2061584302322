import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getNotificationPrefsItem: [],
  getNotificationPrefsError: [],
  NotificationPrefsSuccess: [],
  NotificationPrefsErrors: [],

  deleteError: [],
  deleteSuccess: [],
};

const notificationPreferenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_PREF_REQUEST:
      return updateObject(state, {
        loading: true,
        NotificationPrefsSuccess: "",
        NotificationPrefsErrors: "",

        deleteSuccess: "",
        deleteError: "",
      });

    case actionTypes.GET_NOTIFICATION_PREF_SUCCESS:
      return updateObject(state, {
        loading: false,
        getNotificationPrefsItem: action.payload,
      });

    case actionTypes.GET_NOTIFICATION_PREF_ERROR:
      return updateObject(state, {
        loading: false,
        getNotificationPrefsError: action.payload,
      });

    case actionTypes.CREATE_NOTIFICATION_PREF_REQUEST:
      return updateObject(state, {
        loading: true,
        subNotificationPrefsSuccess: "",
        SubNotificationPrefsErrors: "",
      });

    case actionTypes.CREATE_NOTIFICATION_PREF_SUCCESS:
      return updateObject(state, {
        loading: false,
        NotificationPrefsSuccess: action.payload,
      });

    case actionTypes.CREATE_NOTIFICATION_PREF_ERROR:
      return updateObject(state, {
        loading: false,
        NotificationPrefsErrors: action.payload,
      });

    case actionTypes.UPDATE_NOTIFICATION_PREF_REQUEST:
      return updateObject(state, {
        loading: true,
        NotificationPrefsSuccess: "",
        NotificationPrefsErrors: "",
      });

    case actionTypes.UPDATE_NOTIFICATION_PREF_SUCCESS:
      return updateObject(state, {
        loading: false,
        NotificationPrefsSuccess: action.payload,
      });

    case actionTypes.UPDATE_NOTIFICATION_PREF_ERROR:
      return updateObject(state, {
        loading: false,
        NotificationPrefsErrors: action.payload,
      });

    case actionTypes.DELETE_NOTIFICATION_PREF_REQUEST:
      return updateObject(state, {
        loading: true,
        deleteError: "",
        deleteSuccess: "",
      });

    case actionTypes.DELETE_NOTIFICATION_PREF_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteSuccess: action.payload,
      });

    case actionTypes.DELETE_NOTIFICATION_PREF_ERROR:
      return updateObject(state, {
        loading: false,
        deleteError: action.payload,
      });

    default:
      return state;
  }
};

export default notificationPreferenceReducer;
