import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Divider,
  Box
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { editContactEventForSignee } from "../../store/action/signee/signeeAction";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const EditContactDialog = ({ open, handleClose, data, title, setData }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [eventMsg, seteventMsg] = useState(false);
  const { editCandidateEventSuccess, editCandidateEventError } = useSelector(
    (state) => state.signee
  );
 
  const [formError, setError] = useState([]);

  const handleChange = (event) => {
      setData({...data,comment:event.target.value})
    if (event.target.value) {
      setError([]);
    } else {
      setError("err_comment");
    }
    
  };

  const onSubmitEvent = async (event) => {
    event.preventDefault();
    
    if (data.comment === "") {
      setError("err_comment");
    } else {
    dispatch(editContactEventForSignee(data))
    handleClose();
    seteventMsg(true)
    setTimeout(()=>{
      seteventMsg(false)
    },1000)
    }
  };
  const closeModal = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    setError([]);
  };
 
  return (
    <Box>
      {eventMsg && editCandidateEventError?.message && (
        <Notification data={editCandidateEventError?.message} status="error" />
      )}
   
          
           {eventMsg && <Notification
            data={'Contact Event Updated Successfully'}
            status="success"
            />}
           
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
        >
          
        <form onSubmit={(event) => onSubmitEvent(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Edit Contact Event</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
                <input type='hidden' value={data.id} />
              <TextareaAutosize
                margin="dense"
                variant="outlined"
                minRows={4}
                aria-label="Please Update Comment"
                placeholder="Please Update Comment"
               
                value={data?.comment}
                name="comment"
                required={true}
                style={{ width: "100%" }}
                onChange={handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
};

export default EditContactDialog;
