import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getSubCategoryItem: [],
  getSubCategoryError: [],
  subCategorySuccess: [],
  SubCategoryErrors: [],

  deleteError: [],
  deleteSuccess: [],
};

const subCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUB_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        subCategorySuccess: "",
        SubCategoryErrors: "",

        deleteSuccess: "",
        deleteError: "",
      });

    case actionTypes.GET_SUB_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        getSubCategoryItem: action.payload,
      });

    case actionTypes.GET_SUB_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        getSubCategoryError: action.payload,
      });

    case actionTypes.CREATE_SUB_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        subCategorySuccess: "",
        SubCategoryErrors: "",
      });

    case actionTypes.CREATE_SUB_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        subCategorySuccess: action.payload,
      });

    case actionTypes.CREATE_SUB_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        SubCategoryErrors: action.payload,
      });

    case actionTypes.UPDATE_SUB_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        subCategorySuccess: "",
        SubCategoryErrors: "",
      });

    case actionTypes.UPDATE_SUB_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        subCategorySuccess: action.payload,
      });

    case actionTypes.UPDATE_SUB_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        SubCategoryErrors: action.payload,
      });

    case actionTypes.DELETE_SUB_CATEGORY_REQUEST:
      return updateObject(state, {
        loading: true,
        deleteError: "",
        deleteSuccess: "",
      });

    case actionTypes.DELETE_SUB_CATEGORY_SUCCESS:
      return updateObject(state, {
        loading: false,
        deleteSuccess: action.payload,
      });

    case actionTypes.DELETE_SUB_CATEGORY_ERROR:
      return updateObject(state, {
        loading: false,
        deleteError: action.payload,
      });

    default:
      return state;
  }
};

export default subCategoryReducer;
