import React, { useRef, useEffect } from 'react';
import {
  
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useGooglePlaceAutoCompleteForClient } from "../../components/GoogleAPI/google_place_autocomplete_customer";
// Other imports...

const GoogleClientAddress = (props) => {
  const {data,setData, item, index, register, errors,createTrustError} = props;
  const caddress1Ref = useRef();
  const googleAutoCompleteSvc = useGooglePlaceAutoCompleteForClient();
  let autoComplete = "";

    useEffect(() => {
    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element.
      // eslint-disable-next-line
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(caddress1Ref.current, handleAddressSelect);
    }
    loadGoogleMaps();

  }, []);

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete); 
    setData((prevData) => {
      const newData = { ...prevData };
      newData['hospital'][index]['client_address'] = addressObj?.client_address || "";
      newData['hospital'][index]['latitude'] = addressObj?.latitude || "";
      newData['hospital'][index]['longitude'] = addressObj?.longitude || "";
      return newData;
    });
  };

  
  const handleChangeHospital = (index, event, key) => {
    const newValue = event.target.value;
    setData((prevData) => {
    const newData = { ...prevData };
    newData[key][index]['client_address'] = newValue;
    return newData;
  });
  };

    
    return (
        <TextField
          id={`client_address_${index}`}
          label="Client Address"
          variant="outlined"
          name={`client_address_${index}`}
          value={item?.client_address || ""}
          {...register(`client_address_${index}`, { required: true })}
          inputRef={caddress1Ref}
          error={
            errors.client_address
              ? true
              : createTrustError &&
                createTrustError?.[
                  `hospital.${index}.client_address`
                ]
              ? true
              : false
          }
          onChange={(e) =>
            handleChangeHospital(index, e, "hospital")
          }
          fullWidth
          required
        /> 
    );
};

export default GoogleClientAddress;