import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import {
  GET_SYSTEM_LOGS_ERROR,
  GET_SYSTEM_LOGS_REQUEST,
  GET_SYSTEM_LOGS_SUCCESS,
  GET_MODULES,
  GET_OPERATIONS,
  
} from "../actiontypes";

export const getSystemLogs = ({pageNo = 1,
  search = "",
  operation = "",
  module = "",
  start_date = "",
  end_date = "",
  type="",
  column_name="",}) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getSystemLogsRequest());
    await axios
      .get(`${Config.API_URL}api/organization/system-logs?search=${search}&operation=${operation}&module=${module}&start_date=${start_date}&end_date=${end_date}&page=${pageNo}&type=${type}&column_name=${column_name}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data.status === true) {
          dispatch(getSystemLogsSuccess(data));
        } else {
          dispatch(getSystemLogsSuccess([]));
          dispatch(getSystemLogsError(data));
        }
      })
      .catch((error) => {
        dispatch(getSystemLogsError(error));
      });
  };
};

export const getSystemLogsRequest = () => {
  return {
    type: GET_SYSTEM_LOGS_REQUEST,
  };
};
export const getSystemLogsSuccess = (data) => {
  return {
    type: GET_SYSTEM_LOGS_SUCCESS,
    payload: data,
  };
};
export const getSystemLogsError = (error) => {
  return {
    type: GET_SYSTEM_LOGS_ERROR,
    payload: error,
  };
};

//----------------------------

export const getModules = (result) => {
  return async (dispatch) => {
    dispatch(getModulesRequest());
    await apiClient(true)
      .get(`api/organization/get-modules`)
      .then((response) => {
        result(response.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getModulesRequest = () => {
  return {
    type: GET_MODULES,
  };
};

//----------------------------

export const getOperations = (result) => {
  return async (dispatch) => {
    dispatch(getOperationsRequest());
    await apiClient(true)
      .get(`api/organization/get-operations`)
      .then((response) => {
        result(response.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getOperationsRequest = () => {
  return {
    type: GET_OPERATIONS,
  };
};
