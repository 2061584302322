import moment from "moment/moment";
import { apiClient } from "../../../config/apiClient";
import {

    GET_ALL_TRUST_REQUEST,
    GET_CANDIDATE_LIST_REQUEST,
    GET_CANDIDATE_MUTIPLE_LIST_REQUEST,
    GET_HOSPITAL_LIST,
    GET_STAFF_DETAIL_REQUEST,
    GET_UMBRELLA_DETAIL_REQUEST,
    GET_WARD_BY_HOSPITAL,
    SPECIALITIES_SHIFT_REQUEST,

} from "../actiontypes";

export const getSpecialitiesList = (result) => {
    return async (dispatch) => {
        dispatch(getSpecShiftCreateRequest());
        await apiClient(true)
            .get(`api/organization/get-spec-shift-create`)
            .then((response) => {
                result(response.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

export const getSpecShiftCreateRequest = () => {
    return {
        type: SPECIALITIES_SHIFT_REQUEST,
    };
};

export const getAllCustomerList = (result) => {
    return async (dispatch) => {
        dispatch(getAllTrustRequest());
        await apiClient(true)
            .get(`api/organization/get-trusts`)
            .then((response) => {
                result(response?.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

const getAllTrustRequest = () => {
    return {
        type: GET_ALL_TRUST_REQUEST,
    };
};

export const getAllHospitalsList = (getTrustId, result) => {
    
    return async (dispatch) => {
        dispatch(getHospitalListRequest());
        await apiClient(true)
            .get(`api/organization/get-hospitallist/${getTrustId}`)
            .then((response) => {
                result(response.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

const getHospitalListRequest = () => {
    return {
        type: GET_HOSPITAL_LIST,
    };
};

export const getWardByHospitalList = (hospitalId, getTrustId, result) => {
    return async (dispatch) => {
        dispatch(getWardByHospitalRequest());
        await apiClient(true)
            .get(
                `api/organization/get-ward-by-hospital?hospitalId=${hospitalId}&trustId=${getTrustId}`
            )
            .then((response) => {
                result(response.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

const getWardByHospitalRequest = () => {
    return {
        type: GET_WARD_BY_HOSPITAL,
    };
};

export const getStaffDetailList = (result) => {
    return async (dispatch) => {
        dispatch(getStaffDetailRequest());
        await apiClient(true)
            .get(`api/organization/get-staff`)
            .then((response) => {
                result(response?.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

const getStaffDetailRequest = () => {
    return {
        type: GET_STAFF_DETAIL_REQUEST,
    };
};

export const getAllCandidateList = (result) => {
    return async (dispatch) => {
        dispatch(getCandidateListRequest());
        await apiClient(true)
            .get(`api/organization/get-candidate-list`)
            .then((response) => {
                result(response?.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

const getCandidateListRequest = () => {
    return {
        type: GET_CANDIDATE_LIST_REQUEST,
    };
};


export const getUmbrellDetailList = (result) => {
    return async (dispatch) => {
        dispatch(getUmbrellaDetailRequest());
        await apiClient(true)
            .get(`api/organization/get-umbrella-company`)
            .then((response) => {
                result(response?.data);
            })
            .catch((error) => {
                result(error);
            });
    };
};

const getUmbrellaDetailRequest = () => {
    return {
        type: GET_UMBRELLA_DETAIL_REQUEST,
    };
};