const useGooglePlaceAutoCompleteForCandidate = () => {

    const initAutoComplete = async (input, callback) => {
        let autoComplete =
            new window.google.maps.places.Autocomplete(input,
                {
                    // limit to North America for now
                    componentRestrictions: { country: "UK" },
                    fields: ["address_components", "geometry", "name"],
                }
            );
        autoComplete.addListener("place_changed", callback);
        return autoComplete;

    };

    const getFullAddress = async (autoComplete) => {

        const place = autoComplete.getPlace();
        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();
        // Get each component of the address from the place details,
        if (place.address_components) {
        var address_line_1 = '';
        var address_line_2 = '';
        var locality = '';
        var postcode = '';

        place.address_components.forEach(function (component) {
            if (component.types.includes('street_number')) {
                address_line_1 += component.long_name +',';
            }else if(component.types.includes("subpremise") || component.types.includes("premise") || component.types.includes("neighborhood") ){ 
                address_line_1 += component.long_name +',';
            }else if(component.types.includes('sublocality_level_2') || component.types.includes('route') || component.types.includes('landmark')){
                address_line_2 += component.long_name + ',';
            }else if (component.types.includes('sublocality') || component.types.includes('sublocality_level_1')) {
                address_line_2 += component.long_name;
            } else if (component.types.includes('locality')) {
                locality = component.long_name;
            } else if (component.types.includes('postal_code')) {
                postcode = component.long_name;
            }
        });
    }

        let resAddress = {
            "address_line_1":  place.name+ ' '+address_line_1,
            "locality": locality,
            "postcode": postcode,
            "address_line_2": address_line_2,
            "latitude": latitude,
            "longitude": longitude,
        };

        return resAddress;

    };

    return {
        initAutoComplete,
        getFullAddress
    };

};



export {useGooglePlaceAutoCompleteForCandidate};