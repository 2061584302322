import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Button,
  Box,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createPaymentScheme } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import axios from "axios";
import apiConfigs from "../../config/config";
import history from "../../utils/HistoryUtils";
import UtilService from "../../helper/service";
import { getSpecShiftCreate } from "../../store/action/specialities/specialitiesAction";
import { getAllTrust } from "../../store/action/trust/trustAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },

  formControl: {
    width: "100%",
  },
  formControl1: {
    width: "96%",
    marginLeft: "16px",
  },
  footerBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  radioGroup: {
    flexDirection: "row",
  },
  hospitalBox: {
    padding: 8,
    width: "100%",
  },
  lightGray: {
    background: "#f4f5f6",
    width: "100%",
    margin: 0,
    padding: "16px 12px",
  },
  wardBox: {
    margin: 0,
    width: "100%",
    position: "relative",
  },

  addWards: {
    fontSize: 12,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    "& .MuiButton-label": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      width: 18,
      height: "auto",
    },
  },
  removeWard: {
    position: "absolute",
    top: 24,
    right: 24,
    cursor: "pointer",
  },
  removeTraining: {
    position: "absolute",
    top: 24,
    right: "20px",
    cursor: "pointer",
  },
  removehospital: {
    position: "absolute",
    top: -9,
    right: -9,
    cursor: "pointer",
    background: "#ff8b46",
    borderRadius: "50%",
    width: 26,
    height: 26,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: 16,
    },
  },
  showError: {
    color: "red",
  },
  disFeild: {
    color: "#757575",
  },
  mainTitle: {
    fontSize: 18,
    color: "#ff8b46",
    fontWeight: "500",
  },
}));

  
const CreatePaymentScheme = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { createPaymentSchemeError, createPaymentSchemeSuccess } = useSelector(
    (state) => state.paymentScheme
  );
  const [paymentSchemeNotify, setPaymentSchemeNotify] = useState(false);
  const [addAnother, setAddAnother] = useState(false);
  const [gradeList, setGradeList] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [trust, setTrust] = useState([]);
  const [specError, setSpecError] = useState(false);
  const [hours, setHours] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [data, setData] = useState({
    trust_id: "",
    grade_id: "",
    speciality_id: "",
    remark: "",

    payable_day_rate: "",
    payable_night_rate: "",
    payable_saturday_rate: "",
    payable_holiday_rate: "",
    chargeable_day_rate: "",
    chargeable_night_rate: "",
    chargeable_saturday_rate: "",
    chargeable_holiday_rate: "",
    day_from: "",
    day_to: "",
    night_from: "",
    night_to: "",
    saturday_from: "",
    saturday_to: "",
    holiday_from: "",
    holiday_to: "",
    awr_umbrella_payable_day_rate: "",
    awr_umbrella_payable_night_rate: "",
    awr_umbrella_payable_saturday_rate: "",
    awr_umbrella_payable_holiday_rate: "",
    awr_umbrella_chargeable_day_rate: "",
    awr_umbrella_chargeable_night_rate: "",
    awr_umbrella_chargeable_saturday_rate: "",
    awr_umbrella_chargeable_holiday_rate: "",

    non_awr_paye_payable_day_rate: "",
    non_awr_paye_payable_night_rate: "",
    non_awr_paye_payable_saturday_rate: "",
    non_awr_paye_payable_holiday_rate: "",
    non_awr_paye_chargeable_day_rate: "",
    non_awr_paye_chargeable_night_rate: "",
    non_awr_paye_chargeable_saturday_rate: "",
    non_awr_paye_chargeable_holiday_rate: "",
    start_date: "",
    end_date: "",
    payment_scheme_type: "Variable",
    wtr: "",
  });

  const handleCheckboxClick = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setIsChecked(true);
      setData({ ...data, [event.target.name]: "Yes" });
    } else {
      setIsChecked(false);
      setData({ ...data, [event.target.name]: "No" });
    }
  };

  const handleChange = (event) => {
    if (event.target.name == "day_to") {
      data.night_from = event.target.value;
    }
    if (event.target.name == "day_from") {
      data.night_to = event.target.value;
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };
 const dateHandler = (name,date)=>{
    setData({ ...data, [name]: date });
  }
  const getTrust = async () => {
    try {
      dispatch(
        getAllTrust((result) => {
          setTrust(result);
        })
      );
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const submitData = async (e) => {
    let payload = {
      ...data,
    start_date: data?.start_date ? moment(data?.start_date).format("YYYY-MM-DD") : "",
    end_date:data?.end_date ? moment(data?.end_date).format("YYYY-MM-DD") : ""
    }
    if (addAnother === true) {
      dispatch(createPaymentScheme(payload, addAnother));
    } else {
      dispatch(createPaymentScheme(payload, addAnother));
    }
    setPaymentSchemeNotify(true);
  };
  const backPage = () => {
    history.goBack();
  };

  const getGradeList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-gradelist`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setGradeList(response.data);
      })
      .catch((error) => {
        console.error("error.message", error.message);
      });
  };

  const getSpecialities = async () => {
    dispatch(
      getSpecShiftCreate((result) => {
        if (result?.status === true) {
          setSpeciality(result);
        } else {
          setSpecError(true);
        }
      })
    );
  };

  const getHoursList = async () => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-hours`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        setHours(response.data);
        const output = response.data.data;
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  useEffect(() => {
    getSpecialities();
    getTrust();
    getHoursList();
    getGradeList();
  }, []);

  return (
    <>
      {paymentSchemeNotify && createPaymentSchemeSuccess?.message && (
        <Notification
          data={createPaymentSchemeSuccess?.message}
          status="success"
        />
      )}

      {paymentSchemeNotify &&
        (createPaymentSchemeError?.message?.code ||
          createPaymentSchemeError?.message) && (
          <Notification
            data={
              createPaymentSchemeError?.message?.code
                ? createPaymentSchemeError?.message?.code
                : createPaymentSchemeError?.message
                ? createPaymentSchemeError?.message
                : createPaymentSchemeError
            }
            status="error"
          />
        )}
      {paymentSchemeNotify &&
        (Object.values(createPaymentSchemeError)?.length > 0 ||
          createPaymentSchemeError) && (
          <Notification
            data={
              typeof Object.values(createPaymentSchemeError)[0] === "string"
                ? createPaymentSchemeError
                : Object.values(createPaymentSchemeError)[0]
            }
            status="error"
          />
        )}
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit(submitData)} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.trust_id ? true : false}
              >
                <InputLabel>Customer Name</InputLabel>
                <Select
                  value={data.trust_id}
                  label="Customer Name"
                  name="trust_id"
                  {...register("trust_id", {
                    required: "The Customer field is required.",
                  })}
                  // error={(errors.trust_id ? true : false)}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select Customers</MenuItem>
                  {trust?.data &&
                    trust?.data.map((trustList, index) => {
                      return (
                        <MenuItem value={trustList.id} key={index}>
                          {trustList.name}
                        </MenuItem>
                      );
                    })}

                  {/* <MenuItem value="Apex Care Hospital">Apex Care Hospital</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.speciality ? true : false}
              >
                <InputLabel>Specialities Name</InputLabel>
                <Select
                  value={data?.speciality_id}
                  label="Specialities Name"
                  name="speciality_id"
                  {...register("speciality_id", {
                    required: "The speciality field is required.",
                  })}
                  onChange={handleChange}
                >
                  {speciality?.data &&
                    speciality?.data.map((items, index) => {
                      return (
                        <MenuItem value={items.id} key={index}>
                          {items.speciality_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.grade_id ? true : false}
              >
                <InputLabel>Grade Required</InputLabel>
                <Select
                  value={data.grade_id}
                  name="grade_id"
                  label="Grade Required"
                  {...register("grade_id", {
                    required: "The grade field is required.",
                  })}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select a grade</MenuItem>
                  {gradeList?.data &&
                    gradeList?.data.map((list, index) => {
                      return (
                        <MenuItem value={list.id} key={index}>
                          {list.grade_name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12} lg={4}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                required
                error={errors.remark ? true : false}
              >
                <InputLabel>Remark</InputLabel>
                <Select
                  value={data.remark}
                  name="remark"
                  label="Remark "
                  {...register("remark", {
                    required: "The remark field is required.",
                  })}
                  onChange={handleChange}
                >
                  <MenuItem value="">Remark</MenuItem>
                  <MenuItem value="Day Rate" key="Day Rate">
                    {" "}
                    Day Rate
                  </MenuItem>
                  <MenuItem value="Night Rate" key="Night Rate">
                    {" "}
                    Night Rate
                  </MenuItem>
                  <MenuItem value="Saturday Rate" key="Saturday Rate">
                    {" "}
                    Saturday Rate
                  </MenuItem>
                  <MenuItem value="Sunday Rate" key="Sunday Rate">
                    {" "}
                    Sunday Rate
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* <TextField
                id="start_date"
                label="Start Date"
                type="date"
                name="start_date"
                className={classes.textField}
                variant="outlined"
                // {...register("start_date", {
                //   required: "The start date field is required.",
                // })}
                value={data?.start_date}
                error={errors.start_date ? true : false}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                // inputProps={{
                //   min: disPastDate,
                // }}
              /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          id="start_date"
                          label="Start Date"
                          type="date"
                          name="start_date"
                          className={classes.textField}
                          variant="outlined"
                          value={data.start_date}
                          onChange={(e) =>dateHandler("start_date",e)}
                          error={errors.start_date ? true : false}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              {/* <TextField
                id="end_date"
                label="End Date"
                type="date"
                name="end_date"
                className={classes.textField}
                variant="outlined"
                // {...register("end_date", {
                //   required: "The end date field is required.",
                // })}
                value={data?.end_date}
                error={errors.end_date ? true : false}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                inputProps={
                  {
                    // min: disPastDate,
                  }
                }
              /> */}
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                           id="end_date"
                          label="End Date"
                          type="date"
                          name="end_date"
                          className={classes.textField}
                          variant="outlined"
                          value={data.end_date}
                          onChange={(e) =>dateHandler("end_date",e)}
                          error={errors.end_date ? true : false}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField variant="outlined" fullWidth  {...props}  />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="nhs_product_group"
                label="NHS Product Group"
                variant="outlined"
                name="nhs_product_group"
                value={data?.nhs_product_group}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_product_group"
                label="LPP Product Group"
                variant="outlined"
                name="lpp_product_group"
                value={data?.lpp_product_group}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_job_title"
                label="LPP Job Title"
                variant="outlined"
                name="lpp_job_title"
                value={data?.lpp_job_title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_job_code"
                label="LPP Job Code"
                variant="outlined"
                name="lpp_job_code"
                value={data?.lpp_job_code}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="lpp_hte_job_family"
                label="LPP/HTE Job Family"
                variant="outlined"
                name="lpp_hte_job_family"
                value={data?.lpp_hte_job_family}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                id="miso_job_title"
                label="MISO Job Title"
                variant="outlined"
                name="miso_job_title"
                value={data?.miso_job_title}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box className="mt-3">
                <Typography>Payment Scheme Type</Typography>
                <RadioGroup
                  name="payment_scheme_type"
                  value={data?.payment_scheme_type || ""}
                  onChange={handleChange}
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="Fixed"
                    control={<Radio />}
                    label="Fixed"
                  />
                  <FormControlLabel
                    value="Variable"
                    control={<Radio />}
                    label="Variable"
                  />
                </RadioGroup>
              </Box>
            </Grid>

             <Grid item xs={12} sm={6} lg={4}>
              <Checkbox
                checked={isChecked}
                label="WTR"
                name="wtr"
                value={data?.wtr}
                onClick={(event) => handleCheckboxClick(event)}
                className="mt-2"
              />{" "}
              WTR
            </Grid>

            <Grid item xs={12}>
              <div className="pt-2 pb-0">
                <Divider />
              </div>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.mainTitle}>Rates</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className="pt-0 pb-2">
                <Divider />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Day Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={errors.day_from ? true : false}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data.day_from}
                  label="day_from"
                  name="day_from"
                  {...register("day_from", {
                    // required:data?.payment_scheme_type == "Fixed" ?false: true,
                    required:
                      data?.payment_scheme_type === "Fixed" ? false : true,
                  })}
                  disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                  onChange={handleChange}
                  //   onChange={handleStartTime}
                >
                  <MenuItem value="">Select From Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={errors.day_to ? true : false}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data.day_to}
                  label="To Time"
                  name="day_to"
                  {...register("day_to", {
                    required:
                      data?.payment_scheme_type === "Fixed" ? false : true,
                  })}
                  disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_day_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_day_rate"
                        {...register("non_awr_paye_payable_day_rate", {
                          required: "Non AWR PAYE Pay Rate",
                        })}
                        value={data?.non_awr_paye_payable_day_rate}
                        error={
                          errors.non_awr_paye_payable_day_rate ? true : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_day_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_day_rate"
                        {...register("non_awr_paye_chargeable_day_rate", {
                          required: "Non AWR PAYE Charge Rate",
                        })}
                        value={data?.non_awr_paye_chargeable_day_rate}
                        error={
                          errors.non_awr_paye_chargeable_day_rate ? true : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_day_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        name="payable_day_rate"
                        {...register("payable_day_rate", {
                          required: "Please enter day rate",
                        })}
                        value={data?.payable_day_rate}
                        error={errors.payable_day_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_day_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        name="chargeable_day_rate"
                        {...register("chargeable_day_rate", {
                          required: "Please enter Day Rate",
                        })}
                        value={data?.chargeable_day_rate}
                        error={errors.chargeable_day_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_day_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_day_rate"
                        {...register("non_awr_umbrella_payable_day_rate", {
                          required: "Non AWR Umbrella Pay Rate",
                        })}
                        value={data?.non_awr_umbrella_payable_day_rate}
                        error={
                          errors.non_awr_umbrella_payable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_day_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_day_rate"
                        {...register("non_awr_umbrella_chargeable_day_rate", {
                          required: "Non AWR Umbrella Charge Rate",
                        })}
                        value={data?.non_awr_umbrella_chargeable_day_rate}
                        error={
                          errors.non_awr_umbrella_chargeable_day_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_day_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_day_rate"
                        {...register("awr_umbrella_payable_day_rate", {
                          required: "AWR Umbrella Pay Rate",
                        })}
                        value={data?.awr_umbrella_payable_day_rate}
                        error={
                          errors.awr_umbrella_payable_day_rate ? true : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_day_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_day_rate"
                        {...register("awr_umbrella_chargeable_day_rate", {
                          required: "AWR Umbrella Charge Day Rate",
                        })}
                        value={data?.awr_umbrella_chargeable_day_rate}
                        error={
                          errors.awr_umbrella_chargeable_day_rate ? true : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Night Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                // required
                disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                error={errors.night_from ? true : false}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data.night_from}
                  label="From Required"
                  name="night_from"
                  // {...register("night_from", {
                  //   required: "The Start time field is required.",
                  // })}
                  disabled={true}
                  onChange={handleChange}
                  //   onChange={handleStartTime}
                >
                  <MenuItem value="">Select Start Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                // required
                disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                error={errors.night_to ? true : false}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data.night_to}
                  label="To Time"
                  name="night_to"
                  // {...register("night_to", {
                  //   required: "The To time field is required.",
                  // })}
                  disabled={true}
                  // error={(errors.night_from ? true : false)}
                  // onChange={handleEndTime}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_night_rate"
                        label="Non AWR PAYE  Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_night_rate"
                        {...register("non_awr_paye_payable_night_rate", {
                          required: "Non AWR PAYE Rate",
                        })}
                        value={data?.non_awr_paye_payable_night_rate}
                        error={
                          errors.non_awr_paye_payable_night_rate ? true : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_night_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_night_rate"
                        {...register("non_awr_paye_chargeable_night_rate", {
                          required: "Non AWR PAYE Charge Rate",
                        })}
                        value={data?.non_awr_paye_chargeable_night_rate}
                        error={
                          errors.non_awr_paye_chargeable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_night_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        name="payable_night_rate"
                        {...register("payable_night_rate", {
                          required: "Please enter rate",
                        })}
                        value={data?.payable_night_rate}
                        error={errors.payable_night_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_night_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        name="chargeable_night_rate"
                        {...register("chargeable_night_rate", {
                          required: "Please enter Rate",
                        })}
                        value={data?.chargeable_night_rate}
                        error={errors.chargeable_night_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_night_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_night_rate"
                        {...register("non_awr_umbrella_payable_night_rate", {
                          required: "Non AWR Umbrella Pay Rate",
                        })}
                        value={data?.non_awr_umbrella_payable_night_rate}
                        error={
                          errors.non_awr_umbrella_payable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_night_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_night_rate"
                        {...register("non_awr_umbrella_chargeable_night_rate", {
                          required: "Non AWR Umbrella Charge Rate",
                        })}
                        value={data?.non_awr_umbrella_chargeable_night_rate}
                        error={
                          errors.non_awr_umbrella_chargeable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_night_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_night_rate"
                        {...register("awr_umbrella_payable_night_rate", {
                          required: "AWR Umbrella Pay Rate",
                        })}
                        value={data?.awr_umbrella_payable_night_rate}
                        error={
                          errors.awr_umbrella_payable_night_rate ? true : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_night_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_night_rate"
                        {...register("awr_umbrella_chargeable_night_rate", {
                          required: "AWR Umbrella Charge Rate",
                        })}
                        value={data?.awr_umbrella_chargeable_night_rate}
                        error={
                          errors.awr_umbrella_chargeable_night_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Saturday Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={errors.saturday_from ? true : false}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data.saturday_from}
                  label="From Time"
                  name="saturday_from"
                  {...register("saturday_from", {
                    required:
                      data?.payment_scheme_type === "Fixed" ? false : true,
                  })}
                  disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={errors.saturday_to ? true : false}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data.saturday_to}
                  label="To Time"
                  name="saturday_to"
                  {...register("saturday_to", {
                    required:
                      data?.payment_scheme_type === "Fixed" ? false : true,
                  })}
                  disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                  onChange={handleChange}
                  //   onChange={handleStartTime}
                >
                  <MenuItem value="">Select To Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_saturday_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_saturday_rate"
                        {...register("non_awr_paye_payable_saturday_rate", {
                          required: "Non AWR PAYE Pay Saturday Rate",
                        })}
                        value={data?.non_awr_paye_payable_saturday_rate}
                        error={
                          errors.non_awr_paye_payable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_saturday_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_saturday_rate"
                        {...register("non_awr_paye_chargeable_saturday_rate", {
                          required: "Non AWR PAYE Charge  Saturday Rate",
                        })}
                        value={data?.non_awr_paye_chargeable_saturday_rate}
                        error={
                          errors.non_awr_paye_chargeable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_saturday_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        name="payable_saturday_rate"
                        {...register("payable_saturday_rate", {
                          required: "Please enter Saturday rate name",
                        })}
                        value={data?.payable_saturday_rate}
                        error={errors.payable_saturday_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_saturday_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        name="chargeable_saturday_rate"
                        {...register("chargeable_saturday_rate", {
                          required: "Please enter Charge Saturday Rate",
                        })}
                        value={data?.chargeable_saturday_rate}
                        error={errors.chargeable_saturday_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_saturday_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_saturday_rate"
                        {...register("non_awr_umbrella_payable_saturday_rate", {
                          required: "Non AWR Umbrella Pay  Saturday Rate",
                        })}
                        value={data?.non_awr_umbrella_payable_saturday_rate}
                        error={
                          errors.non_awr_umbrella_payable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_saturday_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_saturday_rate"
                        {...register(
                          "non_awr_umbrella_chargeable_saturday_rate",
                          {
                            required: "Non AWR Umbrella Charge Saturday Rate",
                          }
                        )}
                        value={data?.non_awr_umbrella_chargeable_saturday_rate}
                        error={
                          errors.non_awr_umbrella_chargeable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_saturday_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_saturday_rate"
                        {...register("awr_umbrella_payable_saturday_rate", {
                          required: "AWR Umbrella Pay  Saturday Rate",
                        })}
                        value={data?.awr_umbrella_payable_saturday_rate}
                        error={
                          errors.awr_umbrella_payable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_saturday_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_saturday_rate"
                        {...register("awr_umbrella_chargeable_saturday_rate", {
                          required: "AWR Umbrella Charge Saturday Rate",
                        })}
                        value={data?.awr_umbrella_chargeable_saturday_rate}
                        error={
                          errors.awr_umbrella_chargeable_saturday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12} sm={6} lg={4}>
              <Typography variant="h5">Holiday/Sunday Shift</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={errors.holiday_from ? true : false}
              >
                <InputLabel>From Time</InputLabel>
                <Select
                  value={data.holiday_from}
                  label="From Time"
                  name="holiday_from"
                  {...register("holiday_from", {
                    required:
                      data?.payment_scheme_type === "Fixed" ? false : true,
                  })}
                  disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              lg={4}
              style={{
                display:
                  data?.payment_scheme_type == "Fixed" ? "none" : "block",
              }}
            >
              <FormControl
                variant="outlined"
                className={classes.formControl1}
                error={errors.holiday_to ? true : false}
              >
                <InputLabel>To Time</InputLabel>
                <Select
                  value={data.holiday_to}
                  label="To Time"
                  name="holiday_to"
                  {...register("holiday_to", {
                    required:
                      data?.payment_scheme_type === "Fixed" ? false : true,
                  })}
                  disabled={data?.payment_scheme_type == "Fixed" ? true : false}
                  onChange={handleChange}
                >
                  <MenuItem value="">Select To Time</MenuItem>
                  {hours?.data &&
                    hours?.data.map((hoursList, index) => {
                      return (
                        <MenuItem value={hoursList} key={index}>
                          {hoursList}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Pay Rate</TableCell>
                    <TableCell align="left">Charge Rate</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={0}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_payable_holiday_rate"
                        label="Non AWR PAYE Pay Rate"
                        variant="outlined"
                        name="non_awr_paye_payable_holiday_rate"
                        {...register("non_awr_paye_payable_holiday_rate", {
                          required: "Non AWR PAYE Pay Holiday Rate",
                        })}
                        value={data?.non_awr_paye_payable_holiday_rate}
                        error={
                          errors.non_awr_paye_payable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_paye_chargeable_holiday_rate"
                        label="Non AWR PAYE Charge Rate"
                        variant="outlined"
                        name="non_awr_paye_chargeable_holiday_rate"
                        {...register("non_awr_paye_chargeable_holiday_rate", {
                          required: "Non AWR Paye Charge Holiday Rate",
                        })}
                        value={data?.non_awr_paye_chargeable_holiday_rate}
                        error={
                          errors.non_awr_paye_chargeable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell scope="row">
                      <TextField
                        id="payable_holiday_rate"
                        label="AWR PAYE Pay Rate"
                        variant="outlined"
                        name="payable_holiday_rate"
                        {...register("payable_holiday_rate", {
                          required: "Please enter Holiday rate",
                        })}
                        value={data?.payable_holiday_rate}
                        error={errors.payable_holiday_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="chargeable_holiday_rate"
                        label="AWR PAYE Charge Rate"
                        variant="outlined"
                        name="chargeable_holiday_rate"
                        {...register("chargeable_holiday_rate", {
                          required: "Please enter Charge Holiday",
                        })}
                        value={data?.chargeable_holiday_rate}
                        error={errors.chargeable_holiday_rate ? true : false}
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">PAYE</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_payable_holiday_rate"
                        label="Non AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="non_awr_umbrella_payable_holiday_rate"
                        {...register("non_awr_umbrella_payable_holiday_rate", {
                          required: "Non AWR Umbrella Pay Holiday Rate",
                        })}
                        value={data?.non_awr_umbrella_payable_holiday_rate}
                        error={
                          errors.non_awr_umbrella_payable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="non_awr_umbrella_chargeable_holiday_rate"
                        label="Non AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="non_awr_umbrella_chargeable_holiday_rate"
                        {...register(
                          "non_awr_umbrella_chargeable_holiday_rate",
                          {
                            required: "Non AWR Umbrella Charge Holiday Rate",
                          }
                        )}
                        value={data?.non_awr_umbrella_chargeable_holiday_rate}
                        error={
                          errors.non_awr_umbrella_chargeable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">Non AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_payable_holiday_rate"
                        label="AWR Umbrella Pay Rate"
                        variant="outlined"
                        name="awr_umbrella_payable_holiday_rate"
                        {...register("awr_umbrella_payable_holiday_rate", {
                          required: "AWR Umbrella Pay Rate",
                        })}
                        value={data?.awr_umbrella_payable_holiday_rate}
                        error={
                          errors.awr_umbrella_payable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">
                      <TextField
                        id="awr_umbrella_chargeable_holiday_rate"
                        label="AWR Umbrella Charge Rate"
                        variant="outlined"
                        name="awr_umbrella_chargeable_holiday_rate"
                        {...register("awr_umbrella_chargeable_holiday_rate", {
                          required: "AWR Umbrella Charge Holiday Rate",
                        })}
                        value={data?.awr_umbrella_chargeable_holiday_rate}
                        error={
                          errors.awr_umbrella_chargeable_holiday_rate
                            ? true
                            : false
                        }
                        onChange={handleChange}
                        fullWidth
                        type="number"
                        required
                      />
                    </TableCell>
                    <TableCell scope="row">Umbrella</TableCell>
                    <TableCell scope="row">AWR</TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>

          <Box className={classes.footerBtn}>
            <Button color="primary" onClick={backPage}>
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              name="btn1"
              formNoValidate
              onClick={() => setAddAnother(false)}
            >
              Save & Confirm
            </Button>
            <Button
              color="secondary"
              variant="contained"
              type="submit"
              name="btn2s"
              formNoValidate
              onClick={() => setAddAnother(true)}
            >
              Save & Add another
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default CreatePaymentScheme;
