import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getPaymentSchemeItem: [],
  getPaymentSchemeError: [],

  createPaymentSchemeSuccess: [],
  createPaymentSchemeError: {},

  updatePaymentSchemeSuccess: [],
  updatePaymentSchemeError: [],

  deletePaymentSchemeSuccess: [],
  deletePaymentSchemeError: [],
};

const paymentSchemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_SCHEME_REQUEST:
      return updateObject(state, {
        loading: true,
      });

    case actionTypes.GET_PAYMENT_SCHEME_SUCCESS:
      return updateObject(state, {
        loading: false,
        getPaymentSchemeItem: action.payload,
      });

    case actionTypes.GET_PAYMENT_SCHEME_ERROR:
      return updateObject(state, {
        loading: false,
        getPaymentSchemeError: action.payload,
      });

    case actionTypes.CREATE_PAYMENT_SCHEME_REQUEST:
      return updateObject(state, {
        loading: false,
        createPaymentSchemeSuccess: "",
        createPaymentSchemeError: "",
      });

    case actionTypes.CREATE_PAYMENT_SCHEME_SUCCESS:
      return updateObject(state, {
        loading: false,
        createPaymentSchemeSuccess: action.payload,
      });

    case actionTypes.CREATE_PAYMENT_SCHEME_ERROR:
      return updateObject(state, {
        loading: false,
        createPaymentSchemeError: action.payload,
      });

    case actionTypes.UPDATE_PAYMENT_SCHEME_REQUEST:
      return updateObject(state, {
        loading: true,
        updatePaymentSchemeSuccess: "",
        updatePaymentSchemeError: "",
      });

    case actionTypes.UPDATE_PAYMENT_SCHEME_SUCCESS:
      return updateObject(state, {
        loading: false,
        updatePaymentSchemeSuccess: action.payload,
      });

    case actionTypes.UPDATE_PAYMENT_SCHEME_ERROR:
      return updateObject(state, {
        loading: false,
        updatePaymentSchemeError: action.payload,
      });

    case actionTypes.DELETE_PAYMENT_SCHEME_REQUEST:
      return updateObject(state, {
        loading: true,
        deletePaymentSchemeSuccess: "",
        deletePaymentSchemeError: "",
      });

    case actionTypes.DELETE_PAYMENT_SCHEME_SUCCESS:
      return updateObject(state, {
        loading: false,
        deletePaymentSchemeSuccess: action.payload,
      });

    case actionTypes.DELETE_PAYMENT_SCHEME_ERROR:
      return updateObject(state, {
        loading: false,
        deletePaymentSchemeError: action.payload,
      });

    default:
      return state;
  }
};

export default paymentSchemeReducer;
