export const headCells = [
  //for zero(0) index for Compliance Category
  [{
    id: 'id',
    label: 'Id',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'type',
    label: 'Operation Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'module_name',
    label: 'Module Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'created_by',
    label: 'Operated By',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  },
  {
    id: 'created_date',
    label: 'Created Date',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true,
  }, ],

  
]
