import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  alpha,
  InputBase,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Backdrop,
  CircularProgress,
  IconButton,
  Button,
  Checkbox,
  Toolbar,
  Typography,
  Tooltip,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import SearchIcon from "@material-ui/icons/Search";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTimesheet,
  getTimesheet,
} from "../../store/action/timesheet/timesheetAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import AlertDialog from "../../components/Alert/AlertDialog";
import axios from "axios";
import Notification from "../../components/Notification/Notification";
import Config from "../../config/config";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import {
  notificationFail,
  notificationSuccess,
} from "../../store/action/notificationMsg";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },

  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 335px)",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

const Timesheet = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  const [searchData, setSearchData] = useState({ search: "", id: "" });

  const { getTimesheetItem, loading } = useSelector((state) => state.timesheet);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("timesheet_name");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [Id, setId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const baseUrl = apiConfigs.API_URL + "uploads/temp_timesheet/";
  const [loadings, setLoadings] = useState(false);
  const [timesheetMsgError, setTimesheetError] = useState("");
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );
  const [selected, setSelected] = useState([]);
  const [multipleDelete, setMultipleDelete] = useState(false);

  const getTimesheetList = (
    pageNo = 1,
    search = "",
    type = order,
    column_name = orderBy
  ) => {
    dispatch(getTimesheet({ pageNo, search, type, column_name }));
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(getTimesheetList(value), 2000);
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(getTimesheetList(page, d1), 100);
    } else {
      setTimeout(getTimesheetList(page, ""), 100);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setTimeout(getTimesheetList(page, searchData.search), 1000);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const deleteTimesheetById = (timesheet_id) => {
    setDisabled(true);
    setId(timesheet_id);
    setDeleteOpen(true);
  };
  const deleteTimesheetClose = () => {
    setDeleteOpen(false);
  };
  const alertResponse = (id) => {
    if (disabled === true) {
      dispatch(deleteTimesheet(id));
    }
    setDisabled(false);
    getTimesheetList();
  };

  const handleUploadTimeSheet = (e) => {
    setLoadings(true);

    const pic = e.target.files;
    let formData = new FormData();
    for (const file of pic) {
      formData.append("timesheets[]", file, file.name);
    }

    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(
        `${apiConfigs.API_URL}api/organization/upload-multiple-timesheet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem && dataItem.status === true) {
          setTimesheetError(dataItem);
          setLoadings(false);
          getTimesheetList();
        } else {
          setTimesheetError(dataItem);
          setLoadings(false);
        }
      })
      .catch((error) => {
        setTimesheetError("Sorry timesheet upload failed");
        setLoadings(false);
      });
  };

  useEffect(() => {
    if (
      (loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION" ||
      loginUserInfo?.role === "SUPERADMIN"
    ) {
      getTimesheetList();
    }
  }, [orderBy, order]);

  const downloadFile = async (name) => {
    try {
      const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
      const response = await axios.get(
        `${Config.API_URL}api/organization/download-pdf/${name}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
          responseType: "blob",
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading the file:", error);
      // Handle error as needed
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const toolbarStyles = (theme) => ({
    root: {
      paddingRight: theme.spacing.unit,
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
  });

  const EnhancedTableToolbar = (props) => {
    const { numSelected, classes } = props;
    setMultipleDelete(numSelected > 0);
    return <></>;
  };

  const EnhancedTableToolbarWithStyles =
    withStyles(toolbarStyles)(EnhancedTableToolbar);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds =
        getTimesheetItem?.data?.data &&
        getTimesheetItem?.data?.data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const deleteSelected = async (ids) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .post(
        `${apiConfigs.API_URL}api/organization/delete-mutiple-temp-timesheet`,
        { ids: ids },
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const data = response.data;

        if (data?.status === true) {
          dispatch(notificationSuccess(data?.message));
          getTimesheetList();
          setSelected([]);
        } else {
          dispatch(notificationFail(data?.message));
        }
      })
      .catch((error) => {
        console.log("errro", error);
        dispatch(notificationFail(error));
      });
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <>
      {loading || loadings ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {timesheetMsgError && timesheetMsgError?.message && (
        <Notification
          data={timesheetMsgError?.message}
          status={timesheetMsgError?.status === true ? "success" : "error"}
        />
      )}
      {(loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION" ||
      loginUserInfo?.role === "SUPERADMIN" ? (
        <>
          <Paper className={`${classes.root} mb-6`}>
            <Box className="mb-5" display="flex" alignItems="center">
              <SearchIcon
                className={classes.searchIcondet}
                onClick={handleClickSearch}
              />
              <div className={classes.search}>
                <InputBase
                  name="search"
                  placeholder="Search…"
                  onChange={handleSearchChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
            </Box>
            <Box
              className="mb-5"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <div className="ml-5">
                {multipleDelete && (
                  <Button
                    title="Delete All"
                    variant="contained"
                    color="secondary"
                    style={{ textTransform: "capitalize", marginRight: "20px" }}
                    onClick={() => deleteSelected(selected)}
                  >
                    <label class="mr-1">Delete Selected</label>
                  </Button>
                )}

                <Button
                  title="Upload Timesheet"
                  variant="contained"
                  color="secondary"
                  style={{ textTransform: "capitalize" }}
                >
                  <input
                    key={"s"}
                    accept="image/*,.pdf"
                    type="file"
                    multiple
                    name="timesheet"
                    onChange={(event) => handleUploadTimeSheet(event)}
                    style={{
                      opacity: "0",
                      position: "absolute",
                      width: "100px",
                      height: "35px",
                      cursor: "pointer",
                    }}
                  />
                  <label class="mr-1">Upload Timesheets</label>
                </Button>
              </div>
            </Box>

            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <EnhancedTableToolbarWithStyles numSelected={selected.length} />
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(event, property) => {
                    handleRequestSort(
                      property,
                      setOrder,
                      setOrderBy,
                      orderBy,
                      order
                    );
                  }}
                  rowCount={getTimesheetItem?.data?.data?.length}
                  headCells={headCells[0]}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {getTimesheetItem?.data?.data &&
                    getTimesheetItem?.data?.data.map((list, index) => {
                      const isItemSelected = isSelected(list.id);
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, list.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={list.id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} />
                          </TableCell>
                          <TableCell scope="row">
                            {getTimesheetItem?.data?.from + index}
                          </TableCell>
                          <TableCell align="left">
                            {list.timesheet_name}
                          </TableCell>
                          <TableCell align="right" style={{ width: 140 }}>
                            <IconButton
                              onClick={() => deleteTimesheetById(list.id)}
                              style={{ padding: 6 }}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right" style={{ width: 140 }}>
                            <Link
                              className="btn btn-secondary"
                              to={{
                                pathname: `${baseUrl}${list?.timesheet_name}`,
                              }}
                              target="_blank"
                            >
                              View
                            </Link>
                          </TableCell>
                          <TableCell align="right" style={{ width: 207 }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() =>
                                downloadFile(`${list?.timesheet_name}`)
                              }
                              className={classes.downloadButton}
                            >
                              <span className="material-icons mr-2">
                                download
                              </span>{" "}
                              Download PDF
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {!getTimesheetItem?.data?.data && (
                    <TableRow>
                      <TableCell scope="row" colSpan="8">
                        <div className="" align="center">
                          Sorry,timesheet not available!
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="mt-5" display="flex" justifyContent="flex-end">
              <Pagination
                onChange={handleChangePage}
                page={page}
                count={getTimesheetItem?.data?.last_page}
                showFirstButton
                showLastButton
              />
            </Box>

            <AlertDialog
              id={Id}
              response={alertResponse}
              open={deleteOpen}
              close={deleteTimesheetClose}
              title="Delete Timesheet"
              description="Are you sure you want to delete timesheet?"
              buttonName="Delete"
            />
          </Paper>
        </>
      ) : (
        <div className="" align="center">
          <b>Sorry,You have no access!</b>
        </div>
      )}
    </>
  );
};

export default Timesheet;
