import React from 'react';
import { Route,Redirect, Switch } from 'react-router-dom'
import CreatePaymentScheme from './CreatePaymentScheme'
import DetailPaymentScheme from './DetailPaymentScheme';
import UpdatePaymentScheme from './UpdatePaymentScheme';
import ViewPaymentScheme from './ViewPaymentScheme'

const PaymentScheme = ({ match }) => {
    const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
    return (
        <>
        <Switch>
            <Route exact path={`${match.url}/`} component={ViewPaymentScheme} />
            {
                (staffDetail !== "Booking" && staffDetail !== "Finance") && <Route exact path={`${match.url}/create`} component={CreatePaymentScheme} />
            }
            {
                (staffDetail !== "Booking" && staffDetail !== "Finance") && <Route exact path={`${match.url}/:id/update`} component={UpdatePaymentScheme} />
            }
            <Route exact path={`${match.url}/:id/detail`} component={DetailPaymentScheme} />
            <Redirect from="" to={`${match.url}`} />
            
        </Switch>
        </>
    )
}

export default PaymentScheme