import { combineReducers } from "redux";
import authReducer from "./reducer/auth/authReducer";
import notifyReducer from "./reducer/notify";
import organizationReducer from "./reducer/organizationReducer/organizationReducer";
import orgProfileReducer from "./reducer/orgProfileReducer/orgProfileReducer";
import profileReducer from "./reducer/profile/profileReducer";
import rolesReducer from "./reducer/Roles/rolesReducer";
import holidayReducer from "./reducer/Holiday/holidayReducer";
import specialitiesReducer from "./reducer/specialities/specialitiesReducer";
import staffReducer from "./reducer/staff/staffReducer";
import trustReducer from "./reducer/trust/trustReducer";
import bookingReducer from "./reducer/booking/bookingReducer";
import signeeReducer from "./reducer/signee/signeeReducer";
import complianceReducer from "./reducer/compliance/complianceReducer";
import StaffProfileReducer from "./reducer/staffProfile/StaffProfileReducer";
import notificationMsgReducer from "./reducer/notificationMsg/notificationMsg";
import loadingReducer from "./reducer/loading/globalLoading";
import notificationList from "./reducer/notificationList/notificationList";
import dashboardReducer from "./reducer/dashboard/dashboardReducer";
import paymentReducer from "./reducer/payment/paymentReducer";
import reportReducer from "./reducer/report/reportReducer";
import planReducer from "./reducer/planReducer/planReducer";
import paymentSchemeReducer from "./reducer/paymentScheme/paymentSchemeReducer";
import umbrellaReducer from "./reducer/umbrella/umbrellaReducer";
import categoryReducer from "./reducer/Category/categoryReducer";
import subCategoryReducer from "./reducer/SubCategory/subCategoryReducer";
import systemLogsReducer from "./reducer/SystemLogs/systemLogsReducer";
import notificationPreferenceReducer from "./reducer/NotificationPreference/notificationPreferenceReducer";
import userNotificationPreferenceReducer from "./reducer/UserNotificationPreference/userNotificationPreferenceReducer";
import timesheetReducer from "./reducer/timesheet/timesheetReducer";
import invoiceReducer from "./reducer/invoice/invoiceReducer";

const rootReducer = combineReducers({
  authReducer: authReducer,
  createOrganization: organizationReducer,
  organizationReducer: organizationReducer,
  profile: profileReducer,
  orgProfile: orgProfileReducer,
  notify: notifyReducer,
  roles: rolesReducer,
  specialities: specialitiesReducer,
  staff: staffReducer,
  trust: trustReducer,
  booking: bookingReducer,
  signee: signeeReducer,
  compliance: complianceReducer,
  staffProfile: StaffProfileReducer,
  notificationMsg: notificationMsgReducer,
  loadingReducer: loadingReducer,
  dashboardReducer: dashboardReducer,
  notificationList: notificationList,
  paymentReducer: paymentReducer,
  reportReducer: reportReducer,
  planReducer: planReducer,
  holiday: holidayReducer,
  paymentScheme: paymentSchemeReducer,
  umbrellaReducer: umbrellaReducer,
  category: categoryReducer,
  subCategory: subCategoryReducer,
  systemLogs: systemLogsReducer,
  notificationPrefs:notificationPreferenceReducer,
  userNotificationPrefs:userNotificationPreferenceReducer,
  timesheet: timesheetReducer,
  invoice: invoiceReducer


});

export default rootReducer;
