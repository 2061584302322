import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  makeStyles,
  Chip,
  Paper,
  Box,
  MenuItem,
  Button,
  Backdrop,
  CircularProgress,
  FormControl,
  Select,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import apiConfigs from "../../config/config";
import history from "../../utils/HistoryUtils";
import AlertDialog from "../../components/Alert/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../components/Notification/Notification";
import { cancelInvoice,changeShiftStatus, deleteBooking,delTimeSheetDoc } from "../../store/action";
import CandidatesList from "./CandidatesList";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import { Link } from "react-router-dom";
import { getSingleBooking } from "../../store/action/booking/bookingAction";
import RescheduleShift from "./RescheduleShift";
const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
    padding: 24,
  },
  desc: {
    fontSize: 16,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  heading: {
    color: "#626161",
  },
  gridItem: {
    borderBottom: "1px solid #ccc",
  },
  chipContainer: {
    paddingTop: 12,
    "& > *": {
      marginRight: theme.spacing(0.5),
      background: "#f4f4f4",
      fontSize: 20,
    },
  },
  menuItem: {
    fontSize: 14,
    "& svg": {
      width: 16,
      height: "auto",
    },
  },
  btnContainer: {
    "& > *": {
      marginLeft: theme.spacing(2),
      "& svg": {
        width: 20,
        height: "auto",
      },
    },
  },

  statusContainer: {
    width: "100%",
    maxWidth: "100%",
    flex: "0 0 100%",
    padding: "6px !important",
    background: "#eaebed",
    marginBottom: 24,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  statusLabel: {
    marginRight: 16,
    fontWeight: "500",
    color: "#184a7b",
  },
  formControl1: {
    width: 140,
    display: "flex",
    border: "none",
    background: "#184a7b",
    color: "#fff",
    padding: "4px 8px",
    paddingLeft: 12,
    borderRadius: 6,
    "& .MuiInputBase-root": {
      color: "#fff",
      "&:before": {
        border: "none !important",
      },
    },
    "& svg": {
      fill: "#fff",
    },
  },
  uploadFile: {
    position: "absolute",
    left: "0",
    top: "0",
    opacity: "0",
    zIndex: "9",
  },
  fileCon: {
    // flex: 1,
  },
  downloadButton: {
    background: "#184a7b",
    color: "#fff",
    marginLeft: "8px",
  },
   statusButton: {
    display: 'flex',
    alignItems: 'center',
    background: ' #184a7b',
    borderRadius: 4,

    overflow: 'hidden',
    marginBottom: 16,
    '& > .btn': {
      margin: theme.spacing(0),
      width: 140,
          cursor:"pointer",
      background: 'transparent',
      color: '#8dbef0',
      height: '47px',
      boxShadow: 'none',
      borderRadius: 0,
      '&.active': {
        background: '#ff8b46',
        color: '#fff',
      },
      '&:hover': {
        background: '#f69d68',
        color: '#fff',
      },
    },
  },
}));
const baseUrl = apiConfigs.API_URL + "uploads/timesheet/";
// "http://backendbooking.kanhasoftdev.com/public/uploads/timesheet/";

const DetailBooking = ({ match }) => {
  const tabList = ['CREATED', 'CONFIRMED', 'CANCEL', 'PROVISIONAL', 'COMPLETED']
  const [deleteTimeSheetOpen, setDeleteTimeSheetOpen] = useState(false);                    
    const [timeSheetId, setTimeSheetId] = useState(false);
  const classes = useStyle();
  const dispatch = useDispatch();
  const booking_id = match.params.id;
  const [Id, setId] = useState(false);
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const [bookingDetail, setBookingDetail] = useState([]);
   const [initialData,setInitialData]=useState([])
  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [staffNotify, setStaffNotify] = useState(false);
  const [delTimeSheetNotify, setDelTimeSheetNotify] = useState(false);
  const [cancelInvoiceNotify, setCancelInvoiceNotify] = useState(false);
  const [bookingNotify, setBookingNotify] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(false);
  const [timesheetMsgError, setTimesheetError] = useState("");
  const [cancelInvoiceOpen,setCancelInvoiceOpen] = useState(false);
  const [searchingVal, setSearchingVal] = useState("");

  const loginUserInfo = JSON.parse(window.localStorage.getItem("loginUserInfo"));
  if(localStorage.getItem('tabStatus') === undefined && localStorage.getItem('tabStatus') > 0)
  {
    localStorage.setItem('tabStatus',1);
  }
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const { cancelInvoiceSuccess, cancelInvoiceError,deleteTimeSheetSuccess, deleteTimeSheetError,deleteBookingSuccess, deleteBookingError, shiftStatusSuccess 
  } = useSelector((state) => state.booking);
  const [bookingStatus, setBookingStatus] = useState({
    booking_id: "",
    status: "",
  });

  const upadateLink = () => {
    history.push(`update`);
  };

  const getBookingDetail = async (search, type, proximity) => {
    setLoading(true);
    try {
      dispatch(
        getSingleBooking(
          {
            booking_id,
            search,
            type,
            proximity
          },
          (result) => {
            setBookingDetail(result);
            setInitialData(result)
            
            setLoading(false);
          }
        )
      );
    } catch (error) {
      console.error("error.message", error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getBookingDetail("", "");
  }, []);

  const deleteBookingClose = () => {
    setDeleteOpen(false);
  };

  const deleteStaffItem = (id) => {
    setDeleteOpen(true);
    setId(id);
  };
  const alertResponse = (id) => {
    dispatch(deleteBooking(id));
    setStaffNotify(true);
  };
  const backPage = () => {
    history.go(-2);
  };

  const backToPrevPage = (tabIndex) => {
    localStorage.setItem('tabStatus',tabIndex);
    localStorage.setItem('pageNo',1);
    history.go(-2);
  };

  const handleClose = (action) => {
    setOpen(false);
  };

  const handleBookingStatus = (event, id) => {
    setBookingStatus({
      ...bookingStatus,
      [event.target.name]: event.target.value,
      booking_id: id,
    });
  };

  useEffect(() => {
    if (bookingStatus.booking_id !== "") {
      dispatch(changeShiftStatus(bookingStatus));
      setBookingNotify(true);
      setTimeout(() => {
        getBookingDetail("", "");
      }, 4000);
    }
  }, [bookingStatus]);
  
  const setTimesheet = (e) => {
    setLoading(true);
    const pic = e.target.files[0];
    let formData = new FormData();
    formData.append("timesheet", pic);
    formData.append("booking_id", booking_id);
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(
        `${apiConfigs.API_URL}api/organization/upload-timesheet`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem && dataItem.status === true) {
          // window.location.reload();
          getBookingDetail("", "");
          setTimesheetError(dataItem);
          setLoading(false);
        } else {
          setTimesheetError(dataItem);
        }
      })
      .catch((error) => {
        setTimesheetError("Sorry timesheet upload failed");
      });
  };
  const downloadTimeSheet = async () => {
    setLoading(true);
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/download-timesheet/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const pieces = `${baseUrl}${bookingDetail?.data?.timesheet}`.split("/");
        const fileName = pieces[pieces.length - 1]
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const deleteTimeSheet = (id) => {
    setDeleteTimeSheetOpen(true);
    setTimeSheetId(id);
  };

  const deleteTimeSheetClose = () => {
    setDeleteTimeSheetOpen(false);
  };

  const deleteTimeSheetResponse = (id) => {
    dispatch(delTimeSheetDoc(id));
    setDelTimeSheetNotify(true);
  };

  const cancelInvoiceBooking = (id) => {
    setCancelInvoiceOpen(true);
    setId(id);
  };

  const cancelInvoiceClose = () => {
    setCancelInvoiceOpen(false);
  };

  const cancelInvoiceResponse = (id) => {
    dispatch(cancelInvoice(id));
    setCancelInvoiceNotify(true);
  };

  const downloadCreditNotes = async () => {
    setLoading(true);
    let fileName = (bookingDetail?.data?.invoice_number) ? "CREDIT NOTES"+ bookingDetail?.data?.invoice_number + ".pdf" : "CREDIT NOTES"+bookingDetail?.data?.name + ".pdf";
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/generate-credit-notes/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

   const downloadPdf = async () => {
    setLoading(true);
    let fileName = (bookingDetail?.data?.invoice_number) ? bookingDetail?.data?.invoice_number + ".pdf" : bookingDetail?.data?.name + ".pdf";
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/generate-invoice/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setLoading(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  let userRole = bookingDetail?.data?.user_role;
  let loggedInRole = bookingDetail?.data?.loggedin_role;
  userRole = userRole && userRole.replace(/['"]+/g, "");
  loggedInRole = loggedInRole && loggedInRole.replace(/['"]+/g, "");
  const originalDate = bookingDetail?.data?.invoice_date|| null;
  let newdate;
  if(originalDate){
    const parts = originalDate.split(" ");
    const datePart = parts[0];
    const timePart = parts[1];
    const dateParts = datePart.split("-");
    const day = dateParts[2];
    const month = dateParts[1];
    const year = dateParts[0];
    newdate = `${day}-${month}-${year} ${timePart}`;
  }
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {staffNotify && deleteBookingSuccess?.message && (
        <Notification data={deleteBookingSuccess?.message} status="success" />
      )}

      {staffNotify && deleteBookingError?.message && (
        <Notification data={deleteBookingError?.message} status="error" />
      )}

      {delTimeSheetNotify && deleteTimeSheetSuccess?.message && (
        <Notification data={deleteTimeSheetSuccess?.message} status="success" />
      )}

      {delTimeSheetNotify && deleteTimeSheetError?.message && (
        <Notification data={deleteTimeSheetError?.message} status="error" />
      )}

      {cancelInvoiceNotify && cancelInvoiceSuccess?.message && (
        <Notification data={cancelInvoiceSuccess?.message} status="success" />
      )}

      {cancelInvoiceNotify && cancelInvoiceError?.message && (
        <Notification data={cancelInvoiceError?.message} status="error" />
      )}

      

      {bookingNotify && shiftStatusSuccess?.message && (
        <Notification
          data={shiftStatusSuccess?.message}
          status={shiftStatusSuccess?.status === true ? "success" : "error"}
        />
      )}
      {timesheetMsgError && timesheetMsgError?.message && (
        <Notification
          data={timesheetMsgError?.message}
          status={timesheetMsgError?.status == true ? "success" : "error"}
        />
      )}
      
      <Box className={classes.statusButton}>
          {tabList.map((list, index) => (
            <span
              style={{ textTransform: 'capitalize' }}
              className={`btn ${(localStorage.getItem('tabStatus') ? localStorage.getItem('tabStatus') : "0") == index ? 'active' : ''}`}
              key={index}
              onClick={() => backToPrevPage(index)}
            >
              {list.toLowerCase()}
            </span>
          ))}
        </Box>
      <Paper className={`${classes.root} mb-6`}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4} className={classes.statusContainer}>
            <span className={classes.statusLabel}>Booking Status:</span>
            <FormControl variant="standard" className={classes.formControl1}>
              <Select
                value={bookingDetail?.data?.status || ""}
                name="status"
                onChange={(e) =>
                  handleBookingStatus(e, bookingDetail?.data?.id)
                }
                disabled={
                  staffDetail === "Finance"
                    ? true
                    : false || bookingDetail?.data?.status === "CANCEL"
                      ? true
                      : false
                }
              >
                {bookingDetail?.data?.status === "CREATED" && (
                  <MenuItem value="CREATED" disabled>
                    Created
                  </MenuItem>
                )}

                {bookingDetail?.data?.status !== "CANCEL" && (
                  <MenuItem value="CONFIRMED">Confirmed</MenuItem>
                )}
                
                {bookingDetail?.data?.status === "CREDIT NOTES" && (
                  <MenuItem value="CREDIT NOTES">Credit Notes</MenuItem>
                )}

                {bookingDetail?.data?.timesheet != null ||
                  (bookingDetail?.data?.timesheet != "" && (
                    <MenuItem value="CANCEL">Cancel</MenuItem>
                  ))}
                {bookingDetail?.data?.timesheet == null ||
                  (bookingDetail?.data?.timesheet == "" && (
                    <MenuItem value="CANCEL" disabled>
                      Cancel
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <>


              {bookingDetail?.data?.is_past &&
                (userRole === "Admin" || userRole === "Finance" || loggedInRole === "ORGANIZATION" || loggedInRole === "SUPERADMIN") &&
                bookingDetail?.data?.status !== 'CREDIT NOTES' &&
                bookingDetail?.data?.timesheet != null &&
                bookingDetail?.data?.timesheet != "" ? (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={downloadPdf}
                  className={classes.downloadButton}
                >
                  <span className="material-icons mr-2">download</span> Generate
                  Invoice
                </Button>
              ) : (
                <div>
                  <Button
                    variant="contained"
                    disabled
                    color="secondary"
                    className={classes.downloadButton}
                  >
                    <span className="material-icons mr-2">download</span>{" "}
                    Generate Invoice
                  </Button>
                </div>
              )}
            </>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Reference ID:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.reference_id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Customer Name:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Site Name:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.hospital_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Ward Name:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.ward_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Grade:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.grade_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Booking Reference:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              <span className="symbol"></span>{" "}
              {bookingDetail?.data?.booking_reference}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Date:
            </Typography>
            <Typography variant="h6" className={classes?.desc}>
              {bookingDetail?.data?.date
                ?.toString()
                .split("-")
                .reverse()
                .join("-")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Shift Time:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.start_time} -{" "}
              {bookingDetail?.data?.end_time}
            </Typography>
          </Grid>
          {/* <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
                        <Typography variant="body2" className={classes.heading}>Shift Type :</Typography>
                        <Typography variant="h6" className={classes.desc}>{bookingDetail?.data?.shift_type}</Typography>
                    </Grid> */}

          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Break:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              <span className="symbol"></span> {bookingDetail?.data?.break}{" "}
              Minutes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Booking Type:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              <span className="symbol"></span>{" "}
              {bookingDetail?.data?.booking_type}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Speciality:
            </Typography>
            <div className={classes.chipContainer}>
              {bookingDetail?.data?.speciality != undefined &&
                bookingDetail?.data?.speciality != null &&
                bookingDetail?.data?.speciality &&
                bookingDetail?.data?.speciality.map((list, index) => {
                  return (
                    <Chip
                      className="mt-1"
                      label={list.speciality_name}
                      key={index}
                    />
                  );
                })}
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Purchase Order:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              <span className="symbol"></span>{" "}
              {bookingDetail?.data?.purchase_order}
            </Typography>
          </Grid>
          {bookingDetail?.data?.invoice_date != null &&
            bookingDetail?.data?.invoice_date !== "" && (
              <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
                <Typography variant="body2" className={classes.heading}>
                  Invoice Date:
                </Typography>
                <Typography variant="h6" className={classes.desc}>
                  <span className="symbol"></span>{" "}
                  {newdate}
                </Typography>
              </Grid>
            )}
          {bookingDetail?.data?.invoice_number != null &&
            bookingDetail?.data?.invoice_number !== "" && (
              <>
              <Grid item xs={12} sm={3} lg={2} className={classes.gridItem}>
                  <Typography variant="body2" className={classes.heading}>
                    Invoice Number:
                  </Typography>
                  <Typography variant="h6" className={classes.desc}>
                    <span className="symbol"></span>{" "}
                    {bookingDetail?.data?.invoice_number != null
                      ? bookingDetail?.data?.invoice_number
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} lg={4} className={classes.gridItem}>
                  {(staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION") &&
                    bookingDetail?.data?.status === "CONFIRMED" && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => cancelInvoiceBooking(bookingDetail?.data?.id)}
                      className={classes.downloadButton}
                    >
                      Cancel Invoice
                    </Button>
                  )}
                </Grid>
              </>
            )}
            
            <Grid item xs={12} sm={3} lg={4} className={classes.gridItem}>
              {(userRole === "Admin" || userRole === "Finance" || loggedInRole === "ORGANIZATION" || loggedInRole === "SUPERADMIN") &&
                bookingDetail?.data?.status === "CREDIT NOTES" && (
                <>
                  <Link
                    variant="contained"
                    color="secondary"
                    className="btn"
                    onClick={downloadCreditNotes}
                  >
                    <span className="material-icons mr-2">download</span>
                    <span> CREDIT NOTES {bookingDetail?.data?.invoice_number} .pdf</span>
                  </Link>
                </>
              )}
            </Grid>
          <Grid item xs={12}>
            <Typography>Amount</Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              NON AWR PAYE:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.non_awr_paye_payable}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              AWR PAYE:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.payable}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              NON AWR UMBRELLA:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.non_awr_umbrella_payable}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              AWR UMBRELLA:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.awr_umbrella_payable}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="body2" className={classes.heading}>
              Chargeable Amount Applied:
            </Typography>
            <Typography variant="h6" className={classes.desc}>
              {bookingDetail?.data?.chargeable}
            </Typography>
          </Grid>
          {bookingDetail?.data?.is_past && (bookingDetail?.data?.user_role === "Admin" || bookingDetail?.data?.user_role === "Finance" || bookingDetail?.data?.loggedin_role === "ORGANIZATION" || bookingDetail?.data?.loggedin_role === "SUPERADMIN") && (
            <Grid item xs={5}>
              {(bookingDetail?.data?.is_past &&
                  (bookingDetail?.data?.timesheet == null ||
                    bookingDetail?.data?.timesheet === "" ||
                    bookingDetail?.data?.status === "CONFIRMED" || bookingDetail?.data?.status === "CREDIT NOTES") && bookingDetail?.data?.status !== "CREATED") && 
                <Box display="flex" alignItems="center">
                  
                  <label>Timesheet: </label>
                  
                  <button
                    title="Upload Timesheet"
                    className="btn1 btn"
                    style={{
                      position: "relative",
                      backgroundColor: "#fff",
                      border: "none",
                      margin: "0 15px",
                    }}
                  >
                    <input
                      key={"s"}
                      accept="image/*,.pdf"
                      type="file"
                      multiple
                      name="timesheet"
                      onChange={(event) => setTimesheet(event)}
                      style={{
                        opacity: "0",
                        position: "absolute",
                        width: "100px",
                        height: "35px",
                        cursor: "pointer",
                      }}
                    />
                    <label class="mr-1">Upload</label> <PublishIcon />
                  </button>
                  
                  {bookingDetail?.data?.timesheet && (bookingDetail?.data?.timesheet != null) && (
                    <>
                      <Link
                        variant="contained"
                        color="secondary"
                        className="btn"
                        onClick={(event) => downloadTimeSheet(event)}
                      >
                        <span className="material-icons mr-2">download</span>
                        <span>Download</span>
                      </Link>
                    </>
                  )}
                  {bookingDetail?.data?.timesheet && (
                    <>
                      <Link
                        variant="contained"
                        color="secondary"
                        className="btn"
                        // onClick={(event) => downloadTimeSheet(event)}
                        to={{
                          pathname: `${baseUrl}${bookingDetail?.data?.timesheet}`,
                        }}
                        // onChange={(event) => downloadTimeSheet(event)}
                        target="_blank"
                      >
                        <span className="material-icons mr-2">picture_as_pdf</span>
                        <span>{bookingDetail?.data?.timesheet && bookingDetail?.data?.timesheet}</span>
                      </Link>
                    </>
                  )}

                  {bookingDetail?.data?.timesheet && (
                    <>
                      <Button color="secondary" onClick={() => deleteTimeSheet(bookingDetail?.data?.id)} >
                        <DeleteIcon />
                      </Button>
                    </>
                  )}
                </Box>
              }
            </Grid>
          )}

          <Grid item xs={12}>

            <Box
              display="flex"
              justifyContent="flex-end"
              className={classes.btnContainer}
            >
              <Button color="primary" onClick={backPage}>
                Back
              </Button>
              {(bookingDetail?.data?.status === "CREATED" || bookingDetail?.data?.custom_status == "PROVISIONAL") && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={upadateLink}
                >
                  <EditIcon className="mr-2" />
                  Edit
                </Button>
              )}
              {staffDetail !== "Finance" &&
                bookingDetail?.data?.status === "CANCEL" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClickOpen}
                  >
                    Reschedule Shift
                  </Button>
                )}
              {(staffDetail === "Admin" || loginUserInfo?.role === "ORGANIZATION") &&
                bookingDetail?.data?.status === "CREATED" && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(e) => deleteStaffItem(bookingDetail?.data?.id)}
                  >
                    <DeleteIcon className="mr-2" />
                    Delete
                  </Button>
                )}
            </Box>
          </Grid>
        </Grid>
        <RescheduleShift
          open={open}
          id={bookingDetail?.data?.id}
          handleClose={handleClose}
        />
      </Paper>

      {bookingDetail?.data?.status !== "CANCEL" && (
        <CandidatesList
          bookingDetail={bookingDetail}
          booking_id={booking_id}
          getBookingDetail={getBookingDetail}
          setConfirmBtn={setConfirmBtn}
          setBookingDetail={setBookingDetail}
          initialData={initialData}
          searchingVal={searchingVal}
          setSearchingVal={setSearchingVal}
          actionType='view'
        />
      )}

      <AlertDialog
        id={Id}
        open={deleteOpen}
        close={deleteBookingClose}
        response={alertResponse}
        title="Delete Booking"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />

      <AlertDialog
        id={timeSheetId}
        open={deleteTimeSheetOpen}
        close={deleteTimeSheetClose}
        response={deleteTimeSheetResponse}
        title="Delete Timesheet"
        description="Are you sure you want to delete?"
        buttonName="Delete"
      />

      <AlertDialog
        id={Id}
        open={cancelInvoiceOpen}
        close={cancelInvoiceClose}
        response={cancelInvoiceResponse}
        title="Cancel Invoice"
        description="Are you sure you want to cancel invoice?"
        buttonName="Yes"
      />

    </>
  );
};

export default DetailBooking;
