import * as React from "react";

import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
    headCells,
    activeIndex,
    rowCount,
    pdfDataCount,
    handleCheckboxAllClick,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {parseInt(activeIndex) == 4 && (
          <TableCell scope="row">
            <Checkbox
              checked={rowCount > 0 && pdfDataCount == rowCount ? true : false}
              onClick={(event) => handleCheckboxAllClick(event)}
              className="selectCheckbox"
            />
          </TableCell>
        )}
        {headCells.map((headCell, index) =>
          headCell?.activeIndexOnly ? (
            true && (
              <TableCell
                key={index}
                align={headCell.align}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy == headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy == headCell.id}
                  disabled={!headCell.sortable}
                  direction={orderBy == headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy == headCell.id ? (
                    <Box sx={visuallyHidden}>
                      {order == "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          ) : (
            <TableCell
              key={index}
              align={headCell.align}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy == headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy == headCell.id}
                disabled={!headCell.sortable}
                direction={orderBy == headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#475467",
                }}
              >
                {headCell.label}
                {orderBy == headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order == "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
