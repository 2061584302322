const useGooglePlaceAutoCompleteForClient = () => {

    const initAutoComplete = async (input, callback) => {
        let autoComplete =
            new window.google.maps.places.Autocomplete(input,
                {
                    componentRestrictions: { country: "UK" },
                    fields: ["formatted_address", "geometry","name"],
                }
            );
        autoComplete.addListener("place_changed", callback);

        return autoComplete;

    };

    const getFullAddress = async (autoComplete) => {
        const place = autoComplete.getPlace();
        var latitude = place.geometry.location.lat();
        var longitude = place.geometry.location.lng();
        let client_address=""

        // Get each component of the address from the place details,
        client_address = place.formatted_address;

        let resAddress = {
            "client_address": `${place.name} ${client_address}`,
            "latitude": latitude,
            "longitude": longitude,
        };

        return resAddress;
    };
    

    return {
        initAutoComplete,
        getFullAddress
    };

};

export {useGooglePlaceAutoCompleteForClient};