import React, { useEffect, useState } from 'react'
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { superAssign } from "../../store/action";


const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const SuperAssign = ({ open, handleClose, resetBooking, bookingMultiIds, bookingMultiRefs, getBookingList, multipleCandidate}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [formError, setError] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState("");

  const [data, setData] = useState({
    candidate:"",
    bookingIds: bookingMultiIds,
  }); 

  const onSubmitAssign = async (event) => {
    event.preventDefault();
    if (data.candidate === "") {
      setError("err_candidate");
    }else {
      dispatch(superAssign(data))
      getBookingList();
      resetBooking();
      handleClose();
      setData("");
    }
  };
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedCandidate(value);
    
    setData(prevData => ({
        ...prevData,
        [name]: value,
        bookingIds: bookingMultiIds
    }));
};

  var bookingRefs = bookingMultiRefs?.join(' , ');
  return (
    <>
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitAssign(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Super Assign Candidate </div>
          </DialogTitle>
          <Divider />
          <DialogContent>
          { bookingRefs && <p> You have selected Booking Ref No. <span style={{color:'#ff8b46', marginTop:'10px'}}>{bookingRefs}</span></p>}
          <FormControl
            className={classes.formControl}
            variant="outlined"
            style={{ maxWidth: "280px", width: '100%', marginTop:'10px'}}
            >
            <InputLabel>Select Candidate</InputLabel>
            <Select
              label="Select Candidate"
              name="candidate"
              // Set the value of Select to the state
              value={selectedCandidate}
              onChange={handleChange} // Update state on change
              error={formError.includes("err_candidate") ? true : false}
            >
                <MenuItem value="">Select Candidate</MenuItem>
                {/* <MenuItem value="1">Select Candidate1</MenuItem>
                <MenuItem value="2">Select Candidate2</MenuItem> */}
                {multipleCandidate &&
                    multipleCandidate.map((candidateData, index) => (
                        <MenuItem value={candidateData?.signeeId} key={index}>
                            {candidateData?.first_name} {candidateData?.last_name} ({candidateData?.email})
                        </MenuItem>
                    ))}
            </Select>
                
                
            </FormControl>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
            Super Assign 

            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default SuperAssign;
