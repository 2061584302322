import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { createHoliday } from "../../store/action";
import UtilService from "../../helper/service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const CreateHoliday = ({ open, handleClose }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [roleMsg, setRoleMsg] = useState(false);
  const { holidayErrors, holidaySuccess } = useSelector(
    (state) => state.holiday
  );
  const [data, setData] = useState({
    holiday_date: "",
    holiday_title: "",
  });
  const disPastDate = UtilService.disabledPastDate();

  const [formError, setError] = useState([]);
  const handleChange = (event) => {
    if (event.target.value) {
      setError([]);
    } else {
      setError("err_holiday_date");
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const onSubmitRoles = async (event) => {
    event.preventDefault();
    if (data.holiday_date === "") {
      setError("err_holiday_date");
    } else if (data.holiday_title === "") {
      setError("err_holiday_title");
    } else {
      let payload = {
        ...data,
       holiday_date: data?.holiday_date ? moment(data?.holiday_date).format("YYYY-MM-DD"):data?.holiday_date
      }
      dispatch(createHoliday(payload));
      setRoleMsg(true);
      handleClose();
      setData("");
    }
  };
  const dateHandler = (name,date)=>{
    setData({ ...data, [name]: date });
  }
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, holiday_date: "" });
  };
  return (
    <>
      {roleMsg && holidayErrors?.message && (
        <Notification data={holidayErrors?.message} status="error" />
      )}
      {roleMsg && holidaySuccess?.message && (
        <Notification data={holidaySuccess?.message} status="success" />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitRoles(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Create Holiday</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
              {/* <TextField
                autoFocus
                margin="dense"
                id="holiday_date"
                type="date"
                label="Holiday date"
                variant="outlined"
                name="holiday_date"
                fullWidth
                required
                value={data.holiday_date || ""}
                error={formError.includes("err_holiday_date") ? true : false}
                // inputProps={{
                //   min: disPastDate,
                // }}
                helperText={
                  formError.includes("err_holiday_date")
                    ? "Please enter Holiday date"
                    : false
                }
                onChange={handleChange}
              /> */}
               <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          autoFocus
                          margin="dense"
                          id="holiday_date"
                          type="date"
                          label="Holiday date"
                          variant="outlined"
                          name="holiday_date"
                          value={data.holiday_date || ""}
                          onChange={(e) =>dateHandler("holiday_date",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField  
                            fullWidth
                            required 
                            variant="outlined"  
                          
                            {...props}  
                            />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="holiday_title"
                  type="text"
                  label="Holiday title"
                  variant="outlined"
                  name="holiday_title"
                  fullWidth
                  required
                  value={data.holiday_title || ""}
                  error={formError.includes("err_holiday_title") ? true : false}
                  helperText={
                    formError.includes("err_holiday_title")
                      ? "Please enter title"
                      : false
                  }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateHoliday;
