import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import history from "../../../utils/HistoryUtils";
import {
  CREATE_STAFF_ERROR,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  DELETE_STAFF_ERROR,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  GET_STAFF_DETAIL_REQUEST,
  GET_COMPILANCE_OFFICER_DETAIL_REQUEST,
  GET_CANDIDATE_LIST_REQUEST,
  GET_CANDIDATE_MUTIPLE_LIST_REQUEST,
  GET_STAFF_DETAIL_SUCCESS,
  GET_STAFF_DETAIL_ERROR,
  GET_STAFF_ERROR,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  UPDATE_STAFF_ERROR,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
  STATUS_STAFF_ERROR,
  STATUS_STAFF_REQUEST,
  STATUS_STAFF_SUCCESS,
} from "../actiontypes";

export const getStaffDetail = (result) => {
  return async (dispatch) => {
    dispatch(getStaffDetailRequest());
    await apiClient(true)
      .get(`api/organization/get-staff`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getStaffDetailRequest = () => {
  return {
    type: GET_STAFF_DETAIL_REQUEST,
  };
};

export const getCompilanceOfficerDetail = (result) => {
  return async (dispatch) => {
    dispatch(getCompilanceOfficerDetailRequest());
    await apiClient(true)
      .get(`api/organization/get-compilance-officer`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getCompilanceOfficerDetailRequest = () => {
  return {
    type: GET_COMPILANCE_OFFICER_DETAIL_REQUEST,
  };
};

export const getCandidateList = (result) => {
  return async (dispatch) => {
    dispatch(getCandidateListRequest());
    await apiClient(true)
      .get(`api/organization/get-candidate-list`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

export const getCandidateMatchingList = (data) => {
  
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    await axios
      .post(`${Config.API_URL}api/organization/get-search-multiple-booking`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        return response?.data;
      })
      .catch((error) => {
        return error;
      });
  };
};



const getCandidateListRequest = () => {
  return {
    type: GET_CANDIDATE_LIST_REQUEST,
  };
};
const getCandidateMatchingListRequest = () => {
  return {
    type: GET_CANDIDATE_MUTIPLE_LIST_REQUEST,
  };
};

const getStaffDetailSuccess = (data) => {
  return {
    type: GET_STAFF_DETAIL_SUCCESS,
    payload: data,
  };
};

const getStaffDetailError = (error) => {
  return {
    type: GET_STAFF_DETAIL_ERROR,
    payload: error,
  };
};

export const getStaff = ({ pageNo = 1, search,type="",column_name=""}) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getStaffRequest());
    await axios
      .get(
        `${Config.API_URL}api/organization/user/get-user-list?search=${search}&page=${pageNo}&type=${type}&column_name=${column_name}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getStaffSuccess(dataItem));
        } else {
          dispatch(getStaffSuccess(""));
          dispatch(getStaffError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getStaffError(error));
      });
  };
};

const getStaffRequest = () => {
  return {
    type: GET_STAFF_REQUEST,
  };
};

const getStaffSuccess = (data) => {
  return {
    type: GET_STAFF_SUCCESS,
    payload: data,
  };
};

const getStaffError = (error) => {
  return {
    type: GET_STAFF_ERROR,
    payload: error,
  };
};

export const createStaff = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createStaffRequest());
    await axios
      .post(`${Config.API_URL}api/organization/user/signup-user`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(createStaffSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          dispatch(createStaffFailure(data));
        }
      })
      .catch((error) => {
        dispatch(createStaffFailure(error));
      });
  };
};

const createStaffRequest = () => {
  return {
    type: CREATE_STAFF_REQUEST,
  };
};

const createStaffSuccess = (data) => {
  return {
    type: CREATE_STAFF_SUCCESS,
    payload: data,
  };
};

const createStaffFailure = (error) => {
  return {
    type: CREATE_STAFF_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const updateStaff = (data) => {
  // const {user_id} = data;
  // const data1 = {...data, id:user_id}
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateStaffRequest());
    await axios
      .put(`${Config.API_URL}api/organization/user/edit-user`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(updateStaffSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          dispatch(updateStaffFailure(data));
        }
      })
      .catch((error) => {
        dispatch(updateStaffFailure(error));
      });
  };
};

const updateStaffRequest = () => {
  return {
    type: UPDATE_STAFF_REQUEST,
  };
};

const updateStaffSuccess = (data) => {
  return {
    type: UPDATE_STAFF_SUCCESS,
    payload: data,
  };
};

const updateStaffFailure = (error) => {
  return {
    type: UPDATE_STAFF_ERROR,
    payload: error,
  };
};

// --------------------------

export const deleteStaff = (id,asigneeId) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteStaffRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/user/delete-user/${id}/${asigneeId}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(deleteStaffSuccess(data));
          setTimeout(() => {
            history.go(-2);
          }, 2000);
        } else {
          dispatch(deleteStaffFailure(data));
        }
      })
      .catch((error) => {
        dispatch(deleteStaffFailure(error));
      });
  };
};

const deleteStaffRequest = () => {
  return {
    type: DELETE_STAFF_REQUEST,
  };
};

const deleteStaffSuccess = (data) => {
  return {
    type: DELETE_STAFF_SUCCESS,
    payload: data,
  };
};

const deleteStaffFailure = (error) => {
  return {
    type: DELETE_STAFF_ERROR,
    payload: error,
  };
};

// --------------------------

export const activeOrDeactiveStaff = (id,status) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(statusStaffRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/user/active-deactive-user/${id}/${status}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response?.data;
        if (data && data.status === true) {
          dispatch(statusStaffSuccess(data));
          setTimeout(() => {
            history.go(0);
          }, 100);
        } else {
          dispatch(statusStaffFailure(data));
        }
      })
      .catch((error) => {
        dispatch(statusStaffFailure(error));
      });
  };
};

const statusStaffRequest = () => {
  return {
    type: STATUS_STAFF_REQUEST,
  };
};

const statusStaffSuccess = (data) => {
  return {
    type: STATUS_STAFF_SUCCESS,
    payload: data,
  };
};

const statusStaffFailure = (error) => {
  return {
    type: STATUS_STAFF_ERROR,
    payload: error,
  };
};
