export const headCells = [
  //for zero(0) index for Trust/Customers
  [
    {
      id: "id",
      label: "Id",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "name",
      label: "Customer Name",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "speciality_name",
      label: "Speciality Name",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "grade_name",
      label: "Grade Name",
      sortable: true,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "day_from",
      label: "From",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "day_to",
      label: "To",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "remark",
      label: "Remark",
      sortable: false,
      align: "left",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "Invoice",
      label: "",
      sortable: false,
      align: "right",
      numIndex: 0,
      activeIndexOnly: true,
    },
    {
      id: "View",
      label: "",
      sortable: false,
      align: "right",
      numIndex: 0,
      activeIndexOnly: true,
    },
  ],
];
