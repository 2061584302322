import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  alpha,
  InputBase,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Backdrop,
  CircularProgress,
  Button,
  Checkbox,
  Menu,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  TableContainer,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import apiConfigs from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { getInvoice } from "../../store/action/invoice/invoiceAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import Notification from "../../components/Notification/Notification";
import axios from "axios";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getBookingTrust } from "../../store/action/booking/bookingAction";
import moment from "moment";
import { customEnLocale } from "../../helper/customLocale";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: 'auto',
    padding: 24,
  },

  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },

  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "800px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  formControl: {
    width: "24%",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,

    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: 140,
      cursor: "pointer",
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const Invoice = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(1);
  let firstDayOfWeek = getMonday(new Date());
  firstDayOfWeek = moment(firstDayOfWeek).format("MM-DD-yyyy");

  let lastDayOfWeek = getSunday(new Date());
  lastDayOfWeek = moment(lastDayOfWeek).format("MM-DD-yyyy");
  const { getInvoiceItem, loading } = useSelector((state) => state.invoice);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("invoice_number");
  const [timesheetMsgError, setTimesheetError] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [trust, setTrust] = useState([]);
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );

  const [searchData, setSearchData] = useState({
    search: "",
    customer: "",
    consultants: "",
    candidate: "",
    start_date: firstDayOfWeek,
    end_date: lastDayOfWeek,
    provisional_status: "",
  });

  function getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  function getSunday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 7);
    return new Date(d.setDate(diff));
  }

  const getTrust = async () => {
    dispatch(
      getBookingTrust((result) => {
        if (result?.status === true) {
          setTrust(result);
        }
      })
    );
  };
  const getInvoiceList = (
    pageNo = 1,
    search = "",
    customer = "",
    consultants = "",
    candidate = "",
    start_date = firstDayOfWeek,
    end_date = lastDayOfWeek,
    provisional_status = "",
    type = order,
    column_name = orderBy
  ) => {
    dispatch(
      getInvoice({
        pageNo,
        search,
        customer,
        consultants,
        candidate,
        start_date,
        end_date,
        provisional_status,
        type,
        column_name,
      })
    );
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setPdfData(pdfData);
    setTimeout(
      getInvoiceList(
        value,
        searchData.search,
        searchData?.customer,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? firstDayOfWeek
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? lastDayOfWeek
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        searchData?.provisional_status
      ),
      2000
    );
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    handleMenuClose(true);
    setPage(1);
    if (d1) {
      setTimeout(
        getInvoiceList(
          page,
          d1,
          searchData?.customer,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date === null
            ? firstDayOfWeek
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? lastDayOfWeek
            : moment(searchData?.end_date).format("DD-MM-yyyy"),
          searchData?.provisional_status
        ),
        100
      );
    } else {
      setTimeout(
        getInvoiceList(
          page,
          "",
          searchData?.customer,
          searchData?.consultants,
          searchData?.candidate,
          searchData?.start_date === null
            ? firstDayOfWeek
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? lastDayOfWeek
            : moment(searchData?.end_date).format("DD-MM-yyyy"),
          searchData?.provisional_status
        ),
        100
      );
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setPage(1);
    handleMenuClose(true);
    setTimeout(
      getInvoiceList(
        page,
        searchData.search,
        searchData?.customer,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? firstDayOfWeek
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? lastDayOfWeek
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        searchData?.provisional_status
      ),
      1000
    );
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const downloadCreditNotes = async (
    booking_id,
    invoice_number,
    invoice_name
  ) => {
    setLoadings(true);
    let fileName = invoice_number
      ? "CREDIT NOTES" + invoice_number + ".pdf"
      : "CREDIT NOTES" + invoice_name + ".pdf";
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/generate-credit-notes/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const downloadPdf = async (booking_id, invoice_number) => {
    setLoadings(true);
    const getToken = localStorage.getItem("token")
      ? localStorage.getItem("token").replace(/['"]+/g, "")
      : "";
    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/generate-invoice/${booking_id}`,
        {
          headers: {
            Authorization: getToken ? `Bearer ${getToken}` : "",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", invoice_number + ".pdf");
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const [pdfData, setPdfData] = useState({
    id: [],
  });

  const multipleInvoiceDownload = async () => {
    setLoadings(true);
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    axios
      .post(`${apiConfigs.API_URL}api/organization/multiple-invoice`, pdfData, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Invoice.pdf");
        document.body.appendChild(link);
        link.click();
        setLoadings(false);
      })
      .catch((error) => {
        setLoadings(false);
      });
  };

  const handleCheckboxClick = (event, ids) => {
    const multiplePdf = JSON.parse(JSON.stringify(pdfData));
    const isChecked = event.target.checked;
    if (isChecked) {
      multiplePdf.id.push(parseFloat(ids));
      setPdfData(multiplePdf);
    } else {
      const newData = multiplePdf.id.filter((item) => item !== parseFloat(ids));
      multiplePdf.id = newData;
      setPdfData(multiplePdf);
    }
  };

  const handleCheckboxAllClick = (event) => {
    const multiplePdf = JSON.parse(JSON.stringify(pdfData));
    const isChecked = event.target.checked;
    if (isChecked && getInvoiceItem?.data?.data.length > 0) {
      const createArray = [];
      getInvoiceItem?.data?.data.map((row, index) => {
        createArray.push(row.id);
      });
      multiplePdf.id = createArray;
      setPdfData(multiplePdf);
    } else {
      multiplePdf.id = [];
      setPdfData(multiplePdf);
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    let data = {
      search: "",
      candidate: "",
      customer: "",
      consultants: "",
      start_date: firstDayOfWeek,
      end_date: lastDayOfWeek,
      provisional_status: "",
    };
    setSearchData(data);
    handleMenuClose(true);
    setPage(1);
    setTimeout(
      getInvoiceList(
        page,
        "",
        searchData?.customer,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date,
        searchData?.end_date,
        searchData?.provisional_status
      ),
      100
    );
  };

  useEffect(() => {
    if (
      (loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION"
    ) {
      getTrust();
    }
  }, []);

  useEffect(() => {
    if (
      (loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION"
    ) {
      getInvoiceList(
        page,
        searchData.search,
        searchData?.customer,
        searchData?.consultants,
        searchData?.candidate,
        searchData?.start_date === null
          ? firstDayOfWeek
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? lastDayOfWeek
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        searchData?.provisional_status,
        order,
        orderBy
      );
    }
  }, [orderBy, order]);
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {loadings ? (
        <Backdrop className={classes.backdrop} open={loadings}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {timesheetMsgError && timesheetMsgError?.message && (
        <Notification
          data={timesheetMsgError?.message}
          status={timesheetMsgError?.status === true ? "success" : "error"}
        />
      )}

      {(loginUserInfo?.role === "STAFF" &&
        (loginUserInfo?.staffdetails === "Finance" ||
          (loginUserInfo?.staffdetails === "Admin" &&
            loginUserInfo?.is_smt === "Yes"))) ||
      loginUserInfo?.role === "ORGANIZATION" ? (
        <>
          <Paper className={`${classes.root} mb-6`}>
            <Box
              mb={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "24%" }}>
                <SearchIcon
                  className={classes.searchIcondet}
                  onClick={handleClickSearch}
                />
                <div className={classes.search}>
                  <InputBase
                    name="search"
                    placeholder="Search…"
                    onChange={handleSearchChange}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                  />
                </div>
              </Box>

              <Box sx={{ display: "flex", alignItems: "end" }}>
                <Tooltip title="Filters">
                  <Button onClick={handleMenu}>
                    <FilterListIcon />
                  </Button>
                </Tooltip>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                  open={openMenu}
                  onClose={handleMenuClose}
                  className={classes.filterBookingSearch}
                >
                  <div className={classes.filterBox}>
                    <Box className={classes.radioGroup}>
                      <Box mt={2} mb={1} display="flex" alignItems="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          flexGrow={1}
                        >
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                          >
                            <InputLabel>Customer</InputLabel>
                            <Select
                              label="Customer"
                              name="customer"
                              onChange={(e) =>
                                setSearchData({
                                  ...searchData,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={searchData?.customer}
                            >
                              <MenuItem value="">Select Customer</MenuItem>
                              {trust?.data != undefined &&
                                trust?.data &&
                                trust?.data.map((trustList, index) => {
                                  return (
                                    <MenuItem
                                      value={trustList.id}
                                      key={index + 11111}
                                    >
                                      {trustList.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>

                          <Box className={classes.formControl}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={customEnLocale}
                              localeText={{
                                start: "Check-in",
                                end: "Check-out",
                              }}
                            >
                              <DatePicker
                                label="Start Date"
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) =>
                                  setSearchData({
                                    ...searchData,
                                    start_date: newValue,
                                  })
                                }
                                firstDayOfWeek={1}
                                value={
                                  searchData?.start_date
                                    ? searchData?.start_date
                                    : firstDayOfWeek
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                          <Box className={classes.formControl}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={customEnLocale}
                              localeText={{
                                start: "Check-in",
                                end: "Check-out",
                              }}
                            >
                              <DatePicker
                                label="End Date"
                                inputFormat="dd/MM/yyyy"
                                onChange={(newValue) =>
                                  setSearchData({
                                    ...searchData,
                                    end_date: newValue,
                                  })
                                }
                                firstDayOfWeek={1}
                                value={
                                  searchData?.end_date
                                    ? searchData?.end_date
                                    : lastDayOfWeek
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>

                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                          >
                            <InputLabel>Provisional Status</InputLabel>
                            <Select
                              label="provisional_status"
                              name="provisional_status"
                              onChange={(e) =>
                                setSearchData({
                                  ...searchData,
                                  [e.target.name]: e.target.value,
                                })
                              }
                              value={searchData?.provisional_status}
                            >
                              <MenuItem value="">Select Status</MenuItem>
                              <MenuItem value="CONFIRMED" key={1111}>
                                CONFIRMED
                              </MenuItem>
                              <MenuItem value="CREDIT NOTES" key={11111}>
                                CREDIT NOTES
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box mt={2} mb={1} display="flex" alignItems="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          flexGrow={1}
                        >
                          <Box
                            sx={{ display: "flex", alignItems: "flex-end" }}
                            className={classes.formControl}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                marginRight: "16px",
                                marginLeft: "16px",
                              }}
                              className={classes.searchIcondet}
                              onClick={handleClickSearch}
                            >
                              Search
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </Menu>
                {pdfData.id.length > 0 && (
                  <div className="ml-5">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={multipleInvoiceDownload}
                      className={classes.downloadButton}
                    >
                      <span className="material-icons mr-2">download</span>{" "}
                      Download PDF
                    </Button>
                  </div>
                )}

                <div className="ml-5">
                  <Link
                    variant="contained"
                    style={{ textTransform: "capitalize" }}
                    color="secondary"
                    to="invoice-studio"
                    className="btn btn-secondary"
                  >
                    Invoice Studio
                  </Link>
                </div>
              </Box>
            </Box>

            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <EnhancedTableHead
                  handleCheckboxAllClick={handleCheckboxAllClick}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={(event, property) => {
                    handleRequestSort(
                      property,
                      setOrder,
                      setOrderBy,
                      orderBy,
                      order
                    );
                  }}
                  rowCount={getInvoiceItem?.data?.data?.length}
                  headCells={headCells[0]}
                  pdfDataCount={pdfData.id.length}
                />
                <TableBody>
                  {getInvoiceItem?.data?.data &&
                    getInvoiceItem?.data?.data.map((list, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell scope="row">
                            <Checkbox
                              value={list.id}
                              checked={pdfData.id.includes(list.id)}
                              onClick={(event) =>
                                handleCheckboxClick(event, list.id)
                              }
                              className="selectCheckbox"
                            />
                          </TableCell>
                          <TableCell scope="row">
                            {getInvoiceItem?.data?.from + index}
                          </TableCell>
                          <TableCell align="left">
                            {list.invoice_number}
                          </TableCell>
                          <TableCell align="left">{list.type}</TableCell>
                          <TableCell align="left">
                            {list.invoice_date}
                          </TableCell>
                          <TableCell align="left">{list.total_hours}</TableCell>
                          <TableCell align="left">
                            {list.customer_name}
                          </TableCell>
                          <TableCell align="left">
                            {list.reference_id}
                          </TableCell>

                          <TableCell align="right" style={{ width: 140 }}>
                            {list?.invoice && (
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() =>
                                  list.type === "Invoice"
                                    ? downloadPdf(list.id, list.invoice_number)
                                    : downloadCreditNotes(
                                        list.id,
                                        list.invoice_number,
                                        list.invoice
                                      )
                                }
                                className="btn "
                              >
                                Download
                              </Button>
                            )}
                          </TableCell>
                          <TableCell align="right" style={{ width: 100 }}>
                            {list?.invoice && (
                              <Link
                                variant="contained"
                                color="secondary"
                                to={`bookings/${list.id}/detail`}
                                className="btn btn-secondary"
                              >
                                View
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {!getInvoiceItem?.data?.data && (
                    <TableRow>
                      <TableCell scope="row" colSpan="8">
                        <div className="" align="center">
                          Sorry,invoice not available!
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className="mt-5" display="flex" justifyContent="flex-end">
              <Pagination
                onChange={handleChangePage}
                page={page}
                count={getInvoiceItem?.data?.last_page}
                showFirstButton
                showLastButton
              />
            </Box>
          </Paper>
        </>
      ) : (
        <div className="" align="center">
          <b>Sorry,You have no access!</b>
        </div>
      )}
    </>
  );
};

export default Invoice;
