import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import routes from "../routes.js";

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
  mainContainer: {
    marginLeft: drawerWidth,
  },
  innerMainContainer: {
    padding: "0 24px 0px",
  },
  sidebar: {
    width: drawerWidth,
  },
}));

const Admin = ({ match }) => {
  const classes = useStyles();
  const [custom_fullwindow, setcustom_fullwindow] = useState(false);
  const custom_fullwindowData = () => {
    if (custom_fullwindow) {
      setcustom_fullwindow(false);
    } else {
      setcustom_fullwindow(true);
    }
  };
  return (
    <>
      <Sidebar
        routes={routes}
        sidebarWidth={classes.sidebar}
        custom_fullwindow={custom_fullwindowData}
      />
      <div
        className={`${classes.mainContainer} ${
          custom_fullwindow ? `custom_fullwindow` : ""
        } `}
      >
        <Navbar />
        <div className={classes.innerMainContainer}>
          <Switch>
            {routes
              .filter((route) => route.role === "organization")
              .map((prop, key) => {
                return (
                  <Route
                    path={`${match.url}/${prop.path}`}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            <Redirect from="/admin" to="/admin/bookings" />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default Admin;
