import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Button,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Backdrop,
  CircularProgress,
  TableContainer,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Pagination } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateHoliday from "./CreateHoliday";
import UpdateHoliday from "./UpdateHoliday";

import AlertDialog from "../../components/Alert/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { deleteHoliday, getHoliday } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import EditIcon from "@material-ui/icons/Edit";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },
  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
}));

const Holiday = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [Id, setId] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [page, setPage] = useState(1);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { getHolidayItem, loading } = useSelector((state) => state.holiday);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("holiday_title");

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleClose = (action) => {
    setOpen(false);
    if (action === "update") {
      getHoliday(page);
    }
    setOpenUpdate(false);
  };

  const deleteHolidays = (holiday_id) => {
    setDisabled(true);
    setId(holiday_id);
    setDeleteOpen(true);
  };
  const deleteHolidayClose = () => {
    setDeleteOpen(false);
  };
  const alertResponse = (id) => {
    if (disabled === true) {
      dispatch(deleteHoliday(id));
    }
    setDisabled(false);
  };

  const handleUpdateClickOpen = (id) => {
    setOpenUpdate(true);
    setId(id);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    dispatch(getHoliday(orderBy, order));
  }, [dispatch, orderBy, order]);

  return (
    <>
      <Paper className={`${classes.root} mb-6`}>
        <Box
          className="mb-5"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <div className="ml-5">
            <Button
              variant="contained"
              style={{ textTransform: "capitalize" }}
              color="secondary"
              onClick={handleClickOpen}
            >
              <AddIcon className="mr-2" />
              Add Holiday
            </Button>
          </div>
        </Box>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={getHolidayItem?.data?.data?.length}
              headCells={headCells[0]}
            />

            <TableBody>
              {getHolidayItem?.data &&
                getHolidayItem.data.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">{index + 1}</TableCell>
                      <TableCell align="left">{row.holiday_date}</TableCell>
                      <TableCell align="left">{row.holiday_title}</TableCell>
                      <TableCell align="right">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="flex-end"
                        >
                          <IconButton
                            onClick={() => handleUpdateClickOpen(row.id)}
                            style={{ padding: 6 }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                          <IconButton
                            onClick={() => deleteHolidays(row.id)}
                            style={{ padding: 6 }}
                          >
                            <DeleteIcon color="secondary" />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <CreateHoliday open={open} handleClose={handleClose} />
      <UpdateHoliday
        openUpdate={openUpdate}
        handleClose={handleClose}
        id={Id}
      />
      <AlertDialog
        id={Id}
        response={alertResponse}
        open={deleteOpen}
        close={deleteHolidayClose}
        title="Delete Holiday"
        description="Are you sure you want to delete Holiday?"
        buttonName="Delete"
      />

      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
    </>
  );
};

export default Holiday;
