export const headCells = [
  //for zero(0) index
  [
    {
      id: 'checkbox',
      numeric: false,       
      label: '',
      sortable: false,
      align: 'left',
      numIndex: 0,
      activeIndexOnly:true
    },
    {
    id: 'id',
    numeric: false,       
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'name',
    numeric: false,    
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'hospital_name',
    label: 'Site Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'ward_name',
    label: 'Ward Name',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'grade_name',
    label: 'Grade',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'ShiftTime',
    label: 'Shift Time',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'createdByName',
    label: 'Created By',
    sortable: true,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
  {
    id: 'idleft',
    label: '',
    sortable: false,
    align: 'left',
    numIndex: 0,
    activeIndexOnly:true
  },
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 0,
    activeIndexOnly:true
  },],

  //for one(1) index
     [{
    id: 'id',
    numeric: false,       
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'name',
    numeric: false,    
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'hospital_name',
    label: 'Site Name',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'ward_name',
    label: 'Ward Name',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'candidate_name',
    label: 'Candidate',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'grade_name',
    label: 'Grade',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'ShiftTime',
    label: 'Shift Time',
    sortable: false,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
  {
    id: 'createdByName',
    label: 'Created By',
    sortable: true,
    align: 'left',
    numIndex: 1,
    activeIndexOnly:true
  },
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 1,
    activeIndexOnly:true
  },],


  //for two(2) index
    [{
    id: 'id',
    numeric: false,       
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'name',
    numeric: false,    
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'hospital_name',
    label: 'Site Name',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'ward_name',
    label: 'Ward Name',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'candidate_name',
    label: 'Candidate',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'grade_name',
    label: 'Grade',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'ShiftTime',
    label: 'Shift Time',
    sortable: false,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
  {
    id: 'createdByName',
    label: 'Created By',
    sortable: true,
    align: 'left',
    numIndex: 2,
    activeIndexOnly:true
  },
 
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 2,
    activeIndexOnly:true
  },],


  //for three(3) index
    [{
    id: 'id',
    numeric: false,       
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'name',
    numeric: false,    
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'hospital_name',
    label: 'Site Name',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'ward_name',
    label: 'Ward Name',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'candidate_name',
    label: 'Candidate',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'grade_name',
    label: 'Grade',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'ShiftTime',
    label: 'Shift Time',
    sortable: false,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  {
    id: 'createdByName',
    label: 'Created By',
    sortable: true,
    align: 'left',
    numIndex: 3,
    activeIndexOnly:true
  },
  
    {
    id: 'Invoice',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 3,
    activeIndexOnly:true
  },],

   //for four(4) index
  [
  {
    id: 'id',
    numeric: false,       
    label: 'Id',
    sortable: false,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'name',
    numeric: false,    
    label: 'Customer Name',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'hospital_name',
    label: 'Site Name',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'ward_name',
    label: 'Ward Name',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'candidate_name',
    label: 'Candidate',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'grade_name',
    label: 'Grade',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'invoice_date',
    label: 'Invoice-Date',
    sortable: false,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'ShiftTime',
    label: 'Shift Time',
    sortable: false,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'createdByName',
    label: 'Created By',
    sortable: true,
    align: 'left',
    numIndex: 4,
    activeIndexOnly:true
  },

  {
    id: 'Invoice',
    label: 'Invoice',
    sortable: false,
    align: 'right',
    numIndex: 4,
    activeIndexOnly:true
  },
  {
    id: 'test',
    label: '',
    sortable: false,
    align: 'right',
    numIndex: 4,
    activeIndexOnly:true
  },],
]
