import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { addExpense, getBookingInvoice } from "../../store/action";
import { notificationSuccess } from "../../store/action/notificationMsg";
import moment from "moment";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const AddExpense = ({ open, handleClose, bookingRef, bookingCandidate , type, candidateId,setActiveTab,tempId,getTempTimesheetDetail,nextId,resetCheckboxes,getBookingList,page,searchData,order,firstDayOfWeek,lastDayOfWeek,orderBy,setBookingId,setBookingRef, resetBooking}) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [roleMsg, setRoleMsg] = useState(false);
  const { holidayErrors, holidaySuccess } = useSelector(
    (state) => state.holiday
  );
  const [data, setData] = useState({
    amount_charged: "",
    amount_paid: "",
    charge_rate:"",
    expense_unit:"",
    pay_rate:"",
    expense_type:"",
    candidate_id:"",
    comment:"",
    type:""
  });
  const expenseType = [
    {id:'Mileage' ,name:'Mileage'},
    {id:'Accommodation' ,name:'Accommodation'},
    {id:'Nights/Saturdays' ,name:'Nights/Saturdays'},
    {id:'Days' ,name:'Days'},
    {id:'Sundays/Bank Holidays' ,name:'Sundays/Bank Holidays'},
    {id:'Referral' ,name:'Referral'},
    {id:'Parking' ,name:'Parking'},
    {id:'Others' ,name:'Others'},
  ];

  const [formError, setError] = useState([]);
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
    setData(prevData => ({
        ...prevData,
        bookingCandidate: bookingCandidate ? bookingCandidate : "",
        candidate_id: candidateId? candidateId : "",
        type: type
      }));
  };

  const handleChangeExpenseUnit = (event) => {
    const amountPaid = parseFloat(event.target.value) * parseFloat(data.pay_rate)
    const amountCharged = parseFloat(event.target.value) * parseFloat(data.charge_rate)
       setData(prevData => ({
      ...prevData,
        expense_unit: event.target.value,
        amount_paid: amountPaid.toFixed(2),
        amount_charged: amountCharged.toFixed(2),
      }));
  };

  const handleChangePayrate = (event) =>{
       setData(prevData => ({
      ...prevData,
        pay_rate: event.target.value, 
        amount_paid: data.expense_unit? (parseFloat(event.target.value) * parseFloat(data.expense_unit)).toFixed(2):(prevData.amount_paid).toFixed(2), 
      }));
  }

  const handleChangeChargerate = (event) =>{
    setData(prevData => ({
      ...prevData,
      charge_rate: event.target.value, // Update the specific key with the new value
    }));
    const amountCharged = parseFloat(event.target.value) * parseFloat(data.expense_unit)
    if(data.expense_unit){
      setData(prevData => ({
        ...prevData,
        amount_charged: amountCharged.toFixed(2), // Update the specific key with the new value
      }));
    }
  }

  const onSubmitExpense = async (event) => {
    // setLoadings(true)
    event.preventDefault();
    if (data.expense_type === "") {
      setError("err_expense_type");
    } else if (data.amount_charged === "") {
      setError("err_amount_charged");
    }else if (data.amount_paid === "") {
      setError("err_amount_paid");
    }else if (data.charge_rate === "") {
      setError("err_charge_rate");
    }else if (data.expense_unit === "") {
      setError("err_expense_unit");
    } else if (data.pay_rate === "") {
      setError("err_pay_rate");
    }else {
      dispatch(addExpense({...data,tempId}))
      resetBooking();
      bookingRef?.join(' , ') && getTempTimesheetDetail && getTempTimesheetDetail(nextId,'null')
      setRoleMsg(true);
      handleClose();
      setData("");
      if(setActiveTab){
        setActiveTab("EXPENSES");
      }
      // dispatch(notificationSuccess(''))
      // setLoadings(false)
      // dispatch()
      // window.location.reload()
 bookingRef?.join(' , ') &&  setTimeout(() => {
        bookingRef?.join(' , ') && resetCheckboxes && resetCheckboxes();
      bookingRef?.join(' , ') && getBookingList &&  getBookingList( page,
              searchData.search,
              'PROVISIONAL',
              searchData?.specialty,
              searchData?.customer,
              searchData?.site,
              searchData?.ward,
              searchData?.consultants,
              searchData?.candidate,
              searchData?.start_date === null
                ? firstDayOfWeek
                : moment(searchData?.start_date).format('DD-MM-yyyy'),
              searchData?.end_date === null
                ? lastDayOfWeek
                : moment(searchData?.end_date).format('DD-MM-yyyy'),
                searchData?.provisional_status,
                order,
                orderBy);
      bookingRef?.join(' , ') && setBookingId &&  setBookingId([])
      bookingRef?.join(' , ') && setBookingRef &&  setBookingRef([])
      }, 3000);
    }
  };
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, holiday_date: "" });
  };
  var bookingRefrence = bookingRef?.join(' , ');
  return (
    <>
      {roleMsg && holidayErrors?.message && (
        <Notification data={holidayErrors?.message} status="error" />
      )}
      {roleMsg && holidaySuccess?.message && (
        <Notification data={holidaySuccess?.message} status="success" />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitExpense(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Add Expense</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            { bookingRefrence && <p> You have selected Booking Ref No. <span style={{color:'#ff8b46'}}>{bookingRefrence}</span></p>}
            <FormControl style={{ maxWidth: "280px", width: '100%'}} variant="outlined">
            <InputLabel id="select-label" htmlFor="expense_type">Select Expense type</InputLabel>
                <Select
                    value={data.expense_type}
                    label="Expense Type"
                    name="expense_type"
                    onChange={handleChange}
                    id="expense_type"
                    error={formError.includes("err_expense_type") ? true : false}
                >
                    {expenseType !== undefined &&
                    expenseType &&
                    expenseType.map((expenseType, index) => {
                        return (
                        <MenuItem value={expenseType.id} key={index}>
                            {expenseType.name}
                        </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
            <Grid container spacing={2} xs={12} style={{ marginTop:'10px'}}>
              <Grid item xs={4}>
                <TextField
                  id="expense_unit"
                  type="text"
                  label="Expense Unit"
                  variant="outlined"
                  name="expense_unit"
                  fullWidth
                  required
                  value={data.expense_unit || ""}
                  error={formError.includes("err_expense_unit") ? true : false}
                  helperText={
                    formError.includes("err_expense_unit")
                      ? "Please enter title"
                      : false
                  }
                  onChange={handleChangeExpenseUnit}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  
                  id="pay_rate"
                  type="text"
                  label="Pay Rate"
                  variant="outlined"
                  name="pay_rate"
                  fullWidth
                  required
                  value={data.pay_rate || ""}
                  error={formError.includes("err_pay_rate") ? true : false}
                  helperText={
                    formError.includes("err_pay_rate")
                      ? "Please enter title"
                      : false
                  }
                  onChange={handleChangePayrate}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  
                  id="amount_paid"
                  type="text"
                  label="Amount Paid"
                  variant="outlined"
                  name="amount_paid"
                  fullWidth
                  required
                  value={data.amount_paid || ""}
                  error={formError.includes("err_amount_paid") ? true : false}
                  helperText={
                    formError.includes("err_amount_paid")
                      ? "Please enter title"
                      : false
                  }
                  disabled={true}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} xs={12} style={{ marginTop:'10px'}}>
              <Grid item xs={4}>
                
              </Grid>
              <Grid item xs={4}>
                <TextField
                  
                  id="charge_rate"
                  type="text"
                  label="Charge Rate"
                  variant="outlined"
                  name="charge_rate"
                  fullWidth
                  required
                  value={data.charge_rate || ""}
                  error={formError.includes("err_charge_rate") ? true : false}
                  helperText={
                    formError.includes("err_charge_rate")
                      ? "Please enter title"
                      : false
                  }
                  onChange={handleChangeChargerate}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  
                  id="amount_charged"
                  type="text"
                  label="Amount Charged"
                  variant="outlined"
                  name="amount_charged"
                  fullWidth
                  required
                  value={data.amount_charged || ""}
                  error={formError.includes("err_amount_charged") ? true : false}
                  helperText={
                    formError.includes("err_amount_charged")
                      ? "Please enter title"
                      : false
                  }
                  disabled={true}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="comment"
                  type="text"
                  label="comment"
                  variant="outlined"
                  name="comment"
                  fullWidth
                  value={data.comment || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions className="pr-4 pb-2">
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="submit">
             { bookingRefrence ?
              'Add and Link':'Add'}

            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddExpense;
