import React, { useEffect, useState } from "react";

import {
  Paper,
  makeStyles,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  InputBase,
  Tooltip,
  Button,
  Menu,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TableContainer,
} from "@material-ui/core";

import { Pagination } from "@material-ui/lab";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useDispatch, useSelector } from "react-redux";
import {
  getSystemLogs,
  getModules,
  getOperations,
} from "../../store/action/systemLogs/systemLogsAction";

import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import moment from "moment";
import { customEnLocale } from "../../helper/customLocale";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },

  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
  table: {
    minWidth: 700,
    "& .MuiTableCell-root": {
      padding: "8px !important",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "800px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  formControl: {
    width: "24%",
  },
  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,

    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: 140,
      cursor: "pointer",
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const SystemLogs = () => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const { getSystemLogsItem, loading } = useSelector(
    (state) => state.systemLogs
  );

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("type");
  const [searchData, setSearchData] = useState({
    search: "",
    operation: "",
    module: "",
    start_date: null,
    end_date: null,
  });
  const [operationList, setOpertationList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );
  const getSystemLogList = (
    pageNo = 1,
    search,
    operation,
    module,
    start_date,
    end_date,
    type = order,
    column_name = orderBy
  ) => {
    dispatch(
      getSystemLogs({
        pageNo,
        search,
        operation,
        module,
        start_date,
        end_date,
        type,
        column_name,
      })
    );
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (pageNo, value) => {
    setPage(value);
    setTimeout(
      getSystemLogList(
        value,
        searchData?.search,
        searchData?.operation,
        searchData?.module,
        searchData?.start_date === null
          ? ""
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? ""
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        order,
        orderBy
      ),
      2000
    );
  };

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(
        getSystemLogList(
          page,
          d1,
          searchData?.operation,
          searchData?.module,
          searchData?.start_date === null
            ? ""
            : moment(searchData?.start_date).format("DD-MM-yyyy"),
          searchData?.end_date === null
            ? ""
            : moment(searchData?.end_date).format("DD-MM-yyyy"),
          order,
          orderBy
        ),
        1000
      );
    } else {
      setTimeout(
        getSystemLogList(
          page,
          (searchData.search = ""),
          (searchData.operation = ""),
          (searchData.module = ""),
          (searchData.start_date = null),
          (searchData.end_date = null),
          order,
          orderBy
        ),
        1000
      );
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setPage(1);
    handleMenuClose(true);
    setTimeout(
      getSystemLogList(
        page,
        searchData?.search,
        searchData?.operation,
        searchData?.module,
        searchData?.start_date === null
          ? ""
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? ""
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        order,
        orderBy
      ),
      1000
    );
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    let data = {
      search: "",
      operation: "",
      module: "",
      start_date: null,
      end_date: null,
    };
    setPage(1);
    setSearchData(data);
    handleMenuClose(true);
    setTimeout(
      getSystemLogList(
        page,
        (data.search = ""),
        (data.operation = ""),
        (data.module = ""),
        (data.start_date = null),
        (data.end_date = null),
        order,
        orderBy
      ),
      100
    );
  };

  const getOpertationList = async () => {
    dispatch(
      getOperations((result) => {
        if (result?.status === true) {
          setOpertationList(result);
        }
      })
    );
  };

  const getModuleList = async () => {
    dispatch(
      getModules((result) => {
        if (result?.status === true) {
          setModuleList(result);
        }
      })
    );
  };

  useEffect(() => {
    if (
      loginUserInfo?.role === "SUPERADMIN" ||
      loginUserInfo?.role === "ORGANIZATION" ||
      loginUserInfo?.is_smt === "Yes" ||
      loginUserInfo?.role === "SUPERADMIN"
    ) {
      getOpertationList();
      getModuleList();
    }
  }, []);

  useEffect(() => {
    if (
      loginUserInfo?.role === "SUPERADMIN" ||
      loginUserInfo?.role === "ORGANIZATION" ||
      loginUserInfo?.is_smt === "Yes" ||
      loginUserInfo?.role === "SUPERADMIN"
    ) {
      getSystemLogList(
        page,
        searchData?.search,
        searchData?.operation,
        searchData?.module,
        searchData?.start_date === null
          ? ""
          : moment(searchData?.start_date).format("DD-MM-yyyy"),
        searchData?.end_date === null
          ? ""
          : moment(searchData?.end_date).format("DD-MM-yyyy"),
        order,
        orderBy
      );
    }
  }, [dispatch, orderBy, order]);

  return (
    <>
      {loginUserInfo?.role === "ORGANIZATION" ||
      loginUserInfo?.is_smt === "Yes" ||
      loginUserInfo?.role === "SUPERADMIN" ? (
        <Paper className={`${classes.root} mb-6`}>
          <Box
            mb={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "24%" }}>
              <SearchIcon
                className={classes.searchIcondet}
                onClick={handleClickSearch}
              />
              <div className={classes.search}>
                <InputBase
                  name="search"
                  placeholder="Search…"
                  onChange={handleSearchChange}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                />
              </div>
            </Box>

            <Box sx={{ display: "flex", alignItems: "end" }}>
              <Tooltip title="Filters">
                <Button onClick={handleMenu}>
                  <FilterListIcon />
                </Button>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={openMenu}
                onClose={handleMenuClose}
                className={classes.filterBookingSearch}
              >
                <div className={classes.filterBox}>
                  <Box className={classes.radioGroup}>
                    <Box mt={2} mb={2} display="flex" alignItems="center">
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        flexGrow={1}
                      >
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <InputLabel>Modules</InputLabel>
                          <Select
                            label="Modules"
                            name="module"
                            onChange={(e) =>
                              setSearchData({
                                ...searchData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            value={searchData?.module}
                          >
                            <MenuItem value="">Select Module</MenuItem>
                            {moduleList?.data != undefined &&
                              moduleList?.data &&
                              moduleList?.data.map((items, index) => {
                                return (
                                  <MenuItem
                                    value={items.module_name}
                                    key={index + 55}
                                  >
                                    {items.module_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <FormControl
                          className={classes.formControl}
                          variant="outlined"
                        >
                          <InputLabel>Operations</InputLabel>
                          <Select
                            label="Operations"
                            name="operation"
                            onChange={(e) =>
                              setSearchData({
                                ...searchData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            value={searchData?.operation}
                          >
                            <MenuItem value="">Select Operation</MenuItem>
                            {operationList?.data != undefined &&
                              operationList?.data &&
                              operationList?.data.map((List, index) => {
                                return (
                                  <MenuItem value={List.type} key={index + 111}>
                                    {List.type}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>

                        <Box className={classes.formControl}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={customEnLocale}
                            localeText={{ start: "Check-in", end: "Check-out" }}
                          >
                            <DatePicker
                              label="Start Date"
                              inputFormat="dd/MM/yyyy"
                              onChange={(newValue) =>
                                setSearchData({
                                  ...searchData,
                                  start_date: newValue,
                                })
                              }
                              value={searchData?.start_date}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                />
                              )}
                              firstDayOfWeek={1}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box className={classes.formControl}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={customEnLocale}
                            localeText={{ start: "Check-in", end: "Check-out" }}
                          >
                            <DatePicker
                              label="End Date"
                              inputFormat="dd/MM/yyyy"
                              onChange={(newValue) =>
                                setSearchData({
                                  ...searchData,
                                  end_date: newValue,
                                })
                              }
                              firstDayOfWeek={1}
                              value={searchData?.end_date}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  style={{ width: "100%" }}
                                  variant="outlined"
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Box>
                    </Box>

                    <Box mt={2} mb={1} display="flex" alignItems="center">
                      <Box
                        sx={{ display: "flex", alignItems: "flex-end" }}
                        className={classes.formControl}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          style={{ marginRight: "16px", marginLeft: "16px" }}
                          className={classes.searchIcondet}
                          onClick={handleClickSearch}
                        >
                          Search
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Menu>
            </Box>
          </Box>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={(event, property) => {
                  handleRequestSort(
                    property,
                    setOrder,
                    setOrderBy,
                    orderBy,
                    order
                  );
                }}
                rowCount={getSystemLogsItem?.data?.data?.length}
                headCells={headCells[0]}
              />

              <TableBody>
                {getSystemLogsItem?.data?.data &&
                  getSystemLogsItem.data?.data.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell scope="row">{row.id}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.module_name}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.created_by}</TableCell>
                        <TableCell align="left">{row.created_date}</TableCell>
                      </TableRow>
                    );
                  })}
                {!getSystemLogsItem?.data?.data && (
                  <TableRow>
                    <TableCell scope="row" colSpan="8">
                      <div className="" align="center">
                        Sorry,System logs not available!
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="mt-5" display="flex" justifyContent="flex-end">
            <Pagination
              onChange={handleChangePage}
              page={page}
              count={getSystemLogsItem?.data?.last_page}
              showFirstButton
              showLastButton
            />
          </Box>
        </Paper>
      ) : (
        <div className="" align="center">
          <b>Sorry,You have no access!</b>
        </div>
      )}

      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
    </>
  );
};

export default SystemLogs;
