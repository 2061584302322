import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Divider,
} from "@material-ui/core";
import Notification from "../../components/Notification/Notification";
import { useDispatch, useSelector } from "react-redux";
import { addContactEventForSignee } from "../../store/action";
import { useEffect } from "react";
import { addContactRemarkForSignee, getSingleSignee } from "../../store/action/signee/signeeAction";

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const CreateRemarksEvent = ({ open, handleClose, signee_id,remark,setRemarkGeneral,textdata }) => {

  const classes = useStyle();
  const dispatch = useDispatch();
  const [eventMsg, seteventMsg] = useState(false);
  const { addCandidateRemarkSuccess, addCandidateRemarkError } = useSelector(
    (state) => state.signee
  );
  const [data, setData] = useState({
    document_id:remark.document_id ? remark.document_id.id : null,
    signee_id: signee_id,
    document_subkey:remark.document_subkey,
    remark:textdata
  });
  const [formError, setError] = useState([]);

useEffect(()=>{
    setData({
        document_id:remark.document_id ? remark.document_id.id : null,
        signee_id: signee_id,
        document_subkey:remark.document_subkey,
        remark:textdata
      })
},[remark])
const handleChange = (event) => {
    if (event.target.value) {
      setError([]);
    } else {
      setError("err_comment");
    }
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const onSubmitEvent = async (event) => {
    event.preventDefault();
    data.signee_id = signee_id;
    if (data.comment === "") {
      setError("err_comment");
    } else {
      dispatch(addContactRemarkForSignee(data,setRemarkGeneral)).then(
        (data)=> {
            dispatch(getSingleSignee(signee_id));
        }
        ).catch((e)=>{
          console.log(e);
        });

      seteventMsg(true);
      handleClose();
      setData("");
    }
  };
  const closeModal = () => {
    resetForm();
    setData("");
    handleClose();
  };

  const resetForm = () => {
    setError([]);
    setData({ ...data, remark: "" });
  };
  return (
    <>
      {eventMsg && addCandidateRemarkError?.message && (
        <Notification data={addCandidateRemarkError?.message} status="error" />
      )}
      {eventMsg && addCandidateRemarkSuccess?.message && (
        <Notification
          data={addCandidateRemarkSuccess?.message}
          status="success"
        />
      )}
      <Dialog
        open={open}
        onClose={closeModal}
        classes={{ paper: classes.dialogWidth }}
      >
        <form onSubmit={(event) => onSubmitEvent(event)}>
          <DialogTitle id="form-dialog-title">
            <div>Create Remarks Event</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
              <TextareaAutosize
                margin="dense"
                variant="outlined"
                minRows={4}
                aria-label="Please Enter Comment"
                placeholder="Please Enter Comment"
                defaultValue=""
                name="remark"
                required={true}
                value={data.remark}
                style={{ width: "100%" }}
                onChange={handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
        
            <Button onClick={closeModal} color="primary">
              Cancel
            </Button>
            <Button color="secondary" variant="contained" type="reset">
              RESET
            </Button>
            <Button color="secondary" variant="contained" type="submit">
              SUBMIT
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateRemarksEvent;
