import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  alpha,
  Backdrop,
  CircularProgress,
  FormControl,
  TableRow,
  TableCell,
  Paper,
  makeStyles,
  Button,
  Box,
  Menu,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  InputBase,
  TableContainer,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { getCompliance } from "../../store/action";
import history from "../../utils/HistoryUtils";
import FilterListIcon from "@material-ui/icons/FilterList";
import axios from "axios";
import apiConfigs from "../../config/config";
import { TextField } from "@mui/material";
import { getStaffDetail } from "../../store/action/staff/staffAction";
import { getSpecShiftCreate } from "../../store/action/specialities/specialitiesAction";
import {
  createFilter,
  deleteFilter,
} from "../../store/action/booking/bookingAction";
import { getFilterData } from "../../store/action/signee/signeeAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import { green } from "@material-ui/core/colors";
import { useGooglePlaceAutoCompleteForCandidate } from "../../components/GoogleAPI/google_place_autocomplete";
import { notificationSuccess } from "../../store/action/notificationMsg";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { customEnLocale } from "../../helper/customLocale";
const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },
  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    width: "100%",
  },
  formControl1: {
    width: 120,
  },

  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "400px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
}));

const ComplianceDocument = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { getComplianceItem, loading, getVCFilterDataSuccess } = useSelector(
    (state) => state.compliance
  );
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    // Calculate default start and end dates
    const today = new Date();
    const defaultStartDate = new Date(
      today.getTime() - 15 * 24 * 60 * 60 * 1000
    ); // Today - 15 days
    const defaultEndDate = new Date(today.getTime() + 15 * 24 * 60 * 60 * 1000); // Today + 15 days

    // Set default dates
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, []);

  const [searchData, setSearchData] = useState({
    search: "",
    start_date: "",
    end_date: "",
    module_name: "compliance-document",
  });
  const address1Ref = useRef();
  const googleAutoCompleteSvc = useGooglePlaceAutoCompleteForCandidate();
  let autoComplete = "";
  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
    setSearchData((prevData) => ({
      ...prevData,
      postcode: addressObj.address_line_1,
      latitude: addressObj.latitude,
      longitude: addressObj.longitude,
    }));
  };

  const handleChange = (event) => {
    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element.
      // eslint-disable-next-line
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    loadGoogleMaps();
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  let staffDetail = JSON.parse(localStorage.getItem("staffDetail"));

  staffDetail = staffDetail && staffDetail.replace(/['"]+/g, "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState("ASC");
  const [orderBy, setOrderBy] = React.useState("candidate_name");

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(
        getComplianceList(
          searchPage,
          d1,
          searchData?.start_date
            ? moment(searchData?.start_date).format("DD-MM-yyyy")
            : startDate,
          searchData?.end_date
            ? moment(searchData?.end_date).format("DD-MM-yyyy")
            : endDate
        ),
        100
      );
    } else {
      setTimeout(
        getComplianceList(
          page,
          "",
          searchData?.start_date
            ? moment(searchData?.start_date).format("DD-MM-yyyy")
            : startDate,
          searchData?.end_date
            ? moment(searchData?.end_date).format("DD-MM-yyyy")
            : endDate
        ),
        100
      );
      setSearchPage(1);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleMenu = (event) => {
    if (searchData.search === "") {
      getFilterDataByModule();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // filter status store
  const handleChangeFilterStatus = (event) => {
    setSearchData({ ...searchData, status: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    setTimeout(
      getComplianceList(
        page,
        searchData?.search
          ? searchData?.search
          : getVCFilterDataSuccess?.data?.search,
        "",
        "",
        searchData?.start_date
          ? moment(searchData?.start_date).format("DD-MM-yyyy")
          : startDate,
        searchData?.end_date
          ? moment(searchData?.end_date).format("DD-MM-yyyy")
          : endDate,
        statusFilter
      ),
      1000
    );
    handleMenuClose(true);
  };

  const getComplianceList = (
    pageNo = 1,
    search = "",
    type = order,
    column_name = orderBy,
    start_date = "",
    end_date = "",
    status = ""
  ) => {
    // let tempData = []
    console.log(start_date);
    dispatch(
      getCompliance({
        pageNo,
        search,
        type,
        column_name,
        start_date,
        end_date,
        status,
      })
    );
  };
  const [specError, setSpecError] = useState(false);
  const [staff, setStaff] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );

  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
        }
      })
    );
  };

  const onhandlClick = async (id) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    let formData = {
      id: id,
    };
    await axios
      .post(
        `${apiConfigs.API_URL}api/organization/send-mail-to-candidate`,
        formData,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        getComplianceList(
          page,
          "",
          order,
          orderBy,
          moment(startDate).format("DD-MM-yyyy"),
          moment(endDate).format("DD-MM-yyyy")
        );
        dispatch(notificationSuccess("Reminder Sent Succesfully"));
      })
      .catch((error) => {
        console.log("error.message", error.message);
      });
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(
      getComplianceList(
        value,
        searchData?.search
          ? searchData?.search
          : getVCFilterDataSuccess?.data?.search,
        "",
        "",
        searchData?.start_date
          ? moment(searchData?.start_date).format("DD-MM-yyyy")
          : moment(startDate).format("DD-MM-yyyy"),
        searchData?.end_date
          ? moment(searchData?.end_date).format("DD-MM-yyyy")
          : endDate
      ),
      2000
    );
  };

  const handleReset = () => {
    let data = {
      search: "",
      module_name: "compliance-document",
    };
    setSearchData(data);
    handleMenuClose(true);
    setTimeout(getComplianceList(page, "", ""), 100);
    dispatch(deleteFilter(data, "compliance-document"));
  };

  const getFilterDataByModule = () => {
    let data = {
      search: "",
      module_name: "compliance-document",
    };
    dispatch(getFilterData("compliance-document"));
    if (getVCFilterDataSuccess?.data) {
      if (getVCFilterDataSuccess?.data?.status !== "") {
        data.status = getVCFilterDataSuccess?.data?.status;
      }
      if (getVCFilterDataSuccess?.data?.specialty !== "") {
        data.specialty = getVCFilterDataSuccess?.data?.specialty;
      }
      if (getVCFilterDataSuccess?.data?.postcode !== "") {
        data.postcode = getVCFilterDataSuccess?.data?.postcode;
      }
      if (getVCFilterDataSuccess?.data?.consultants !== "") {
        data.consultants = getVCFilterDataSuccess?.data?.consultants;
      }
      if (getVCFilterDataSuccess?.data?.compliance !== "") {
        data.compliance = getVCFilterDataSuccess?.data?.compliance;
      }
      setSearchData(data);
    }
  };

  const handleClickSave = async () => {
    dispatch(createFilter(searchData, "compliance-document"));
    setPage(1);
    getComplianceList(
      1,
      searchData?.search
        ? searchData?.search
        : getVCFilterDataSuccess?.data?.search,

      order,
      orderBy
    );
    handleMenuClose(true);
  };

  useEffect(() => {
    if (startDate) {
      setSearchData({
        ...searchData,
        start_date: startDate,
      });
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      setSearchData({
        ...searchData,
        end_date: endDate,
      });
    }
  }, [endDate]);

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "ASC";
    setOrder(isAsc ? "DESC" : "ASC");
    setOrderBy(property);
  };

  useEffect(() => {
    const today = new Date();
    const defaultStartDate = new Date(
      today.getTime() - 15 * 24 * 60 * 60 * 1000
    ); // Today - 15 days
    const defaultEndDate = new Date(today.getTime() + 15 * 24 * 60 * 60 * 1000);
    if (searchData || getVCFilterDataSuccess?.data) {
      getComplianceList(
        1,
        searchData?.search
          ? searchData?.search
          : getVCFilterDataSuccess?.data?.search,
        order,
        orderBy,
        searchData?.start_date
          ? moment(searchData?.start_date).format("DD-MM-yyyy")
          : moment(defaultStartDate).format("DD-MM-yyyy"),
        searchData?.end_date
          ? moment(searchData?.end_date).format("DD-MM-yyyy")
          : moment(defaultEndDate).format("DD-MM-yyyy")
      );
    }
  }, [getVCFilterDataSuccess?.data, order, orderBy]);

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center", width: "24%" }}>
            <SearchIcon
              className={classes.searchIcondet}
              onClick={handleClickSearch}
            />
            <div className={classes.search}>
              <InputBase
                name="search"
                placeholder="Search…"
                onChange={handleSearchChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Box>
          <Box display="flex" alignItems="end">
            <Tooltip title="Filters">
              <Button onClick={handleMenu}>
                <FilterListIcon />
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={openMenu}
              onClose={handleMenuClose}
              className={classes.filterBookingSearch}
            >
              <Box>
                <div className={classes.filterBox}>
                  <div className={classes.filterBox}>
                    <Box className={classes.radioGroup}>
                      <Box mt={2} mb={1} display="flex" alignItems="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          flexGrow={1}
                        >
                          <Box className={classes.formControl}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={customEnLocale}
                              localeText={{
                                start: "Check-in",
                                end: "Check-out",
                              }}
                            >
                              <DatePicker
                                label="Start Date"
                                inputFormat="dd/MM/yyyy"
                                name="start_date"
                                onChange={(newValue) => setStartDate(newValue)}
                                value={startDate !== undefined ? startDate : ""}
                                firstDayOfWeek={1}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{
                                      width: "100%",
                                      marginRight: "10px",
                                    }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                          <Box className={classes.formControl}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              adapterLocale={customEnLocale}
                              localeText={{
                                start: "Check-in",
                                end: "Check-out",
                              }}
                            >
                              <DatePicker
                                label="End Date"
                                inputFormat="dd/MM/yyyy"
                                name="end_date"
                                onChange={(newValue) => setEndDate(newValue)}
                                value={endDate !== undefined ? endDate : ""}
                                firstDayOfWeek={1}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    style={{
                                      width: "100%",
                                      marginLeft: "10px",
                                    }}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                      </Box>
                      <Box mt={2} mb={1} display="flex" alignItems="center">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          flexGrow={1}
                        >
                          <FormControl
                            variant="outlined"
                            className={classes.formControl}
                          >
                            <InputLabel>Status </InputLabel>
                            <Select
                              value={statusFilter}
                              name="status"
                              label="Status"
                              onChange={(e) => setStatusFilter(e?.target.value)}
                              style={{ width: "175px" }}
                            >
                              <MenuItem value="PENDING">PENDING</MenuItem>
                              <MenuItem value="SUCCESS">SUCCESS</MenuItem>
                            </Select>
                          </FormControl>

                          <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <Button
                              variant="contained"
                              color="secondary"
                              style={{
                                marginRight: "16px",
                                marginLeft: "16px",
                              }}
                              className={classes.searchIcondet}
                              onClick={handleClickSearch}
                            >
                              Search
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Box>
            </Menu>
          </Box>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={getComplianceItem?.data?.data?.length}
              headCells={headCells[0]}
            />

            <TableBody>
              {getComplianceItem?.data &&
                getComplianceItem?.data?.data.map((list, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell scope="row" style={{ width: 80 }}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" style={{ width: 70 }}>
                        {list.get_user.first_name} {list.get_user.last_name}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 300 }}>
                        {list.sub_category.get_docs_type.get_category.title}
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 300 }}>
                        {list.sub_category.title}
                      </TableCell>
                      <TableCell align="left" style={{ width: 200 }}>
                        {list.file_name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: 50, color: list.expire_date_color }}
                      >
                        {list.formatted_expire_date}
                      </TableCell>
                      <TableCell align="left" style={{ width: 50 }}>
                        {list.remark}
                      </TableCell>
                      <TableCell align="left" style={{ width: 50 }}>
                        {list.document_status}
                      </TableCell>
                      <TableCell align="left" style={{ width: 50 }}>
                        {list.last_notified
                          ? `${new Date(list.last_notified).getDate()}-${
                              new Date(list.last_notified).getMonth() + 1
                            }-${new Date(list.last_notified).getFullYear()}`
                          : "-"}
                      </TableCell>
                      <TableCell align="right" style={{ width: 70 }}>
                        <Link
                          to="#"
                          className="btn btn-secondary btn-send-reminder"
                          onClick={(e) => onhandlClick(list.id)}
                        >
                          Send Reminder
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {getCompliance?.data?.data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan="6" scope="">
                    {" "}
                    Sorry no record available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={getComplianceItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
    </>
  );
};

export default ComplianceDocument;
