import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Card,
  TextField,
  Button,
  makeStyles,
  CircularProgress,
  Box,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MailIcon from "@material-ui/icons/Mail";
import LockIcon from "@material-ui/icons/Lock";
import logo from "../../../assets/images/logo.png";
import loginBanner from  "../../../assets/images/loginbanner.jpg"
import { login } from "../../../store/action";
import { useForm } from "react-hook-form";
import Notification from "../../../components/Notification/Notification";

const useStyle = makeStyles({
  loginContainer: {
    width: "100%",
    height: "100vh",
    display: "flex",
  },
  loginForm :{
    width:"40%",
    '@media (max-width: 767px)': {
      width:"100%",
    },
  },
  loginImage :{
    width:"60%",
    '@media (max-width: 767px)': {
     display:"none",
    },
  },
  loginCard: {
    width: "100%",
    maxWidth: 480,
    padding: "36px 24px 24px",
    margin:"0 auto",
    // background: "#dceeff",
    // boxShadow: "0 1px 35px rgba(11, 48, 86, 0.50)",
    '@media (max-width: 767px)': {
      width: "90%",
     },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  error: {
    marginBottom: "15px",
    paddingLeft: "7px",
    color: "red",
  },
  validationError: {
    marginTop: "-14px",
    marginBottom: "10px",
    color: "red",
  },
  success: {
    marginBottom: "15px",
    paddingLeft: "7px",
    color: "green",
  },
  textField: {
    marginBottom: 24,
    color: "#000",
    "& input": {
      paddingLeft: 12,
    },
    "& svg": {
      color: "#2b68a4",
    },
  },
  forgotCont: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 24,
  },
  forgotText: {
    color: "#2b68a4",
    fontSize: 13,
  },
  loginBtn: {
    width: 140,
    borderRadius: "4px",
    margin: "0 auto",
    background: "#ff8b46",
    "&:hover": {
      background: "#ff8b46",
    },
    textTransform: "capitalize"
  },
});

const Login = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { loading, loginErrors, userInfo } = useSelector(
    (state) => state.authReducer
  );
  const [loginNotify, setLoginNotify] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    dispatch(login(data));
    setLoginNotify(true);
    // reset();
  };
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Grid className={classes.loginContainer}>
        <Grid className={classes.loginForm} style={{ backgroundColor:'white', display:'flex',flexDirection:'column' , justifyContent:"center"}}>
         
          <div className={classes.loginCard}>
            {loginNotify && (loginErrors?.message || loginErrors) && (
              <Notification
                data={loginErrors?.message || loginErrors}
                status="error"
              />
            )}
            {loginNotify && userInfo?.message && (
              <Notification data={userInfo?.message} status="success" />
            )}
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} id="login-form">
            <img src={logo} alt="" height={160} width={150} style={{display: "table",margin: "0 auto"}} />
            <h1 style={{textAlign:"left",color:"#1c487a",fontWeight:"700",marginBottom:"5px"}}>Log in </h1>
            <p style={{textAlign:"left",color:"#1c487a",marginBottom:"40px"}}>Let's get started!</p>
              <TextField
                id="email"
                name="email"
                label="Email"
                autoComplete="off"
                // value={data.email}
                onChange={handleChange}
                type="email"
                variant="outlined"
                InputProps={{
                  startAdornment: <MailIcon />,
                }}
                required
                aria-invalid={errors.password ? "true" : "false"}
                {...register("email", {
                  required: "Please enter email",
                })}
                className={classes.textField}
              />
              {errors.email && (
                <span className={classes.validationError} role="alert">
                  {" "}
                  {errors.email.message}
                </span>
              )}
              <TextField
                id="password"
                name="password"
                label="Password"
                autoComplete="off"
                // value={data.password}
                onChange={handleChange}
                type="password"
                variant="outlined"
                InputProps={{
                  startAdornment: <LockIcon />,
                }}
                required
                aria-invalid={errors.password ? "true" : "false"}
                {...register("password", {
                  required: "Please enter password",
                  // minLength: {
                  //     value: 5,
                  //     message: "min length is 5"
                  // }
                })}
                className={classes.textField}
              />
              {errors.password && (
                <span className={classes.validationError} role="alert">
                  {" "}
                  {errors.password.message}
                </span>
              )}
            
              
             
              {/* <Button variant="contained" color="primary" className={classes.loginBtn} onClick={handleSubmit}> */}
              <Button
                variant="contained"
                className={classes.loginBtn}
                type="submit"
                formNoValidate
                style={{width:'100%', borderRadius:'20px', backgroundColor:'#1c487a', color:'white'}}
              >
                {loading ? (
                  <CircularProgress
                    style={{ width: 18, height: 18, marginRight: 12 }}
                  />
                ) : (
                  ""
                )}
                login
              </Button>
              <Link to="/forgotten-password" className={classes.forgotText}>
                <Button className={classes.forgotCont}  style={{marginTop:"20px",width:'100%', borderRadius:'20px',background: "#e8e8e8",justifyContent: "center", textTransform: "capitalize"}}>
                  Forgot password
                  </Button>
                </Link>
            </form>
          </div>
        </Grid>
        <Grid className={classes.loginImage}>
          <Box style={{width:'100%' , height:'100%'}}>
          <img src={loginBanner} alt="" style={{width:'100%' , height:'100%',  objectFit: 'cover'}} />
          </Box>
        </Grid>

        
      </Grid>
    </>
  );
};

export default Login;
