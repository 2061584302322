import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Grid,
} from "@material-ui/core";
import axios from "axios";
import Config from "../../../src/config/config";
import { useDispatch, useSelector } from "react-redux";
import { updateHoliday } from "../../store/action";
import Notification from "../../components/Notification/Notification";
import UtilService from "../../helper/service";
import { getHolidayById } from "../../store/action/holiday/holidayAction";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { customEnLocale } from "../../helper/customLocale";
import moment from "moment";

const disPastDate = UtilService.disabledPastDate();

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const UpdateHoliday = ({ openUpdate, handleClose, id }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateMsg, setUpdateMsg] = useState(false);
  const { holidaySuccess } = useSelector((state) => state.holiday);

  const [data, setData] = useState({
    organization_name: "",
    contact_person_name: "",
    contact_number: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    postcode: "",
    status: "",
    user_id: id,
  });

  const getData = async (id) => {
    if (id > 0) {
      try {
        dispatch(
          getHolidayById(id, (result) => {
            if (result?.status === true) {
              setData(result?.data);
            }
          })
        );
      } catch (error) {
        console.error("error.message", error.message);
      }
    }
  };
  useEffect(() => {
    setData("");
    getData(id);
  }, [id]);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const submitOrganization = () => {
    let payload = {
      ...data,
      holiday_date:data?.holiday_date ? moment(data?.holiday_date).format("YYYY-MM-DD"):''
    }
    dispatch(updateHoliday(payload));
    setUpdateMsg(true);
  };
 const dateHandler = (name,date)=>{
    setData({ ...data, [name]: date });
  }
  useEffect(() => {
    if (updateMsg && holidaySuccess?.message) {
      handleClose("update");
    }
  }, [handleClose, updateMsg, holidaySuccess]);

  return (
    <>
      {updateMsg && holidaySuccess?.message && (
        <Notification data={holidaySuccess?.message} status="success" />
      )}
      <Dialog
        open={openUpdate}
        onClose={(e) => handleClose("cancel")}
        classes={{ paper: classes.dialogWidth }}
      >
        <form>
          <DialogTitle id="form-dialog-title">
            <div>Update Holiday</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={customEnLocale}>
                         <DatePicker
                          autoFocus
                          margin="dense"
                          id="holiday_date"
                          type="date"
                          label="Holiday date"
                          variant="outlined"
                          name="holiday_date"
                          value={data.holiday_date || ""}
                          onChange={(e) =>dateHandler("holiday_date",e)}
                          InputLabelProps={{
                              shrink: true,
                          }}
                          style={{ width: "100%" }}
                          renderInput={props => <TextField  
                            fullWidth
                            required 
                            variant="outlined"  
                            inputProps={{
                            min: disPastDate,
                            }}
                            {...props}  
                            />}
                          inputFormat="dd-MMM-yyyy"                          
                          fullWidth
                          firstDayOfWeek={1} 
                        />
                   </LocalizationProvider>
              {/* <TextField
                autoFocus
                margin="dense"
                id="holiday_date"
                type="date"
                label="Holiday date"
                variant="outlined"
                name="holiday_date"
                fullWidth
                required
                value={data.holiday_date || ""}
                // helperText={holidayErrors?.message?.organization_name}
                // error={
                //     !!holidayErrors?.message?.organization_name
                // }
                inputProps={{
                  min: disPastDate,
                }}
                onChange={handleChange}
              /> */}
            </div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="holiday_title"
                  type="text"
                  label="Holiday title"
                  variant="outlined"
                  name="holiday_title"
                  fullWidth
                  required
                  value={data.holiday_title || ""}
                  // helperText={holidayErrors?.message?.organization_name}
                  // error={
                  //     !!holidayErrors?.message?.organization_name
                  // }
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={(e) => handleClose("cancel")} color="primary">
              Cancel
            </Button>
            <Button
              onClick={submitOrganization}
              color="secondary"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpdateHoliday;
