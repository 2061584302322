import * as actionTypes from "../../action/actiontypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  getInvoiceItem: [],
  getInvoiceError: [],
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_REQUEST:
      return updateObject(state, {
        loading: true,
          getInvoiceItem: '',
          getInvoiceError: '',
      });

    case actionTypes.GET_INVOICE_SUCCESS:
      return updateObject(state, {
        loading: false,
        getInvoiceItem: action.payload,
      });

    case actionTypes.GET_INVOICE_ERROR:
      return updateObject(state, {
        loading: false,
        getInvoiceError: action.payload,
      });

  
    default:
      return state;
  }
};

export default invoiceReducer;
