import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import Config from "../../../config/config";
import history from "../../../utils/HistoryUtils";
import * as actions from "../index";

import {
  GET_SIGNEE_ERROR,
  GET_SIGNEE_REQUETS,
  GET_SIGNEE_SUCCESS,
  GET_COMPLIANCE_ERROR,
  GET_COMPLIANCE_REQUETS,
  GET_COMPLIANCE_SUCCESS,
  GET_VC_FILTER_DATA_REQUEST,
  GET_VC_FILTER_DATA_SUCCESS,
  CREATE_SIGNEE_ERROR,
  CREATE_SIGNEE_REQUETS,
  CREATE_SIGNEE_SUCCESS,
  GET_CANDIDATE_REFERRED_FROM_SUCCESS,
  UPDATE_SIGNEE_SUCCESS,
  UPDATE_SIGNEE_REQUETS,
  UPDATE_SIGNEE_ERROR,
  GET_SINGLE_SIGNEE_REQUETS,
  GET_SINGLE_SIGNEE_SUCCESS,
  GET_SINGLE_SIGNEE_ERROR,
  DELETE_SIGNEE_REQUETS,
  DELETE_SIGNEE_SUCCESS,
  DELETE_SIGNEE_ERROR,
  DOWNLOAD_CHECKLIST_REQUETS,
  DOWNLOAD_CHECKLIST_SUCCESS,
  DOWNLOAD_CHECKLIST_ERROR,
  CHANGE_SIGNEE_PRO_STATUS_REQUEST,
  CHANGE_SIGNEE_PRO_STATUS_SUCCESS,
  CHANGE_SIGNEE_PRO_STATUS_ERROR,
  ENABLED_DISABLED_CANDIDATE_SUCCESS,
  ENABLED_DISABLED_CANDIDATE_REQUEST,
  ENABLED_DISABLED_CANDIDATE_ERROR,
  CHANGE_SIGNEE_COMP_STATUS_REQUEST,
  CHANGE_SIGNEE_COMP_STATUS_SUCCESS,
  CHANGE_SIGNEE_COMP_STATUS_ERROR,
  CHANGE_DOC_STATUS_REQUEST,
  CHANGE_DOC_STATUS_SUCCESS,
  CHANGE_DOC_STATUS_ERROR,
  GET_CONTACT_EVENT_REQUETS,
  GET_CONTACT_EVENT_SUCCESS,
  GET_CONTACT_EVENT_ERROR,
  ADD_CONTACT_EVENT_REQUETS,
  ADD_CONTACT_EVENT_SUCCESS,
  ADD_CONTACT_EVENT_ERROR,
  ADD_DOC_EXPIRE_DATE_REQUETS,ADD_DOC_EXPIRE_DATE_SUCCESS,ADD_DOC_EXPIRE_DATE_ERROR,
  DELETE_DOCUMENT_REQUETS, DELETE_DOCUMENT_SUCCESS, DELETE_DOCUMENT_ERROR, ADD_SIGNEE_REMARK_REQUETS, ADD_SIGNEE_REMARK_SUCCESS, ADD_SIGNEE_REMARK_ERROR
} from "../actiontypes";

export const getSignee = ({
  pageNo = 1,
  search = "",
  new_status = "",
  specialty = "",
  postcode = "",
  consultants = "",
  type="",
  column_name="",
  proximity = "",
  latitude = "",
  longitude = "",
}) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getSigneeRequest());
    await axios
      .get(
        `${Config.API_URL}api/organization/get-signee?search=${search}&new_status=${new_status}&page=${pageNo}&speciality=${specialty}&postcode=${postcode}&consultantsId=${consultants}&type=${type}&column_name=${column_name}&proximity=${proximity}&latitude=${latitude}&longitude=${longitude}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getSigneeSuccess(dataItem));
        } else {
          dispatch(getSigneeSuccess(""));
          dispatch(getSigneeError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getSigneeError(error));
      });
  };
};

export const getCompliance = ({
  pageNo = 1,
  search = "",
  type="",
  column_name="",
  start_date="",
  end_date="",
  status=""
}) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getComplianceRequest());
    await axios
      .get(
        `${Config.API_URL}api/organization/get-compliance?search=${search}&page=${pageNo}&type=${type}&column_name=${column_name}&start_date=${start_date}&end_date=${end_date}&status=${status}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getComplianceSuccess(dataItem));
        } else {
          dispatch(getComplianceSuccess(""));
          dispatch(getComplianceError(dataItem));
        }
      })
      .catch((error) => {
        dispatch(getComplianceError(error));
      });
  };
};

const getSigneeRequest = () => {
  return {
    type: GET_SIGNEE_REQUETS,
  };
};

const getSigneeSuccess = (data) => {
  return {
    type: GET_SIGNEE_SUCCESS,
    payload: data,
  };
};

const getSigneeError = (error) => {
  return {
    type: GET_SIGNEE_ERROR,
    payload: error,
  };
};

const getComplianceRequest = () => {
  return {
    type: GET_COMPLIANCE_REQUETS,
  };
};

const getComplianceSuccess = (data) => {
  return {
    type: GET_COMPLIANCE_SUCCESS,
    payload: data,
  };
};

const getComplianceError = (error) => {
  return {
    type: GET_COMPLIANCE_ERROR,
    payload: error,
  };
};

// ----------------------------------------------

export const createSignee = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createSigneeRequest());
    await axios
      .post(`${Config.API_URL}api/organization/add-signee`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(createSigneeSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          // dispatch(createSigneeSuccess(""))
          dispatch(createSigneeFailure(data));
        }
      })
      .catch((error) => {
        dispatch(createSigneeFailure(error));
      });
  };
};

const createSigneeRequest = () => {
  return {
    type: CREATE_SIGNEE_REQUETS,
  };
};

const createSigneeSuccess = (data) => {
  return {
    type: CREATE_SIGNEE_SUCCESS,
    payload: data,
  };
};

const createSigneeFailure = (error) => {
  return {
    type: CREATE_SIGNEE_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const updateSignee = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateSigneeRequest());
    await axios
      .put(`${Config.API_URL}api/organization/edit-signee`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(updateSigneeSuccess(data));
          setTimeout(() => {
            history.goBack();
          }, 2000);
        } else {
          dispatch(updateSigneeSuccess(""));
          dispatch(updateSigneeFailure(data));
        }
      })
      .catch((error) => {
        dispatch(updateSigneeFailure(error));
      });
  };
};

const updateSigneeRequest = () => {
  return {
    type: UPDATE_SIGNEE_REQUETS,
  };
};

const updateSigneeSuccess = (data) => {
  return {
    type: UPDATE_SIGNEE_SUCCESS,
    payload: data,
  };
};

const updateSigneeFailure = (error) => {
  return {
    type: UPDATE_SIGNEE_ERROR,
    payload: error,
  };
};

// ---------------------------------------

// -----------------------------------
export const getSingleSignee = (id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getSingleSigneeRequest());
    await axios
      .get(`${Config.API_URL}api/organization/get-my-signee/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(getSingleSigneeSuccess(data));
        } else {
          dispatch(getSingleSigneeSuccess(""));
          dispatch(getSingleSigneeFailure(data));
        }
      })
      .catch((error) => {
        dispatch(getSingleSigneeFailure(error));
      });
  };
};

const getSingleSigneeRequest = () => {
  return {
    type: GET_SINGLE_SIGNEE_REQUETS,
  };
};

const getSingleSigneeSuccess = (data) => {
  return {
    type: GET_SINGLE_SIGNEE_SUCCESS,
    payload: data,
  };
};

const getSingleSigneeFailure = (error) => {
  return {
    type: GET_SINGLE_SIGNEE_ERROR,
    payload: error,
  };
};

// ---------------------------------------
export const getContactEventForSignee = (pageNo, search, id,column_name="created_at",type="desc") => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getContactEventRequest());
    await axios
      .get(
        `${Config.API_URL}api/organization/contact-event/${id}?search=${search}&column_name=${column_name}&type=${type}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + loggedInUser,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(getContactEventSuccess(data));
        } else {
          dispatch(getContactEventSuccess(""));
          dispatch(getContactEventFailure(data));
        }
      })
      .catch((error) => {
        dispatch(getContactEventFailure(error));
      });
  };
};

const getContactEventRequest = () => {
  return {
    type: GET_CONTACT_EVENT_REQUETS,
  };
};

const getContactEventSuccess = (data) => {
  return {
    type: GET_CONTACT_EVENT_SUCCESS,
    payload: data,
  };
};

const getContactEventFailure = (error) => {
  return {
    type: GET_CONTACT_EVENT_ERROR,
    payload: error,
  };
};

// ---------------------------------------

export const addContactEventForSignee = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(addContactEventRequest());
    await axios
      .post(`${Config.API_URL}api/organization/contact-event`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const output = response.data;
        if (output && output.status === true) {
          dispatch(addContactEventSuccess(output));
          dispatch(actions.getContactEventForSignee(1, "", data.signee_id));
        } else {
          dispatch(addContactEventSuccess(""));
          dispatch(addContactEventFailure(output));
        }
      })
      .catch((error) => {
        dispatch(addContactEventFailure(error));
      });
  };
};
export const editContactEventForSignee = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    // dispatch(addContactEventRequest());
    // dispatch(editcon)
    await axios
      .post(`${Config.API_URL}api/organization/update-event`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const output = response.data;
        if (output && output.status === true) {
          dispatch(addContactEventSuccess(output));
          dispatch(actions.getContactEventForSignee(1, "", data.signee_id));
        } else {
          dispatch(addContactEventSuccess(""));
          dispatch(addContactEventFailure(output));
        }
      })
      .catch((error) => {
        dispatch(addContactEventFailure(error));
      });
  };
};

const addContactEventRequest = () => {
  return {
    type: ADD_CONTACT_EVENT_REQUETS,
  };
};

const addContactEventSuccess = (data) => {
  return {
    type: ADD_CONTACT_EVENT_SUCCESS,
    payload: data,
  };
};

const addContactEventFailure = (error) => {
  return {
    type: ADD_CONTACT_EVENT_ERROR,
    payload: error,
  };
};

//----------------------------------------

export const addContactRemarkForSignee = (data,setRemarkGeneral) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  let url=data.document_subkey ? `${Config.API_URL}api/organization/doc-remark` : `${Config.API_URL}api/organization/set-signee-remark`
  return async (dispatch) => {
    dispatch(addContactRemarkRequest());
    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const output = response.data;
        if (output && output.status === true) {
          setRemarkGeneral(data.remark)
        
          dispatch(addContactRemarkSuccess(output));
          
        } else {
          dispatch(addContactRemarkSuccess(""));
          dispatch(addContactRemarkFailure(output));
        }
      })
      .catch((error) => {
        dispatch(addContactRemarkFailure(error));
      });
  };
};

const addContactRemarkRequest = () => {
  return {
    type: ADD_SIGNEE_REMARK_REQUETS,
  };
};

const addContactRemarkSuccess = (data) => {
  return {
    type: ADD_SIGNEE_REMARK_SUCCESS,
    payload: data,
  };
};

const addContactRemarkFailure = (error) => {
  return {
    type: ADD_SIGNEE_REMARK_ERROR,
    payload: error,
  };
};
//------------------------------------------
export const addDocumentRemarkForSignee = (data,setRemarkDocument) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  let url=data.document_subkey ? `${Config.API_URL}api/organization/doc-remark` : `${Config.API_URL}api/organization/set-signee-remark`
  return async (dispatch) => {
    dispatch(addRemarkDocumentRequest());
    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const output = response.data;
        if (output && output.status === true) {
          setRemarkDocument(data.remark)
        
          dispatch(addRemarkDocumentSuccess(output));
          window.location.reload()
          
        } else {
          dispatch(addRemarkDocumentSuccess(""));
          dispatch(addContactRemarkFailure(output));
        }
      })
      .catch((error) => {
        dispatch(addRemarkDocumentFailure(error));
      });
  };
};

const addRemarkDocumentRequest = () => {
  return {
    type: ADD_SIGNEE_REMARK_REQUETS,
  };
};

const addRemarkDocumentSuccess = (data) => {
  return {
    type: ADD_SIGNEE_REMARK_SUCCESS,
    payload: data,
  };
};

const addRemarkDocumentFailure = (error) => {
  return {
    type: ADD_SIGNEE_REMARK_ERROR,
    payload: error,
  };
};
//------------------------------------------

//------------------------------------------
export const addDocumentExpireDate = (data,setExpiryDate) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  let url= `${Config.API_URL}api/organization/doc-expiredate`;
  return async (dispatch) => {
    dispatch(addExpireDateRequest());
    await axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const output = response.data;
        if (output && output.status === true) {
          dispatch(addExpireDateSuccess(output));
          dispatch(getSingleSignee(data.signee_id))
        } else {
          dispatch(addExpireDateSuccess(""));
          dispatch(addExpireDateFailure(output));
        }
      })
      .catch((error) => {
        dispatch(addExpireDateFailure(error));
      });
  };
};

const addExpireDateRequest = () => {
  return {
    type: ADD_DOC_EXPIRE_DATE_REQUETS,
  };
};

const addExpireDateSuccess = (data) => {
  return {
    type: ADD_DOC_EXPIRE_DATE_SUCCESS,
    payload: data,
  };
};

const addExpireDateFailure = (error) => {
  return {
    type: ADD_DOC_EXPIRE_DATE_ERROR,
    payload: error,
  };
};
//------------------------------------------

export const getCandidateReferredFrom = () => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    // dispatch(getSigneeRequest())
    await axios
      .get(`${Config.API_URL}api/signee/candidate-referred-from`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          dispatch(getCandidateReferredFromSuccess(dataItem));
        } else {
          dispatch(getCandidateReferredFromSuccess(""));
        }
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };
};

const getCandidateReferredFromSuccess = (data) => {
  return {
    type: GET_CANDIDATE_REFERRED_FROM_SUCCESS,
    payload: data,
  };
};

// -------------------------------------

export const deleteSignee = (role_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteSigneeRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-signee/${role_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(deleteSigneeSuccess(data));
          setTimeout(() => {
            history.go(-2);
          }, 2000);
        } else {
          dispatch(deleteSigneeError(data));
        }
      })
      .catch((error) => {
        dispatch(deleteSigneeError(error));
      });
  };
};

export const deleteSigneeRequest = () => {
  return {
    type: DELETE_SIGNEE_REQUETS,
  };
};
export const deleteSigneeSuccess = (data) => {
  return {
    type: DELETE_SIGNEE_SUCCESS,
    payload: data,
  };
};
export const deleteSigneeError = (error) => {
  return {
    type: DELETE_SIGNEE_ERROR,
    payload: error,
  };
};

export const downloadChecklistPdf = (role_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(downloadChecklistRequest());
    try {
      const response = await axios.get(`${Config.API_URL}api/organization/download-checklist/${role_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
        responseType: 'blob' // Set responseType to 'blob' to handle binary data (PDF file)
      });

      // Create a blob URL for the downloaded PDF
      const url = window.URL.createObjectURL(new Blob([response.data]));
      
      // Create a link element to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Checklist.pdf');
      
      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();
      
      // Clean up by revoking the object URL
      window.URL.revokeObjectURL(url);

      // Dispatch success action if needed
      dispatch(downloadChecklistSuccess());
    } catch (error) {
      // Dispatch error action if an error occurs
      dispatch(downloadChecklistError(error));
    } 
  };
};

export const enabledDisabledLogin = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(enabledDisabledCandidateRequest());
    await axios
      .post(`${Config.API_URL}api/organization/enabled-disabled-candidate`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data1 = response.data;
        if (data1 && data1.status === true) {
          dispatch(getSingleSignee(data.id));
          dispatch(enabledDisabledCandidateSuccess(data));
        } else {
          // dispatch(createSigneeSuccess(""))
          dispatch(enabledDisabledCandidateFailure(data));
        }
      })
      .catch((error) => {
        dispatch(enabledDisabledCandidateFailure(error));
      });
  };
};

export const downloadChecklistRequest = () => {
  return {
    type: DOWNLOAD_CHECKLIST_REQUETS,
  };
};
export const downloadChecklistSuccess = (data) => {
  return {
    type: DOWNLOAD_CHECKLIST_SUCCESS,
    payload: data,
  };
};
export const downloadChecklistError = (error) => {
  return {
    type: DOWNLOAD_CHECKLIST_ERROR,
    payload: error,
  };
};

// -------------------------------------

export const signeeProStatus = (data) => {
  return async (dispatch) => {
    dispatch(signeeProStatusRequest());
    await apiClient(true)
      .put(`api/organization/user/change-signee-profile-status`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(signeeProStatusSuccess(dataItem));
      })
      .catch((error) => {
        dispatch(signeeProStatusFailure(error));
      });
  };
};

const signeeProStatusRequest = () => {
  return {
    type: CHANGE_SIGNEE_PRO_STATUS_REQUEST,
  };
};

const enabledDisabledCandidateRequest = () => {
  return {
    type: ENABLED_DISABLED_CANDIDATE_REQUEST,
  };
};

const signeeProStatusSuccess = (data) => {
  return {
    type: CHANGE_SIGNEE_PRO_STATUS_SUCCESS,
    payload: data,
  };
};

const enabledDisabledCandidateSuccess = (data) => {
  return {
    type: ENABLED_DISABLED_CANDIDATE_SUCCESS,
    payload: data,
  };
};

const enabledDisabledCandidateFailure = (error) => {
  return {
    type: ENABLED_DISABLED_CANDIDATE_ERROR,
    payload: error,
  };
};


const signeeProStatusFailure = (error) => {
  return {
    type: CHANGE_SIGNEE_PRO_STATUS_ERROR,
    payload: error,
  };
};

// -------------------------------------

export const signeeCompStatus = (data) => {
  return async (dispatch) => {
    dispatch(signeeCompStatusRequest());
    await apiClient(true)
      .post(`api/organization/change-signee-compliance-status`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(signeeCompStatusSuccess(dataItem));
      })
      .catch((error) => {
        dispatch(signeeCompStatusFailure(error));
      });
  };
};

const signeeCompStatusRequest = () => {
  return {
    type: CHANGE_SIGNEE_COMP_STATUS_REQUEST,
  };
};

const signeeCompStatusSuccess = (data) => {
  return {
    type: CHANGE_SIGNEE_COMP_STATUS_SUCCESS,
    payload: data,
  };
};

const signeeCompStatusFailure = (error) => {
  return {
    type: CHANGE_SIGNEE_COMP_STATUS_ERROR,
    payload: error,
  };
};

// -------------------------------------

export const changeDocStatus = (data) => {
  return async (dispatch) => {
    dispatch(changeDocStatusRequest());
    await apiClient(true)
      .post(`api/organization/user/change-document-status`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(changeDocStatusSuccess(dataItem));
      })
      .catch((error) => {
        dispatch(changeDocStatusFailure(error));
      });
  };
};

const changeDocStatusRequest = () => {
  return {
    type: CHANGE_DOC_STATUS_REQUEST,
  };
};

const changeDocStatusSuccess = (data) => {
  return {
    type: CHANGE_DOC_STATUS_SUCCESS,
    payload: data,
  };
};

const changeDocStatusFailure = (error) => {
  return {
    type: CHANGE_DOC_STATUS_ERROR,
    payload: error,
  };
};



// -------------------------------------

export const deleteSigneeDoc = (docId) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteDocumentRequest());
    await axios
      .delete(`${Config.API_URL}api/organization/delete-document/${docId}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(deleteDocumentSuccess(data));
          // setTimeout(() => {
          //   dispatch(getSingleSignee(docId));
          // }, 1000);
        } else {
          dispatch(deleteDocumentError(data));
        }
      })
      .catch((error) => {
        dispatch(deleteDocumentError(error));
      });
  };
};

export const deleteDocumentRequest = () => {
  return {
    type: DELETE_DOCUMENT_REQUETS,
  };
};
export const deleteDocumentSuccess = (data) => {
  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: data,
  };
};
export const deleteDocumentError = (error) => {
  return {
    type: DELETE_DOCUMENT_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const getFilterData = (moduleName) => {
  const getToken = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getVCFilterDataRequest());
    await axios
      .get(`${Config.API_URL}api/organization/get-filter-by-module?module_name=${moduleName}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + getToken,
        },
      })
      .then((response) => {
        const data = response.data;
      
        if(moduleName === 'candidates')
        {
          dispatch(getVCFilterDataSuccess(data));
        }
      })
      .catch((error) => {
      });
  };
};

const getVCFilterDataRequest = () => {
  return {
    type: GET_VC_FILTER_DATA_REQUEST,
  };
};

const getVCFilterDataSuccess = (data) => {
  return {
    type: GET_VC_FILTER_DATA_SUCCESS,
    payload: data,
  };
};
//-------------------------------