import React, { useEffect, useState } from "react";
import {
  Paper,
  makeStyles,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  TextField,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Tooltip,
  Menu,
  TableContainer,
} from "@material-ui/core";
import axios from "axios";
import apiConfigs from "../../config/config";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import FilterListIcon from "@material-ui/icons/FilterList";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { useDispatch, useSelector } from "react-redux";
import history from "../../utils/HistoryUtils";
import Notification from "../../components/Notification/Notification";
import { getReport } from "../../store/action";
import {
  getAllCandidateList,
  getAllCustomerList,
  getAllHospitalsList,
  getSpecialitiesList,
  getStaffDetailList,
  getUmbrellDetailList,
  getWardByHospitalList,
} from "../../store/action/dropdown/dropdownAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { customEnLocale } from "../../helper/customLocale";
const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
  },
  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },
  formControl: {
    width: "24%",
    marginTop: "10px",
  },
  table: {
    minWidth: 700,
  },
  searchIcondet: {
    marginRight: "14px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "800px",
      width: "100%",
      left: "unset !important",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },

  statusButton: {
    display: "flex",
    alignItems: "center",
    background: " #184a7b",
    borderRadius: 4,
    overflow: "hidden",
    marginBottom: 16,
    "& > .btn": {
      margin: theme.spacing(0),
      width: 140,
      background: "transparent",
      color: "#8dbef0",
      height: "47px",
      boxShadow: "none",
      borderRadius: 0,
      "&.active": {
        background: "#ff8b46",
        color: "#fff",
      },
      "&:hover": {
        background: "#f69d68",
        color: "#fff",
      },
    },
  },
}));

const ViewReports = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const tabList = ["COMPLETED"];
  const [page, setPage] = React.useState(1);
  const [searchData, setSearchData] = useState({ search: "" });
  const staffDetail = JSON.parse(localStorage.getItem("staffDetail"));
  const [activeIndex, setActiveIndex] = useState(0);
  const [bookingNotify, setBookingNotify] = useState(false);
  const [umbrella, setUmbrella] = useState([]);
  const [speciality, setSpeciality] = useState([]);
  const [hospitalList, setHospitalList] = useState();
  const [getTrustId, setTrustId] = useState();
  const [getHospitalId, setHospitalId] = useState();
  const [wardList, setWardList] = useState([]);
  const [staff, setStaff] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selectedReport, setSelectedReport] = useState(0);

  const { bookingItem, loading, shiftStatusSuccess } = useSelector(
    (state) => state.booking
  );

  const [trust, setTrust] = useState([]);
  const today = new Date();
  const defaultStartDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

  const [data, setData] = useState({
    specialty: "",
    customer: "",
    site: "",
    ward: "",
    consultants: "",
    candidate: "",
    start_date: moment(defaultStartDate).format("yyyy-MM-DD"),
    end_date: moment(today).format("yyyy-MM-DD"),
    framework: "",
    trust_id: "",
    umbrella_id: "",
    type: "asc",
    column_name: "date",
    report_type: "",
  });

  const getBookingList = (pageNo = 1, type = order, column_name = orderBy) => {
    console.log("datazzzzz", data);
    dispatch(getReport({ pageNo, data, type, column_name }));
  };

  const getBookingDownload = (
    pageNo = 1,
    type = order,
    column_name = orderBy
  ) => {
    dispatch(getReport({ pageNo, data, type, column_name }));
  };

  const onhandlClick = (id) => {
    history.push(`${match.url}/${id}/detail`);
  };

  const handleChangePage = (pageNo, value) => {
    let status = tabList[activeIndex];
    setPage(value);
    setTimeout(getBookingList(value, searchData.search), 2000);
  };

  const reportHandleChange = (event, value) => {
    setSelectedReport(event.target.value);

    setData({ ...data, report_type: event.target.value });
    tempReportHandle(event.target.value);
  };

  const tempReportHandle = (reportValue) => {
    let data1 = {
      specialty: "",
      customer: "",
      site: "",
      ward: "",
      consultants: "",
      candidate: "",
      start_date: data.start_date
        ? moment(data.start_date).format("yyyy-MM-DD")
        : moment(defaultStartDate).format("yyyy-MM-DD"),
      end_date: data.end_date
        ? moment(data.end_date).format("yyyy-MM-DD")
        : moment(today).format("yyyy-MM-DD"),
      trust_id: "",
      framework: "",
      umbrella_id: "",
      report_type: reportValue,
    };

    dispatch(getReport({ pageNo: 1, data: data1, order, orderBy }));
  };
  const getTrust = async () => {
    try {
      dispatch(
        getAllCustomerList((result) => {
          setTrust(result);
        })
      );
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const handleClickSearch = (event, value) => {
    setTimeout(getBookingList(page, searchData.search), 1000);
    handleMenuClose(true);
  };

  const handleClickDownload = async (event, value) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    await axios
      .get(
        `${
          apiConfigs.API_URL
        }api/organization/download-report-completed-booking?speciality=${
          data.specialty
        }&customer=${data.customer}&site=${data.site}&ward=${
          data.ward
        }&consultant=${data.consultants}&candidate=${
          data.candidate
        }&start_date=${moment(data.start_date).format(
          "YYYY-MM-DD"
        )}&end_date=${moment(data.end_date).format("YYYY-MM-DD")}&trust_id=${
          data.trust_id
        }&umbrella_id=${
          data.umbrella_id
        }&act=export_csv&report_type=${selectedReport}`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const url = response.data.data;
        var link = document.createElement("a");
        link.download = "Candidate.csv";
        link.href = url;
        link.target = "_blank";
        link.click();
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const getUmbrella = async () => {
    dispatch(
      getUmbrellDetailList((result) => {
        if (result?.data) {
          setUmbrella(result);
        }
      })
    );
  };
  const handleReset = () => {
    let type = "asc";
    let column_name = "date";
    let data = {
      specialty: "",
      customer: "",
      site: "",
      ward: "",
      consultants: "",
      candidate: "",
      start_date: moment(defaultStartDate).format("yyyy-MM-DD"),
      end_date: moment(today).format("yyyy-MM-DD"),
      trust_id: "",
      framework: "",
      umbrella_id: "",
    };
    setData(data);
    handleMenuClose(true);
    dispatch(getReport({ pageNo: 1, data, type, column_name }));
  };

  const getSpecialities = async () => {
    dispatch(
      getSpecialitiesList((result) => {
        if (result?.status === true) {
          setSpeciality(result);
        }
      })
    );
  };

  const gethospital = async () => {
    if (getTrustId) {
      let ID = moment(getTrustId).format("YYYY-MM-DD");
      dispatch(
        getAllHospitalsList(ID, (result) => {
          if (result?.status === true) {
            setHospitalList(result);
          }
        })
      );
    }
  };

  const getStaff = async () => {
    dispatch(
      getStaffDetailList((result) => {
        if (result?.data) {
          setStaff(result);
        }
      })
    );
  };

  const getCandidates = async () => {
    dispatch(
      getAllCandidateList((result) => {
        if (result?.data) {
          setCandidates(result);
        }
      })
    );
  };
  useEffect(() => {
    if (getTrustId) {
      gethospital();
    }
  }, [getTrustId]);

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    getBookingList();
    getTrust();
    getUmbrella();
    getSpecialities();
    getStaff();
    getCandidates();
  }, [orderBy, order]);

  const trustHandleChange = (event) => {
    data.site = "";
    data.ward = "";
    setTrustId(event.target.value);
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const dateHandler = (name, date) => {
    setData({ ...data, [name]: date });
    data.date = date;
    setTrustId(date);
  };
  const getWardType = async (hospitalId) => {
    dispatch(
      getWardByHospitalList(hospitalId, getTrustId, (result) => {
        if (result?.status === true) {
          setWardList(result);
        }
      })
    );
  };
  const hospitalHandleChange = (event) => {
    data.ward = "";
    setHospitalId(event.target.value);
    setData({ ...data, [event.target.name]: event.target.value });
    getWardType(event.target.value);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      {bookingNotify && shiftStatusSuccess?.message && (
        <Notification data={shiftStatusSuccess?.message} status="success" />
      )}

      <Paper className={`${classes.root} mb-6`}>
        <Box className="mb-5" display="flex" justifyContent="end">
          <Box sx={{ display: "flex", alignItems: "end" }}>
            <Tooltip title="Filters">
              <Button onClick={handleMenu}>
                <FilterListIcon />
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              open={openMenu}
              onClose={handleMenuClose}
              className={classes.filterBookingSearch}
            >
              <div className={classes.filterBox}>
                <Box className={classes.radioGroup}>
                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                      flexWrap="wrap"
                    >
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Specialty</InputLabel>
                        <Select
                          // value={data.trust_id}
                          label="Specialty"
                          name="specialty"
                          onChange={trustHandleChange}
                          value={data?.specialty}
                        >
                          <MenuItem value="">Select Specialty</MenuItem>
                          {speciality?.data != undefined &&
                            speciality?.data &&
                            speciality?.data.map((items, index) => {
                              return (
                                <MenuItem value={items.id} key={index}>
                                  {items.speciality_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Customer Name</InputLabel>
                        <Select
                          value={data.trust_id}
                          label="Customer Name"
                          name="trust_id"
                          onChange={trustHandleChange}
                        >
                          <MenuItem value="">Select Customer</MenuItem>
                          {trust?.data &&
                            trust?.data?.map((trustList, index) => {
                              return (
                                <MenuItem value={trustList.id} key={index}>
                                  {trustList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Site</InputLabel>
                        <Select
                          label="Site"
                          name="site"
                          onChange={hospitalHandleChange}
                          value={data?.site}
                        >
                          <MenuItem value="">Select Site</MenuItem>
                          {hospitalList?.data != undefined &&
                            hospitalList?.data &&
                            hospitalList?.data.map((List, index) => {
                              return (
                                <MenuItem value={List.id} key={index}>
                                  {List.hospital_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Ward</InputLabel>
                        <Select
                          label="Ward"
                          name="ward"
                          onChange={(e) =>
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={data?.ward}
                        >
                          <MenuItem value="">Select Ward</MenuItem>
                          {wardList?.data != undefined &&
                            wardList?.data &&
                            wardList?.data.map((list, index) => {
                              return (
                                <MenuItem value={list.id} key={index}>
                                  {list.ward_name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Consultants</InputLabel>
                        <Select
                          label="Consultants"
                          name="consultants"
                          onChange={(e) =>
                            setData({
                              ...data,
                              [e.target.name]: e.target.value,
                            })
                          }
                          value={data?.consultants}
                        >
                          <MenuItem value="">Select Consultants</MenuItem>
                          {staff?.data != undefined &&
                            staff?.data &&
                            staff?.data.map((trustList, index) => {
                              return (
                                <MenuItem value={trustList.id} key={index}>
                                  {trustList.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <Box className={classes.formControl}>
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={customEnLocale}
                        >
                          <DatePicker
                            id="date"
                            label="Start Date"
                            type="date"
                            name="start_date"
                            value={data.start_date}
                            variant="outlined"
                            onChange={(e) => dateHandler("start_date", e)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "100%" }}
                            renderInput={(props) => (
                              <TextField variant="outlined" {...props} />
                            )}
                            inputFormat="dd-MMM-yyyy"
                            fullWidth
                            firstDayOfWeek={1}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box className={classes.formControl}>
                        {/* <TextField
                          id="date"
                          value={data.end_date}
                          label="End Date"
                          type="date"
                          name="end_date"
                          variant="outlined"
                          onChange={trustHandleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                        /> */}
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={customEnLocale}
                        >
                          <DatePicker
                            id="date"
                            value={data.end_date}
                            label="End Date"
                            type="date"
                            name="end_date"
                            variant="outlined"
                            onChange={(e) => dateHandler("end_date", e)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            style={{ width: "100%" }}
                            renderInput={(props) => (
                              <TextField variant="outlined" {...props} />
                            )}
                            inputFormat="dd-MMM-yyyy"
                            fullWidth
                            firstDayOfWeek={1}
                          />
                        </LocalizationProvider>
                      </Box>
                      <FormControl
                        className={classes.formControl}
                        variant="outlined"
                      >
                        <InputLabel>Candidate</InputLabel>
                        <Select
                          label="Candidate"
                          name="candidate"
                          onChange={trustHandleChange}
                          value={data?.candidate}
                        >
                          <MenuItem value="">Select Candidate</MenuItem>
                          {candidates?.data != undefined &&
                            candidates?.data &&
                            candidates?.data.map((candidateData, index) => {
                              return (
                                <MenuItem value={candidateData.id} key={index}>
                                  {candidateData.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      flexGrow={1}
                    >
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel>Umbrella Name</InputLabel>
                        <Select
                          value={data?.umbrella_id}
                          label="Umbrella Name"
                          name="umbrella_id"
                          onChange={trustHandleChange}
                        >
                          <MenuItem value="">Select Umbrella</MenuItem>
                          {umbrella?.data &&
                            umbrella?.data.map((trustList, index) => {
                              return (
                                <MenuItem value={trustList.id} key={index}>
                                  {trustList.name_umbrella}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      <Box className={classes.formControl}>
                        <TextField
                          value={data.framework}
                          label="Framework"
                          type="text"
                          name="framework"
                          variant="outlined"
                          onChange={trustHandleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          style={{ width: "100%" }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                        }}
                        className={classes.formControl}
                      >
                        <Button
                          variant="contained"
                          color="secondary"
                          className={classes.searchIcondet}
                          onClick={handleClickSearch}
                        >
                          Search
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleReset}
                        >
                          Reset
                        </Button>
                      </Box>
                      <Box className={classes.formControl}></Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Menu>
            <div className="ml-5">
              <Select
                label="select-report"
                name="select-report"
                onChange={reportHandleChange}
                value={selectedReport}
                style={{ width: "180px" }}
              >
                <MenuItem value="0">Select Report Type</MenuItem>
                return (
                <MenuItem value="1" key="1">
                  General
                </MenuItem>
                <MenuItem value="2" key="2">
                  Timesheets
                </MenuItem>
                <MenuItem value="3" key="3">
                  Framework
                </MenuItem>
                <MenuItem value="4" key="4">
                  Factoring
                </MenuItem>
                <MenuItem value="5" key="5">
                  MI
                </MenuItem>
                <MenuItem value="6" key="6">
                  HTE-SIS
                </MenuItem>
                <MenuItem value="7" key="7">
                  Invoice List
                </MenuItem>
                );
              </Select>
            </div>
            {bookingItem?.data && (
              <div className="ml-5">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClickDownload}
                >
                  <span className="material-icons mr-2">download</span> Download
                </Button>
              </div>
            )}
          </Box>
        </Box>

        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={bookingItem?.data?.data?.length}
              headCells={headCells[0]}
            />
            <TableBody>
              {bookingItem?.data?.data &&
                bookingItem?.data?.data.map((row, index) => {
                  const dateFormate = row.date
                    .toString()
                    .split("-")
                    .reverse()
                    .join("-");

                  const InvoicedateFormate = {
                    formattedDate: row?.invoice_date
                      ? moment(row?.invoice_date).format("DD-MM-yyyy")
                      : "-",
                  };

                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        {bookingItem?.data?.from + index}
                      </TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.candidate}</TableCell>
                      <TableCell align="left">{row.consultant_name}</TableCell>
                      <TableCell align="left">{row.speciality_name}</TableCell>
                      <TableCell align="left">{row.framework}</TableCell>
                      <TableCell align="left">{row.hospital_name}</TableCell>
                      <TableCell align="left">{row.ward_name}</TableCell>
                      <TableCell align="left">{row.grade_name}</TableCell>
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        {dateFormate}
                      </TableCell>
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        {InvoicedateFormate?.formattedDate}
                      </TableCell>
                      <TableCell align="left">
                        {row.start_time} <br /> {row.end_time}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to="#"
                          onClick={() => onhandlClick(row.id)}
                          className="btn btn-secondary"
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!bookingItem?.data && (
                <TableRow>
                  <TableCell scope="row" colSpan="8">
                    <div className="" align="center">
                      Sorry, booking not available!
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={bookingItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
    </>
  );
};

export default ViewReports;
