import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Divider,
  Grid,
  Select,
  MenuItem
} from "@material-ui/core";
import axios from "axios";
import Config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { updateSubCategory } from "../../store/action/subCategory/subCategoryAction";
import Notification from "../../components/Notification/Notification";
import UtilService from "../../helper/service";
import { getSubCategoryById } from "../../store/action/subCategory/subCategoryAction";

const disPastDate = UtilService.disabledPastDate();

const useStyle = makeStyles((theme) => ({
  dialogWidth: { width: "100%" },
  radioGroup: {
    flexDirection: "row",
  },
}));

const UpdateSubCategory = ({ openUpdate, handleClose, id }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const [updateMsg, setUpdateMsg] = useState(false);
  const { subCategorySuccess } = useSelector((state) => state.subCategory);

  const [data, setData] = useState({
    title: "",
    is_active: "",
    sub_category_id: id,
  });

  const statusList = [
    {
      status_name : 'Active',
      id : 1
    },
    {
      status_name : 'Inactive',
      id : 0
    }
  ];
  
  const getData = async (id) => {
    if (id > 0) {
      try {
        dispatch(
          getSubCategoryById(id, (result) => {
            if (result?.status === true) {
              setData(result?.data);
            }
          })
        );
      } catch (error) {
        console.error("error.message", error.message);
      }
    }
  };
  useEffect(() => {
    setData("");
    getData(id);
  }, [id]);

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const submitSubCategory = () => {
    dispatch(updateSubCategory(data));
    setUpdateMsg(true);
  };

  useEffect(() => {
    if (updateMsg && subCategorySuccess?.message) {
      handleClose("update");
    }
  }, [handleClose, updateMsg, subCategorySuccess]);

  return (
    <>
      {updateMsg && subCategorySuccess?.message && (
        <Notification data={subCategorySuccess?.message} status="success" />
      )}
      <Dialog
        open={openUpdate}
        onClose={(e) => handleClose("cancel")}
        classes={{ paper: classes.dialogWidth }}
      >
        <form>
          <DialogTitle id="form-dialog-title">
            <div>Update Sub Category</div>
          </DialogTitle>
          <Divider />
          <DialogContent>
          
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  margin="dense"
                  id="title"
                  type="text"
                  label="Sub Category Title"
                  variant="outlined"
                  name="title"
                  fullWidth
                  required
                  value={data.title || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={12}>
                  <Select
                    value={String(data?.is_active)}
                    label="Status"
                    name="is_active"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    required
                    onChange={handleChange}
                  >
                    {statusList &&
                      statusList.map((items, index) => {
                        return (
                          <MenuItem value={items.id} key={index}>
                            {items.status_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="pr-4 pb-2">
            <Button onClick={(e) => handleClose("cancel")} color="primary">
              Cancel
            </Button>
            <Button
              onClick={submitSubCategory}
              color="secondary"
              variant="contained"
            >
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default UpdateSubCategory;
